import {RouteComponentProps} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import dataStore from "../../../stores/dataStore";
import { UserRequestUpdate} from "../../../models/general";
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {useTranslation} from "react-i18next";
import styles from "../users/Users.module.scss";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {Table, Button, Modal, notification} from "antd";
import UpdateApproval from "./updateApproval/UpdateApproval";

import {API} from "../../../api";
import {
    approvalRequestRef,
    countryRef,
    databaseRef,
    usersRef,
} from "../../../services/firebase/firebase";

import messageStore from "../../../stores/messageStore";
import DeleteConfirmationDialog from "../../../components/deleteConfirmationDialog/DeleteConfirmationDialog";
import InfoIcon from "@material-ui/icons/Info";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {taxSpecialismeListWordpress} from "../../wordpress-conf/tax-specialism-conf";
import {ApprovalStatus} from "../../../models/ApprovalStatusEnum";




// Interface Approvals Props
interface ApprovalsProps extends RouteComponentProps {}


const Approvals=(props:ApprovalsProps)=>{

    // Translation
    const { t } = useTranslation();
    const [token, setToken] = useState('');
    const [openNewRequestUpdate, setOpenNewRequestUpdate] = React.useState(false);
    const [dataLoading , setDataLoading] = useState(true);

    //Delete Dialog State
    const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);

    // Request Date Field
    const [approvalRequestData,setApprovalRequestData]=useState({
        requestId: "",
        type: "",
        userId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        avatarUrl: "",
        personnalBiography: "",
        profesionnalBiography: "",
        deleted: false,
        isVisible : false ,
        linkedInProfileLink: "",
        twitterProfileLink: "",
        assistantId:"",
        assistantName: "",
        assistantEmail: "",
        assistantTelephone: "",
        assistantMobile: "",
        wordpress_id:"",
        lastUpdated:"",
        right : "",
        grade : "",
        country : "",
        areaOfIndustryExpertise : [] ,
        taxSpecialisme : [],
        firm:''
    });

    // WordPress User id
    var wordpress_id:any;

    var characters="abcdefghijklmnopqrstuvwxyz"


    // Change Biography Format To Be Accept By WordPress
    const textFormat=(biography:any,userInfo:any)=>{
        let text:string=biography;
        let p:string="<p>";
        let pEnd:string="</p>";
        let count=0;
        for(let i=0;i<userInfo.profesionnalBiography.length;i++){
            if(count%2===0) {
                text = text.replace("\n", pEnd + '&nbsp;');
                count++
            }else {
                text = text.replace("\n", p);
                count++
            }

        }
        text=text+pEnd;
        return text
    }



    const rows = dataStore.approvals;
    // Filter TaxSpecialismList To Be include In The List /Wordpress-conf/taxSpecialism.tsx
    const filterTaxSpecialisme=(taxSpecialismList:string[])=>{
        let intersection = taxSpecialismList.filter(x => taxSpecialismeListWordpress.includes(x));
        return intersection
    }
    
   


    // Column Of Approvals In Material Table
    const headCellsRequests = [
        {
            field: "type",
            title: t("type"),
            sortable: true,
            key : "type",
            render : (rowData : any) => (rowData.type),
            sorter: (a : any, b : any) => a.type?.toLowerCase() <  b.type?.toLowerCase() ? -1 : 1 ,

        },
        {
            field: "date",
            title: t("date"),
            sortable: true,
            render : (rowData : any) => (rowData.date),
            sorter: (a : any, b : any) => a.date?.toLowerCase() <  b.date?.toLowerCase() ? -1 : 1 ,
        },
        {
            field: "subject",
            title: t("Subject"),
            sortable: true,
            render: (rowData:UserRequestUpdate ) => (rowData.subject!=="undefined undefined"?rowData.subject:""),
            sorter: (a : any, b : any) => a.subject?.toLowerCase() <  b.subject?.toLowerCase() ? -1 : 1 ,

        },
        {
            field: "requestedBy",
            title: t("Requested by"),
            sortable: true,
            render: (rowData:UserRequestUpdate ) => (rowData.requestedBy!=="undefined undefined"?rowData.requestedBy:""),
            sorter: (a : any, b : any) => a.requestedBy?.toLowerCase() <  b.requestedBy?.toLowerCase() ? -1 : 1 ,

        },

        {
            field: "Actions",
            title: "Actions",
            editable: "never",
            sortable: false,
            render: (userRequestUpdate: any) => (
                <ButtonGroup disableRipple={true} variant="text" aria-label="text button group">

                    {/* Delete Request */}
                     <Button onClick={(event) => {
                         setDialogDeleteOpen(true);
                         setApprovalRequestData(userRequestUpdate);
                     }}>
                      <HighlightOffIcon style={{color:"red"}} />
                     </Button>

                    {/* Confirm Request */}
                    <Button onClick={(event) => {
                        confirmRequest(userRequestUpdate);
                        updateUserAssitantInfo(userRequestUpdate)
                    }}
                    >
                        <CheckIcon style={{color:"green"}} />
                    </Button >

                    {/* Open Request Details */}
                    {userRequestUpdate.type !=="Delete" ?<Button onClick={(event) =>
                        handleOpenNewRequestUpdate(event, userRequestUpdate)} >
                        <InfoIcon />
                    </Button >:""}
                </ButtonGroup>

            ),
        },
    ];

    // Load Data Approvals From Firebase Database
    const loadData = useCallback(async () => {
        try {
            setDataLoading(true);
            approvalRequestRef.on("value", async (snapshot: any) => {
                let requests = snapshot.val();
                let newRequestUpdate: any[] = [];
                for (let key in requests) {
                    newRequestUpdate.push({ id: key, ...requests[key] });
                }
                dataStore.updateApprovals(newRequestUpdate);
                setDataLoading(false);
            });

        } catch (error) {
            messageStore.snackbar({
                message: t("Failed fetching approval Requests"),
                type: "error",
            });
        }
    }, [t]);

    //Handle Open Request Details
    const handleOpenNewRequestUpdate = async (
        event: React.MouseEvent<unknown>,
        user: any
    ) => {
        setApprovalRequestData(user);
        setOpenNewRequestUpdate(true);
    };


    const updateUserAssitantInfo=async (userRequest:any)=>{
        const assistantInfo=await API.getUserInfo(userRequest.userId);
        usersRef.on('value', async (snap) => {
            const update: any = {};

                update["assistantName"]= ( !!userRequest.firstName ? userRequest.firstName : assistantInfo.firstName) +" "+ ( !!userRequest.lastName ? userRequest.lastName : assistantInfo.lastName)
                update["assistantTelephone"]= !!userRequest.phone ? userRequest.phone:!!assistantInfo && !!assistantInfo.phone?assistantInfo.phone:""
                update["assistantMobile"]= !!userRequest.mobile ? userRequest.mobile:!!assistantInfo && !!assistantInfo.mobile?assistantInfo.mobile:""

            let users = snap.val();
            for (let user in users) {
                const detailedUser = await API.getUserInfo(user);
                if (detailedUser.assistantId) {
                    if (detailedUser.assistantId === userRequest.userId) {
                        await API.updateUser(update, detailedUser.id)
                    }
                }

            }});
    }


    // Get Country Id from List Country Firebase

    function getKeyByValue(object:any, value:any) {
        return Object.keys(object).find(key => object[key] === value);
    }

    const getCountryId = useCallback((countryName: string) => {
        countryRef.once('value', async (snap) => {
            let result = snap.val();
            let res=getKeyByValue(result,countryName);
            dataStore.updateCountrySelected(res as any)

        })
    }, []);

    //Delete User From Firebase And Wordpress
    const deleteUser = useCallback(
        async (userId: string) => {
            const userInfo = await API.getUserInfo(userId);

            try {
                // const updates: any = {};
                // updates['deleted'] = true;
                // await firebase.database().ref('users/' + userId).update(updates);

                // Delete user from Firebase
                const firebaseResponse =  await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUser`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        uid: userId,
                        callType: ""
                    })
                });

                if (firebaseResponse.status !== 200) {
                    throw new Error("Failed to delete user from Firebase");
                }

                // Delete user from Wordpress if needed
                if (userInfo.wordpress_id) {
                    const wordpressResponse = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUserWordpress`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            userIdWordpress: userInfo.wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            callType: ""
                        })
                    });

                    if (wordpressResponse.status !== 200) {
                        throw new Error("Failed to delete user from Wordpress");
                    }
                }

                notification["success"]({
                    message: "Success",
                    description: t("User deleted successfully")
                });

                const imageRes = await firebase.storage().ref("userAvatar/" + userId + ".jpg");
                if (userInfo.avatarUrl) {
                    imageRes.delete()
                }
            } catch (error) {
                messageStore.snackbar({
                    message: t("Failed to delete user"),
                    type: "error"
                });
            }
        },
        [t,token]
    );

    // Move Image From UserRequest Storage To UserAvatar Storage
    const moveFirebaseFile =useCallback(async (id:string,requestId:string)=>{
        let oldRef =await firebase.storage().ref("userRequest").child(requestId+".jpg")

        await oldRef.getDownloadURL().then( async url => {
            let blob = await fetch(url).then(r => r.blob());
            await firebase.storage().ref("userAvatar/" + id + ".jpg").put(blob)
            await firebase.storage().ref("userAvatar").child(id+".jpg").getDownloadURL().then(async url =>{
                const updates: any = {};
                updates['avatarUrl'] = url;
                await firebase.database().ref('users/' + id).update(updates)
            })

        })

    },[])




    // Convert Blob To File
    // const blobToFile = (theBlob: Blob, fileName:string): File => {
    //     return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    // }


    // Download Image From Firebase And Upload It To WordPress
    const uploadImageWordpress = useCallback(async (requestId: string) => {
        try {
            const requestUser = await API.getRequestInfo(requestId);
            const userInfo = await API.getUserInfo(requestUser.userId);
            let id: any;
            const formData = new FormData();
            let oldRef;
            if (requestUser.type === 'Create' && requestUser.avatarUrl) {
                oldRef = await firebase.storage().ref("userRequest").child(requestId + ".jpg");
            }
            if (requestUser.type === 'Update') {
                if (requestUser.avatarUrl) {
                    oldRef = await firebase.storage().ref("userRequest").child(requestId + ".jpg");
                }

                if (userInfo.avatarUrl && !requestUser.avatarUrl) {
                    oldRef = await firebase.storage().ref("userAvatar").child(userInfo.id + ".jpg");
                }
            }
            const url = await oldRef?.getDownloadURL();
            if (url) {
                const blob = await fetch(url).then(r => r.blob());
                const myFile = new File([blob], requestUser.userId + ".png", { type: blob.type });
                formData.append("file", myFile);
                const response = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("wordpressToken")}`,
                    },
                    body: formData
                });
                if (!response.ok) {
                    const errorMessage = await response.text();
                    console.error('Error uploading media:', errorMessage);
                } else {
                    const res = await response.json();
                    id = res.id;
                    dataStore.updateImageWordPressSelected(id);
                }
            }
        } catch (error) {
            console.error('Error in uploadImageWordpress:', error);
        }
    }, []);

    // Check If New Email Added Already Exist And Get His Wordpress Id
    // const checkUserWordpress=async (email:string)=>{
    //     const users=dataStore.deletedUsers;
    //     let id;
    //     for (let user in users) {
    //         if(users[user].email===email ){
    //             id=users[user].wordpress_id;
    //             break
    //         }
    //     }
    //     return id;
    // }
    const checkUserWordpress = async (email: string) => {
        try {
            const tokenWordpress = localStorage.getItem("wordpressToken");
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUserWordPressById?email=${email.trim()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokenWordpress}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const res = await response.json();
                return res.userId;
            } else if (response.status === 404) {
                return null; // Return null if user not found
            } else {
                throw new Error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error('Error checking WordPress user:', error);
            throw error;
        }
    };
    //Accept Request
    const confirmRequest=useCallback(async (userRequest:UserRequestUpdate)=>{
        try {
            // Sample function to make the fetch call with types defined for parameters.
            const callFirebaseFunction = async (endpoint: string,method:string, data: Record<string, any>) => {
                const response =  await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error('Error calling Firebase function: ' + endpoint);
                }
                return await response.json();
            }


           // if request is a delete type
            if(userRequest.type==="Delete"){
                deleteUser(userRequest.userId)
            }
            if(userRequest.type==="Create"||userRequest.type==="Update") {

                const userInfo = await API.getUserInfo(userRequest.userId);

                // Get Country Id from List Country Firebase
                await getCountryId(userRequest.country?userRequest.country as any:((userInfo && userInfo.country) ?userInfo.country:""));
                var countryIdStore = dataStore.countrySelected;
                countryIdStore = parseInt(countryIdStore as any);


                // Build Acf Data (Acf is a object data that accepted by wordpress to do the update)
                let randomCharacter=characters.charAt(Math.floor(Math.random() * characters.length))
                const userWordpressUpdateACF = {
                    fields: {
                        display_email: !!userRequest.email ? userRequest.email : !!userInfo && !!userInfo.email ? userInfo.email : "",
                        telephone: !!userRequest.phone ? userRequest.phone : !!userInfo && !!userInfo.phone ? userInfo.phone : "",
                        mobile: !!userRequest.mobile ? userRequest.mobile : !!userInfo && !!userInfo.mobile ? userInfo.mobile : "",
                        biography: (!!userRequest.profesionnalBiography && !userInfo) || (!!userInfo && !!userInfo.profesionnalBiography) ? textFormat(!!userRequest.profesionnalBiography ? userRequest.profesionnalBiography : userInfo.profesionnalBiography, !!userRequest.profesionnalBiography ? userRequest : userInfo) : "",
                        connect_url: !!userRequest.linkedInProfileLink ? userRequest.linkedInProfileLink : userInfo && !!userInfo.linkedInProfileLink ? userInfo.linkedInProfileLink : "",
                        assistants_name: !!userRequest.assistantName ? userRequest.assistantName : userInfo && !!userInfo.assistantName ? userInfo.assistantName : "",
                        assistants_email: !!userRequest.assistantEmail ? userRequest.assistantEmail : userInfo && !!userInfo.assistantEmail ? userInfo.assistantEmail : "",
                        position: !!userRequest.grade ? userRequest.grade : userInfo && !!userInfo.grade ? userInfo.grade : "",
                        user_location: !!countryIdStore ? countryIdStore : "",
                        show: typeof userRequest.isVisible === "boolean" ? userRequest.isVisible : userInfo && !!userInfo.isVisible ? userInfo.isVisible : false,
                        area_of_expertise: !!userRequest?.taxSpecialisme && userRequest?.taxSpecialisme?.length > 0 ? filterTaxSpecialisme(userRequest.taxSpecialisme) : userInfo && !!userInfo.taxSpecialisme ? filterTaxSpecialisme(userInfo.taxSpecialisme) : "",
                        company_logo:userRequest.firm?.logo_id ? userRequest.firm?.logo_id: !!userInfo?.firm?.logo_id ? userInfo?.firm?.logo_id : "",
                    }
                }
                var str1 = !!userRequest.firstName ? userRequest.firstName : userInfo && userInfo.firstName ? userInfo.firstName : "";
                var str2 = !!userRequest.lastName ? userRequest.lastName : userInfo && userInfo.lastName ? userInfo.lastName : "";

                //Build Native Data (Native is a object data that accepted by wordpress to do the update)
                //we replace special character by randomCharacter
                const userWordpressUpdateNative = {
                    name: str1.replace(/[^a-zA-Z0-9]/g,randomCharacter).concat(' ', str2.replace(/[^a-zA-Z0-9]/g,randomCharacter)),
                    first_name: !!userRequest.firstName ? userRequest.firstName : userInfo && !!userInfo.firstName ? userInfo.firstName : "",
                    last_name: !!userRequest.lastName ? userRequest.lastName : userInfo && !!userInfo.lastName ? userInfo.lastName : "",
                    url: !!userRequest.firm ? userRequest.firm.webSite : userInfo && !!userInfo.firm ? userInfo.firm.webSite : "",
                }


                //Get Image Wordpress Id from Store depend on Witch last Two Condition Executed
                await uploadImageWordpress(userRequest.requestId)
                const image = dataStore.imageWordPressSelected;

                //If Type Of Request Is Update
                if (userRequest.type === "Update") {
                    // If on Firebase IsVisble is True And On Request False
                    if (userRequest.isVisible === false && userInfo.isVisible === true) {
                        // Update Native Data Wordpress For The User Exist Already
                        await callFirebaseFunction('updateUserWordpressNative','PUT', {
                            userIdWordpress: userRequest.wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            first_name: userWordpressUpdateNative.first_name,
                            last_name: userWordpressUpdateNative.last_name,
                            name: userWordpressUpdateNative.name,
                            url: userWordpressUpdateNative.url,
                            callType:""

                        });
                        // Update Acf Data Wordpress For The User Exist Already
                        await callFirebaseFunction('updateUserWordpressAcf','PUT', {
                            userIdWordpress: wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            image: image,
                            telephone: userWordpressUpdateACF.fields.telephone,
                            mobile: userWordpressUpdateACF.fields.mobile,
                            biography: userWordpressUpdateACF.fields.biography,
                            connect_url: userWordpressUpdateACF.fields.connect_url,
                            assistants_name: userWordpressUpdateACF.fields.assistants_name,
                            assistants_email: userWordpressUpdateACF.fields.assistants_email,
                            position: userWordpressUpdateACF.fields.position,
                            user_location: userWordpressUpdateACF.fields.user_location,
                            show: userWordpressUpdateACF.fields.show,
                            area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                            company_logo:userWordpressUpdateACF.fields.company_logo,
                            callType:""

                        });
                    }
                }
                // Check If User Email Already Exist
                const id = await checkUserWordpress(userInfo.email);

                // If Request IsVisible True Or Firebase IsVisible existe and True
                if (userRequest.isVisible === true || (!!userInfo && userInfo.isVisible === true)) {

                    // If Type Of Request Is Create
                    if (userRequest.type === "Create") {

                        //Check If Id User Already Exist
                        if (id) {

                            // Update Native Data Wordpress For The User Exist Already
                            await callFirebaseFunction('updateUserWordpressNative','PUT', {
                                userIdWordpress: id,
                                token: localStorage.getItem("wordpressToken"),
                                first_name: userWordpressUpdateNative.first_name,
                                last_name: userWordpressUpdateNative.last_name,
                                name: userWordpressUpdateNative.name,
                                url: userWordpressUpdateNative.url,
                                callType:""

                            });

                            // Update Acf Data Wordpress For The User Exist Already
                            await callFirebaseFunction('updateUserWordpressAcf','PUT', {
                                userIdWordpress: id,
                                token: localStorage.getItem("wordpressToken"),
                                image: image,
                                telephone: userWordpressUpdateACF.fields.telephone,
                                mobile: userWordpressUpdateACF.fields.mobile,
                                biography: userWordpressUpdateACF.fields.biography,
                                connect_url: userWordpressUpdateACF.fields.connect_url,
                                assistants_name: userWordpressUpdateACF.fields.assistants_name,
                                assistants_email: userWordpressUpdateACF.fields.assistants_email,
                                position: userWordpressUpdateACF.fields.position,
                                user_location: userWordpressUpdateACF.fields.user_location,
                                show: userWordpressUpdateACF.fields.show,
                                area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                                company_logo:userWordpressUpdateACF.fields.company_logo,
                                callType:""});
                        } else {

                            // Create Wordpress User By Insert Native Data
                            await callFirebaseFunction('createUserWordPressNativeFunction', 'POST',{
                                token: localStorage.getItem("wordpressToken"),
                                name: userWordpressUpdateNative.name,
                                username: userWordpressUpdateNative.name,
                                first_name: userWordpressUpdateNative.first_name,
                                last_name: userWordpressUpdateNative.last_name,
                                email: userWordpressUpdateACF.fields.display_email,
                                password: "i5Z%sTF6tdu&#*h#ydq$J%PM",
                                url: userWordpressUpdateNative.url,
                                description: "",
                                callType:""
                            }).then(res => {
                                //Return Wordpress Id
                                // eslint-disable-next-line
                                wordpress_id = res.data.userId;
                            })
                        }
                        // To Insert ACF data Should Use Update Because Creation Support Just Native Data
                        await callFirebaseFunction('updateUserWordpressAcf','PUT', {
                            // userIdWordpress: wordpress_id,
                            userIdWordpress: wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            image: image,
                            telephone: userWordpressUpdateACF.fields.telephone,
                            mobile: userWordpressUpdateACF.fields.mobile,
                            biography: userWordpressUpdateACF.fields.biography,
                            connect_url: userWordpressUpdateACF.fields.connect_url,
                            assistants_name: userWordpressUpdateACF.fields.assistants_name,
                            assistants_email: userWordpressUpdateACF.fields.assistants_email,
                            position: userWordpressUpdateACF.fields.position,
                            user_location: userWordpressUpdateACF.fields.user_location,
                            show: userWordpressUpdateACF.fields.show,
                            area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                            company_logo:userWordpressUpdateACF.fields.company_logo,
                            callType:""});
                    }
                    //If Type Of Request Is Update
                    if (userRequest.type === "Update") {

                        // If User Has Already Wordpress Id
                        if (userRequest.wordpress_id) {
                            // Update Native Data Wordpress For The User Exist Already
                            await callFirebaseFunction('updateUserWordpressNative','PUT', {
                                userIdWordpress: userRequest.wordpress_id,
                                token: localStorage.getItem("wordpressToken"),
                                first_name: userWordpressUpdateNative.first_name,
                                last_name: userWordpressUpdateNative.last_name,
                                name: userWordpressUpdateNative.name,
                                url: userWordpressUpdateNative.url,
                                callType:""

                            });
                        }
                        // Id User Has Not Wordpress Id
                        if (!userRequest.wordpress_id) {
                            await callFirebaseFunction('createUserWordPressNativeFunction','POST', {
                                token: localStorage.getItem("wordpressToken"),
                                name: userWordpressUpdateNative.name,
                                username: userWordpressUpdateNative.name,
                                first_name: userWordpressUpdateNative.first_name,
                                last_name: userWordpressUpdateNative.last_name,
                                email: userInfo.email,
                                password: "i5Z%sTF6tdu&#*h#ydq$J%PM",
                                url: userWordpressUpdateNative.url,
                                description: "",
                                callType:""
                            }).then(async res => {
                                //Return Wordpress Id
                                // eslint-disable-next-line
                                wordpress_id = res.data.userId;
                                });
                        }
                        // For The Two last Condition If He Has Wordpress Or Not We Update ACF Data
                        await callFirebaseFunction('updateUserWordpressAcf', 'PUT',{
                            userIdWordpress: !!userRequest.wordpress_id ? userRequest.wordpress_id : wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            image: image,
                            telephone: userWordpressUpdateACF.fields.telephone,
                            mobile: userWordpressUpdateACF.fields.mobile,
                            biography: userWordpressUpdateACF.fields.biography,
                            connect_url: userWordpressUpdateACF.fields.connect_url,
                            assistants_name: userWordpressUpdateACF.fields.assistants_name,
                            assistants_email: userWordpressUpdateACF.fields.assistants_email,
                            position: userWordpressUpdateACF.fields.position,
                            user_location: userWordpressUpdateACF.fields.user_location,
                            show: userWordpressUpdateACF.fields.show,
                            area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                            company_logo:userWordpressUpdateACF.fields.company_logo,
                            callType:""});
                    }
                }
                // Build Data For Firebase database
                const userUpdate = {
                    id: userRequest.userId,
                    deleted: !!userInfo && !!userInfo.deleted ? userInfo.deleted : false,
                    email: !!userRequest.email ? userRequest.email : !!userInfo && !!userInfo.email ? userInfo.email : "",
                    firstName: !!userRequest.firstName ? userRequest.firstName : !!userInfo && !!userInfo.firstName ? userInfo.firstName : "",
                    lastName: !!userRequest.lastName ? userRequest.lastName : !!userInfo && !!userInfo.lastName ? userInfo.lastName : "",
                    phone: !!userRequest.phone ? userRequest.phone : !!userInfo && !!userInfo.phone ? userInfo.phone : "",
                    mobile: !!userRequest.mobile ? userRequest.mobile : !!userInfo && !!userInfo.mobile ? userInfo.mobile : "",
                    //isVisible:!!userRequest.isVisible ? userRequest.isVisible : !!userInfo && !!userInfo.isVisible ? userInfo.isVisible : false,
                    isVisible: typeof userRequest.isVisible === "boolean" ? userRequest.isVisible : !!userInfo && !!userInfo.isVisible ? userInfo.isVisible : false,
                    right: !!userRequest.right ? userRequest.right : userInfo.right ? userInfo.right : "",
                    firm: !!userRequest.firm ? userRequest.firm : userInfo?.firm ? userInfo?.firm as any:"",
                    avatarUrl: !!userRequest.avatarUrl ? userRequest.avatarUrl : !!userInfo && !!userInfo.avatarUrl ? userInfo.avatarUrl : "",
                    taxSpecialisme: !!userRequest.taxSpecialisme ? userRequest.taxSpecialisme : !!userInfo && !!userInfo.taxSpecialisme ? userInfo.taxSpecialisme : [],
                    personnalBiography: !!userRequest.personnalBiography ? userRequest.personnalBiography : !!userInfo && !!userInfo.personnalBiography ? userInfo.personnalBiography : "",
                    profesionnalBiography: !!userRequest.profesionnalBiography ? userRequest.profesionnalBiography : !!userInfo && !!userInfo.profesionnalBiography ? userInfo.profesionnalBiography : "",
                    linkedInProfileLink: !!userRequest.linkedInProfileLink ? userRequest.linkedInProfileLink : !!userInfo && !!userInfo.linkedInProfileLink ? userInfo.linkedInProfileLink : "",
                    twitterProfileLink: !!userRequest.twitterProfileLink ? userRequest.twitterProfileLink : !!userInfo && !!userInfo.twitterProfileLink ? userInfo.twitterProfileLink : "",
                    assistantId: !!userRequest.assistantId ? userRequest.assistantId : !!userInfo && !!userInfo.assistantId ? userInfo.assistantId : "",
                    assistantName: !!userRequest.assistantName ? userRequest.assistantName : !!userInfo && !!userInfo.assistantName ? userInfo.assistantName : "",
                    assistantEmail: !!userRequest.assistantEmail ? userRequest.assistantEmail : !!userInfo && !!userInfo.assistantEmail ? userInfo.assistantEmail : "",
                    assistantTelephone: !!userRequest.assistantTelephone ? userRequest.assistantTelephone : !!userInfo && !!userInfo.assistantTelephone ? userInfo.assistantTelephone : "",
                    assistantMobile: !!userRequest.assistantMobile ? userRequest.assistantMobile : !!userInfo && !!userInfo.assistantMobile ? userInfo.assistantMobile : "",
                    grade: !!userRequest.grade ? userRequest.grade : !!userInfo && !!userInfo.grade ? userInfo.grade : "",
                    country: !!userRequest.country ? userRequest.country : !!userInfo && !!userInfo.country ? userInfo.country : "",
                    areaOfIndustryExpertise: !!userRequest.areaOfIndustryExpertise ? userRequest.areaOfIndustryExpertise : !!userInfo && !!userInfo.areaOfIndustryExpertise ? userInfo.areaOfIndustryExpertise : [],
                    wordpress_id: !!userRequest.wordpress_id ? userRequest.wordpress_id : !!id ? id : !!wordpress_id ? wordpress_id : "",
                    approvalStatus: ApprovalStatus.Approved,
                    lastUpdated: new Date().toISOString().replace('T', ' ').substr(0, 10)
                };


                //Get Request Info
                const requestRes = await API.getRequestInfo(userRequest.requestId)

                //If Request Contain avatarUrl Move Image From userRequest Storage To userAvatar Storage
                if (requestRes.avatarUrl) {
                    moveFirebaseFile(userRequest.userId, userRequest.requestId)
                }

                if (userRequest.type === 'Create') {
                    //Enable Account Authentication After Request Accepted By Admin
                    await callFirebaseFunction('settingAccount', 'POST',{userId: userRequest.userId});
                }

                // Update User Firebase
                await API.updateUser(userUpdate, userRequest.userId);

                //Clean DataStore
                dataStore.updateImageWordPressSelected(0);
            }
           databaseRef.ref("approval_request/" + userRequest.requestId).remove();

            notification["success"]({
                message: "Success",
                description: t("Approval request successfully accepted"),
            });
        }catch (error) {
            notification["error"]({
                message: "Error",
                description: t("Failed to accept approval request "),
            });

        }

    },[t,characters,deleteUser,getCountryId,moveFirebaseFile,token,uploadImageWordpress,wordpress_id])

    //Delete Request
    const deleteRequest = async () => {
        const requestInfo = await API.getRequestInfo(approvalRequestData.requestId);
        if (approvalRequestData.type === "Create") {

            // Using fetch to call the httpsDeleteUser function
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUser`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    uid: approvalRequestData.userId,
                    callType: ""
                })
            });

            if (!response.ok) { // Assuming the function returns an object with a success attribute
                throw new Error("Failed to process the user deletion request");
            }
        }

        const requestRes = await firebase.storage().ref("userRequest").child(approvalRequestData.requestId + ".jpg");
        if (requestInfo.avatarUrl) {
            requestRes.delete();
        }

        // Update the approvalStatus to Declined when the admin rejects the approval
        const updatedInfo: any = {
            approvalStatus: ApprovalStatus.Declined
        }
        await API.updateUser(updatedInfo, requestInfo.userId);

        await databaseRef
            .ref("approval_request/" + approvalRequestData.requestId)
            .remove()
            .then(() => {
                notification["success"]({
                    message: "Success",
                    description: t("Approval request successfully deleted"),
                });
            })
            .catch((err) => {
                notification["error"]({
                    message: "Error",
                    description: t("Failed to delete request approval"),
                });
            });
    };
    useEffect(() => {
        firebase.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    const token = await user.getIdToken();
                    setToken(token);
                } else {
                    setToken('');
                }
            }
        );
    }, []);
    // First Loading Components
    useEffect( ()=>{
        loadData().then();
      },[loadData])



    return (
        <div className={'root'}>
           <Table
               style={{overflowX:"auto",cursor : "pointer"}}
               dataSource={rows}
               columns={headCellsRequests}
               loading={dataLoading}
               rowKey={(row:any) => row.userId }
           />

        <DeleteConfirmationDialog
            parentStyle={styles.dialog}
            open={dialogDeleteOpen}
            resolveAction={(resolve: boolean) => {
                resolve && deleteRequest();
                setDialogDeleteOpen(false);
            }}
        />
        <Modal
            title="Edit professional"
            visible={openNewRequestUpdate}
            onCancel={() => {
                setOpenNewRequestUpdate(false);
            }}
            width={'80%'}
            style={{top:' 5%'}}
            destroyOnClose
            footer={null}
            bodyStyle={{ height: "70vh", overflowY: "scroll" }}
        >
          <>
             <UpdateApproval
                    onCloseCallback={() => setOpenNewRequestUpdate(false)}
                    dataRow={approvalRequestData}
                />
          </>
        </Modal>
    </div>);
}


export default inject("dataStore", "messageStore")(observer(Approvals));

