import axios from "axios"
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import fileDownload from "js-file-download"
import dataStore from "stores/dataStore"
import { uuid } from "uuidv4"
import { Prefixe ,Item } from "models/general";

let prefixSearch:Prefixe[]=[];
let itemSearch:Item[]=[];

export const loadDataStorage = async (valueName:string) => {
    const storageDataPrefixes = await firebase.storage().ref(valueName).listAll().then(res => {
        return res;
    })
    return storageDataPrefixes;
}

export const loadFileMetadata = async (path: string) => {
    const storageDataFile = await firebase.storage().ref('/' + path).getMetadata().then(res => {
        return res;
    })
    return storageDataFile;
}

export const downloadFile = async (item: Item | Prefixe) => {
    const urlDownload = await firebase.storage().ref(item.fileUrl).getDownloadURL()
        .then(async (urlFile) => {
            return urlFile
        })
    axios.get(urlDownload, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, item.name)
        })
}

export const deleteFileService = async (path: string) => {
  return firebase
    .storage()
    .ref(path)
    .delete()
    .then((res) => {
      return true;
    });
};



export const deleteFolderService = async (folderPath:string) => {
    const ref = await firebase.storage().ref(folderPath);
    const list = await ref.listAll();
    let filesDeleted = 0;

    for await (const fileRef of list.items) {
        await deleteFileService(fileRef.fullPath);
        filesDeleted++;
    }
    for await (const folderRef of list.prefixes) {
        filesDeleted += await deleteFolderService(folderRef.fullPath);
    }
    return filesDeleted;
};

export const uploadFileService=async (file:any,path:string,version:string,downloadPermissions:string[])=>{
    const unique_id = uuid();
    const timeStamp=Date.now()
    return await firebase.storage().ref(path+'/'+timeStamp+'#'+file.name).put(file,{customMetadata:{
            version:version,
            id:unique_id,
            isLastVersion:'Yes',
            downloadPermissions:downloadPermissions.join(','),
        }}).then((res)=> {
           return true;
        });
};

export const loadFileVersion=async (item: Item, setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>) => {
    dataStore.updateFileVersionSelected(item)
    setLoadingFileVersion(true)
    dataStore.resetItemsVersions();
    const result = await loadDataStorage(
       '/'+item.parent
    );
    let itemsVersionDataStore: any = [];
    if (result.items.length > 0) {
        await Promise.all(
            result?.items.map(async (value: any) => {
                const metadataItem = await loadFileMetadata(value.location.path_);
                let file: Item = {
                    id:metadataItem.customMetadata?.id,
                    name: value.name.substring(value.name.indexOf('#')+1,),
                    isDirectory: false,
                    size: metadataItem.size,
                    fileUrl: value.fullPath,
                    lastUpdate: new Date(metadataItem.updated),
                    parent: value.parent?.fullPath as any,
                    version:metadataItem.customMetadata?.version,
                    isLastVersion:metadataItem.customMetadata?.isLastVersion
                };
                if(value.name!=='fakeFile' && item.id===metadataItem.customMetadata?.id){itemsVersionDataStore.push(file);}
            })
        );
        dataStore.updateItemsFileVersionSelected(itemsVersionDataStore.sort(function (a:Item,b:Item){return parseInt(b.version)- parseInt(a.version)}));

    }

    setLoadingFileVersion(false);
}

export const uploadFileVersionService=async (file:any,path:string)=>{
    let newItemVersionSelected:any={};
    const dataStoreFileVersionSelected=dataStore.fileVersionSelected
    const timeStamp=Date.now()
    const pathFile=path+'/'+timeStamp+'#'+file.name
    return  await firebase.storage().ref(pathFile).put(file,{
        customMetadata:{
            version:(parseInt(dataStoreFileVersionSelected.version)+1).toString(),
            id:dataStoreFileVersionSelected.id,
            isLastVersion:'Yes'
        }
    })
        .then(async res => {
         newItemVersionSelected= {
            name:res.metadata.name,
            version:res.metadata.customMetadata?.version as any,
            id:res.metadata.customMetadata?.id as any,
            fileUrl:res.metadata.fullPath,
            size:res.metadata.size,
            parent:res.ref.parent?.fullPath as any,
            lastUpdate:new Date(),
            isDirectory:false,
            isLastVersion:res.metadata.customMetadata?.isLastVersion as any,
        };

        updateMetaDataFile(dataStoreFileVersionSelected.fileUrl);
        updateFileVersionSelected(newItemVersionSelected);

        return newItemVersionSelected;
    })
        .catch(error => {
            // eslint-disable-next-line
            return newItemVersionSelected;
        });
}



const updateMetaDataFile = async (path: string) => {
    await firebase.storage().ref(path).updateMetadata({customMetadata: {isLastVersion:'No'}}).then(res => {
    })
}

const updateFileVersionSelected=(item:Item)=>{
    dataStore.updateFileVersionSelected(item)
}


/*export const searchFile=async (value: string,path:string) => {
    let resultSearch: Item[] = [];
    let resultItem:Item;
    const list = await loadDataStorage(path);
    for await (const item of list.items) {
        const itemMetadata = await loadFileMetadata(item.fullPath)
        if (item.name !== 'fakeFile' && itemMetadata.customMetadata?.isLastVersion==="Yes") {
            const nameFile: string = item.name.substring(item.name.indexOf("#") + 1, item.name.indexOf("."))
            if (nameFile.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())){
                resultItem={
                    id:itemMetadata.customMetadata?.id,
                    name:item.name.substring(item.name.indexOf('#')+1,),
                    version:itemMetadata.customMetadata?.version,
                    isLastVersion:itemMetadata.customMetadata?.isLastVersion,
                    fileUrl:item.fullPath,
                    parent:item.parent?.fullPath as any,
                    size:itemMetadata.size,
                    isDirectory:false,
                    lastUpdate:itemMetadata.updated,
                }
                resultSearch.push(resultItem)
            }
        }
    }
    return resultSearch;
}*/

export const searchFile=async (value: string, path: string) => {
    itemSearch = []
    prefixSearch = []
    await recursiveSearch(value, path)
    dataStore.updatePrefixes(prefixSearch)
    dataStore.updateItems(itemSearch)

}

export const recursiveSearch = async (value: string,path:string) => {
    const ref = await firebase.storage().ref(path);
    const list = await ref.listAll();
    for await (const itemRef of list.items) {
        const itemMetadata = await loadFileMetadata(itemRef.fullPath)
        if (itemRef.name !== 'fakeFile' && itemMetadata.customMetadata?.isLastVersion==="Yes") {
            const nameItem: string = itemRef.name.substring(itemRef.name.indexOf("#") + 1, itemRef.name.indexOf("."))
            if (nameItem.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())){
             let resultItem={
                    id:itemMetadata.customMetadata?.id,
                    name:itemRef.name.substring(itemRef.name.indexOf('#')+1,),
                    version:itemMetadata.customMetadata?.version,
                    isLastVersion:itemMetadata.customMetadata?.isLastVersion,
                    fileUrl:itemRef.fullPath,
                    parent:itemRef.parent?.fullPath as any,
                    size:itemMetadata.size,
                    isDirectory:false,
                    lastUpdate:itemMetadata.updated,
                }
                itemSearch.push(resultItem)
            }
        }
    }
    for await (const prefixRef of list.prefixes) {
        if (prefixRef.name.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())){
            let prefixe: Prefixe = {
                name: prefixRef.name,
                isDirectory: true,
                fileUrl: prefixRef.fullPath,
                items: [],
                parent: prefixRef.parent?.fullPath as any,
            };
            prefixSearch.push(prefixe)
        }
        await recursiveSearch(value,prefixRef.fullPath);

    }
    return {item:itemSearch,prefix:prefixSearch};
};

export const updateCutItemsStore=async (item: Item) => {
    let newItemCopySelected: Item[] = [];
    const items = await loadDataStorage(item.parent);
    if (items.items.length > 0) {
        await Promise.all(
            items?.items.map(async (value: any) => {
                const metadataItem = await loadFileMetadata(value.location.path_);
                let file: Item = {
                    id:metadataItem.customMetadata?.id,
                    name: value.name.substring(value.name.indexOf('#')+1,),
                    isDirectory: false,
                    size: metadataItem.size,
                    fileUrl: value.fullPath,
                    lastUpdate: new Date(metadataItem.updated),
                    parent: value.parent?.fullPath as any,
                    version:metadataItem.customMetadata?.version,
                    isLastVersion:metadataItem.customMetadata?.isLastVersion
                };
                if(value.name!=='fakeFile' && item.id===metadataItem.customMetadata?.id){newItemCopySelected.push(file);}
            })
        );
        dataStore.updateItemsCutSelected(newItemCopySelected);
    }
}


export const pasteItemsSelected=async (pathToPast:string) => {
        const itemsCutSelected = dataStore.itemsCutSelected;
        await Promise.all(
            itemsCutSelected.map(async (value: Item) => {
                await moveFirebaseFile(value, pathToPast);
            })
        )
        const oldItems=dataStore.itemsCutSelected
        await Promise.all(
            oldItems.map(async (value: Item) => {
                await firebase.storage().ref(value.fileUrl).delete()
            })
        )
}


const moveFirebaseFile =async (item:Item,newPath:string)=>{
    const timeStamp=Date.now()
    let oldRef =await firebase.storage().ref(item.fileUrl)
    await oldRef.getDownloadURL().then( async url => {
        let blob = await fetch(url).then(r => r.blob());
        await firebase.storage().ref(newPath+'/'+timeStamp+'#'+item.name).put(blob,{customMetadata:{
                version:item.version,
                id:item.id,
                isLastVersion:item.isLastVersion,
                //downloadPermissions:item.downloadPermissions
            }})
    })

}

