import React, {FormEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import styles from "../users/Users.module.scss";
import {inject, observer} from "mobx-react";
import {UserData} from "../../../models/general";
import dataStore from "../../../stores/dataStore";
import {RouteComponentProps} from "react-router-dom";
import messageStore from "../../../stores/messageStore";
import {useTranslation} from "react-i18next";
import profileBlank from "../../../assets/profile-blue.svg";
import {API} from "../../../api";
import {countryRef, usersRef} from "../../../services/firebase/firebase";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {Button, Input, Modal, notification, Table} from "antd";
import {Tooltip} from "@material-ui/core";
import AddUserTaxand from "../users/addUser/AddUserTaxand";
import UserInfo from '../users/UserInfo/UserInfo';

import * as XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import {
    DeleteFilled,
    EditFilled,
    ExclamationCircleOutlined,
    ExportOutlined,
    InfoCircleFilled,
    PlusOutlined
} from '@ant-design/icons';
import {ApprovalStatus} from "../../../models/ApprovalStatusEnum";


interface UsersProps extends RouteComponentProps {}

const FirmsProfessionals = () => {
    const { t } = useTranslation();
    const [action , setAction] = useState<any>("");
    const [DataLoading , setDataLoading] = useState(true);
    const [openUserInfo, setOpenUserInfo] = React.useState(false);
    const [openNewUser, setOpenNewUser] = React.useState(false);
    const [openUpdateUser, setOpenUpdateUser] = React.useState(false);
    const [userFirmInfo,setUserFirmInfo]=useState("");
    const [currentUserRight,setCurrentUserRight]=useState("");
    const [DeletedusernameVerified, setDeletedUsernameVerified] = useState(true);
    const [adminsMail, setAdminsMail] = React.useState<string[]>([]);
    const [file1,setfile1]=useState<any>();
    const [token, setToken] = useState('');
    const [data, setData] = useState({
        id: "",
        email: "",
        password: "",
        againPassword: "",
        firstName: "",
        lastName: "",
        generatedPass: "",
        phone: "",
        mobile: "",
        personnalBiography: "",
        profesionnalBiography: "",
        deleted: false,
        linkedInProfileLink: "",
        twitterProfileLink: "",
        assistantName: "",
        assistantEmail: "",
        assistantTelephone: "",
        assistantMobile: "",
        assistantId:"",
    });

    const [dataRow,setDataRow]=React.useState<any>({
        email:"",
        id: "",
        firstName: "",
        lastName: "",
        phone:"",
        areaOfExpertise:"",
        firm: "",
        biography:"",
        right:"",
        mobile:"",
        isAdmin: false,
        isVisible:false,
        deleted: false
    });

    //Filter Ref Material Table
    let filterFirstNameRef = useRef('')
    let filterLastNameRef = useRef('')
    let filterCountryRef = useRef('')

    const approval=dataStore.approvals;
    const [rows,setRows] = useState<any>([]);

    // State handlers of search filters in tables

    const [SearchFirstname , setSearchFirstname] = useState<any>('');
    const [SearchLastname , setSearchLastname] = useState<any>('');
    const [SearchFirm , setSearchFirm] = useState<any>('');
    const [SearchCountry , setSearchCountry] = useState<any>('');
    const [working, setWorking] = useState(false);
    const [isVisible, setIsVisible] = useState(action === "Edit" ? dataRow.isVisible : false);
    const [assistant,setAssistant]=useState<any>("");
    const [assistants,setAssistants]=useState<any>([])
    const [taxSpecialism, setTaxSpecialism] = React.useState<any>(action === "Edit" ? (!!dataRow.taxSpecialisme ? dataRow.taxSpecialisme : []) : []);
    const [grade, setGrade] = React.useState<any>(action === "Edit" ? (!!dataRow.grade ? dataRow.grade : "") : "");
    const { confirm } = Modal;
    const [pictureDeleted, setPictureDeleted]= useState(false);

    const [emailVerified, setEmailVerified] = useState(true);
    const [usernameVerified, setUsernameVerified] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    const [firm, setFirm] = React.useState<any>(action === "Edit" ? (!!dataRow.firm?.firm ? dataRow.firm?.firm : "") : "");
    const [fileChanged, setFileChanged] = useState(false);
    const [areaOfIndustryExpertise, setAreaOfIndustryExpertise] = React.useState<any>(action === "Edit" ? (!!dataRow.areaOfIndustryExpertise ? dataRow.areaOfIndustryExpertise : []) : []);
    const [country, setCountry] = React.useState<any>(action === "Edit" ?(!!dataRow.country ? dataRow.country : "") : "");
    const [right, setRight] = useState(action === 'Edit' ? dataRow.right : "");

    function showDeleteConfirm(rowData:any) {
        confirm({
            title: "Are you sure you want to delete this user ?",
            style : {fontFamily : 'ArialBD'},
            icon: <ExclamationCircleOutlined />,
            content: `You are deleting this user permanently` ,
            okText: "Delete",
            okType: 'danger',
            onOk() {
                return new Promise((resolve,reject) => {
                    deleteUserRequest(rowData).then(()=>{
                        loadData();
                        resolve('done');
                    }).catch(err => reject(err))
                })
            },
            onCancel() {
            },
        });
    }
    // Get Country Id from List Country Firebase
    const getCountryId =(countryName:string)=>{
        countryRef.on('value', async (snap) => {
            let result = snap.val();
            for (let country in result) {
                const detailedCountry = await API.getCountryInfo(country);
                if(detailedCountry===countryName){
                    // setCountryId(country);
                    break;
                }
            }

        });

    }

    // Upload Avatar firebase
    const uploadImageWithoutRequest = useCallback(async (id:string) => {
        await firebase.storage().ref("userAvatar/" + id + ".jpg").put(file1);
        await firebase.storage().ref("userAvatar").child(id + ".jpg").getDownloadURL().then(async url => {
            const updates: any = {};
            updates['avatarUrl'] = url;
            await firebase.database().ref('users/' + id).update(updates);
        });
    },[file1])


    // Upload Avatar firebase
    const uploadImage = useCallback(async (id, requestId) => {
        await firebase.storage().ref("userRequest/" + requestId + ".jpg").put(file1);
        await firebase.storage().ref("userRequest").child(requestId + ".jpg").getDownloadURL().then(async url => {
            const updates: any = {};
            updates['avatarUrl'] = url;
            await firebase.database().ref("approval_request/" + requestId).update(updates);
        });
    },[file1])

    const loadAdmins = async () => {
        await firebase.database().ref().child("users").orderByChild('deleted').equalTo(false).on('value', async (snapshot) => {
            let users=snapshot.val();
            let emails:any[]=[];
            for(let user in users){
                let userInfo=await API.getUserInfo(user);
                if(userInfo.right && userInfo.right === "Global administrator"){
                    emails.push(userInfo.email)
                }
            }
            setAdminsMail(emails);
        });
    }

    const canSubmit = useMemo(
        () =>
            !!data.email && !!data.password && data.password === data.againPassword && data.firstName && data.lastName,
        [data]
    );


    const shortPassword = useMemo(
        () => data.password.length > 0 && data.password.length < 8,
        [data]
    );
    const handleUpdate = useCallback(async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            const userInfo = await API.getUserInfo(firebase.auth().currentUser?.uid as string);

            // Retrieving the updated user data
            const updatedUser = await API.getUserInfo(dataRow.id);
            try {
                setWorking(true);
                const requestId = await API.getRequestId();

                // Replace Firebase function call for httpsCreateRequestProfileUpdate with fetch
                const responseCreateRequest = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/createRequestProfileUpdate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        requestId: requestId,
                        userId: dataRow.id,
                        email: dataRow.email,
                        firstName: dataRow.firstName,
                        lastName: dataRow.lastName,
                        phone: dataRow.phone,
                        mobile: dataRow.mobile,
                        right: right,
                        isVisible: isVisible,
                        firm: firm,
                        deleted: dataRow.deleted,
                        type: "Update",
                        requestedBy: userInfo.firstName + " " + userInfo.lastName,
                        subject: dataRow.firstName + " " + dataRow.lastName,
                        personnalBiography: dataRow.personnalBiography,
                        profesionnalBiography: dataRow.profesionnalBiography,
                        linkedInProfileLink: dataRow.linkedInProfileLink,
                        twitterProfileLink: dataRow.twitterProfileLink,
                        assistantId: !!assistant.id ? assistant.id : "",
                        assistantName: dataRow.assistantName,
                        assistantEmail: dataRow.assistantEmail,
                        assistantTelephone: dataRow.assistantTelephone,
                        assistantMobile: dataRow.assistantMobile,
                        taxSpecialisme: taxSpecialism,
                        areaOfIndustryExpertise: areaOfIndustryExpertise,
                        grade: grade,
                        country: country,
                        wordpress_id: !!dataRow.wordpress_id ? dataRow.wordpress_id : "",
                        callType: ""
                    }) // Add all the necessary properties here
                });

                if (responseCreateRequest.ok) {
                    if (fileChanged) {
                        await uploadImage(dataRow.userId, requestId);
                        setFileChanged(false);
                    }

                    // Link empty avatar value to the request if avatar is deleted
                    if(pictureDeleted) {
                        const updates: any = {};
                        updates['avatarUrl'] = "";
                        await firebase.database().ref("approval_request/" + requestId).update(updates);
                    }

                    // Link empty taxSpecialism value to the request if taxSpecialism is deleted
                    if (taxSpecialism && taxSpecialism.length === 0 && updatedUser.taxSpecialisme?.length !== 0) {
                        const updatedTaxSpec: any = {};
                        updatedTaxSpec['taxSpecialisme'] = "";
                        await firebase.database().ref("approval_request/" + requestId).update(updatedTaxSpec);
                    }

                    // Link empty areaOfIndustryExpertise value to the request if areaOfIndustryExpertise is deleted
                    if (areaOfIndustryExpertise && areaOfIndustryExpertise.length === 0 && updatedUser.areaOfIndustryExpertise?.length !== 0) {
                        const updatedIndustry: any = {};
                        updatedIndustry['areaOfIndustryExpertise'] = "";
                        await firebase.database().ref("approval_request/" + requestId).update(updatedIndustry);
                    }

                    // Replace Firebase function call for httpsSendMailRequest with fetch
                    try {
                        const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendMailRequest`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                            body: JSON.stringify({
                                requestBy: userInfo.firstName + " " + userInfo.lastName,
                                emailList: adminsMail
                            })
                        });

                        if (response.status !== 200) {
                            throw new Error("Error sending the mail request.");
                        }
                        else {
                            setWorking(false);
                            setOpenUpdateUser(false);
                            notification['success']({
                                message: 'Success',
                                description: t('Thank you for your update, it is being processed.')
                            });
                        }
                    }
                    catch (error) {
                        setWorking(false);
                        setOpenUpdateUser(false);
                        notification['error']({
                            message: 'Error',
                            description: t('Error sending the mail request.')
                        });
                    }
                } else {
                    throw new Error('Error creating the request.');
                }

            } catch (error) {
                setWorking(false);
                setOpenUpdateUser(false);
                notification["error"]({
                    message: t("Failed to update"),
                    description: "An error occurred while updating",
                });
            }
            setPictureDeleted(false);
        }, [file1, fileChanged, pictureDeleted, grade,country,firm,right,isVisible,assistant,adminsMail,taxSpecialism, areaOfIndustryExpertise, dataRow, t]// eslint-disable-line react-hooks/exhaustive-deps
    )

    const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            if (!canSubmit || !emailValid || !emailVerified) {
                return;
            }
            const userId = await firebase.auth().currentUser?.uid;
            const userInfo = await API.getUserInfo(userId as string);

            try {
                setWorking(true);
                const requestId = await API.getRequestId();

                const responseCreateRequest = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/createRequestFirmsAdd`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        requestId: requestId,
                        email: data.email,
                        password: data.password,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phone: data.phone,
                        mobile: data.mobile,
                        right: right,
                        isVisible: isVisible,
                        firm: firm,
                        deleted: data.deleted,
                        type: "Create",
                        requestedBy: userInfo.firstName + " " + userInfo.lastName,
                        subject: data.firstName + " " + data.lastName,
                        profesionnalBiography: data.profesionnalBiography,
                        personnalBiography: data.personnalBiography,
                        linkedInProfileLink: data.linkedInProfileLink,
                        twitterProfileLink: data.twitterProfileLink,
                        assistantName: data.assistantName,
                        assistantEmail: data.assistantEmail,
                        assistantTelephone: data.assistantTelephone,
                        assistantMobile: data.assistantMobile,
                        taxSpecialisme: taxSpecialism,
                        areaOfIndustryExpertise: areaOfIndustryExpertise,
                        grade: grade,
                        country: country,
                        assistantId: !!assistant.id ? assistant.id : data.assistantId,
                        callType: ""
                    })
                });
                if (!responseCreateRequest.ok) {
                    throw new Error('Error creating the request.');
                }
                const createRequestData = await responseCreateRequest.json();
                if (fileChanged) {
                    uploadImage(createRequestData.user_res, requestId);
                }

                const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendMailRequest`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        requestBy: userInfo.firstName + " " + userInfo.lastName,
                        emailList: adminsMail
                    })
                });
                if (response.status !== 200) {
                    throw new Error("Error creating a request.");
                }
                else {
                    notification['success']({
                        message: 'Success',
                        description: t('Thank you for your update, it is being processed')
                    });
                }
                setWorking(false);
                setOpenNewUser(false);

                setData({
                    id: "",
                    email: "",
                    password: "",
                    againPassword: "",
                    firstName: "",
                    lastName: "",
                    generatedPass: "",
                    phone: "",
                    mobile: "",
                    personnalBiography: "",
                    profesionnalBiography: "",
                    deleted: false,
                    linkedInProfileLink: "",
                    twitterProfileLink: "",
                    assistantName: "",
                    assistantEmail: "",
                    assistantTelephone: "",
                    assistantMobile: "",
                    assistantId: "",
                });
                setTaxSpecialism([]);
                setGrade("");
                setFirm("");
                setCountry("");
                setAreaOfIndustryExpertise([]);
                setAssistant('');
                setRight('');
                setIsVisible(false);
            } catch (error) {
                console.log("the error is", error);
                messageStore.snackbar({
                    message: `Error in firms`,
                    type: "error",
                });
                setWorking(false);
                setOpenNewUser(false);
            }
            setWorking(false);
            setOpenNewUser(false);
        },
        [canSubmit, file1, fileChanged, data, isVisible, adminsMail, areaOfIndustryExpertise, grade, country, taxSpecialism, right, firm, t, DeletedusernameVerified] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Delete avatar from Firebase
    const deleteImageFirebase = useCallback(async (id) => {
        const updates: any = {};
        updates['avatarUrl'] = "";
        await firebase.database().ref('users/' + id).update(updates);
    },[file1])// eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateForVisibleIsFalse = useCallback(async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        try {
            setWorking(true);
            // Build Data For Firebase database
            const userUpdate = {
                id: dataRow.id !== undefined ? dataRow.id : "",
                email: dataRow.email !== undefined ? dataRow.email : "",
                firstName: dataRow.firstName !== undefined ? dataRow.firstName : "",
                lastName: dataRow.lastName !== undefined ? dataRow.lastName : "",
                phone: dataRow.phone !== undefined ? dataRow.phone : "",
                mobile: dataRow.mobile !== undefined ? dataRow.mobile : "",
                deleted: dataRow.deleted !== undefined ? dataRow.deleted : "",
                personnalBiography: dataRow.personnalBiography !== undefined ? dataRow.personnalBiography : "",
                profesionnalBiography: dataRow.profesionnalBiography !== undefined ? dataRow.profesionnalBiography : "",
                linkedInProfileLink: dataRow.linkedInProfileLink !== undefined ? dataRow.linkedInProfileLink : "",
                twitterProfileLink: dataRow.twitterProfileLink !== undefined ? dataRow.twitterProfileLink : "",
                assistantId:!!assistant.id?assistant.id:"",
                assistantName: dataRow.assistantName !== undefined ? dataRow.assistantName : "",
                assistantEmail: dataRow.assistantEmail !== undefined ? dataRow.assistantEmail : "",
                assistantTelephone: dataRow.assistantTelephone !== undefined ? dataRow.assistantTelephone : "",
                assistantMobile: dataRow.assistantMobile !== undefined ? dataRow.assistantMobile : "",
                grade: grade === undefined ? "" : grade,
                country: country === undefined ? "" : country,
                isVisible: isVisible === undefined ? "" : isVisible,
                right: right === undefined ? "" : right,
                firm: firm === undefined ? "" : firm,
                taxSpecialisme: taxSpecialism?.length > 0 ? taxSpecialism : "",
                areaOfIndustryExpertise: areaOfIndustryExpertise?.length > 0 ? areaOfIndustryExpertise : "",
                wordpress_id:!!dataRow.wordpress_id ? dataRow.wordpress_id :"",
                approvalStatus: ApprovalStatus.Default,
                lastUpdated: new Date().toISOString().replace('T', ' ').substr(0, 10)
            };

            // If File Changed Upload Image Using Id Of User
            if (fileChanged) {
                await uploadImageWithoutRequest(dataRow.id);
                setFileChanged(false);
            }

            // Delete the user avatar if pictureDeleted is true
            if (pictureDeleted) {
                await deleteImageFirebase(userUpdate.id);
            }

            const res = await API.updateUser(userUpdate, dataRow.id);
            if (res) {
                setOpenUpdateUser(false);
                setWorking(false);
                setPictureDeleted(false);
                notification["success"]({
                    message: "Success",
                    description: t("Profile updated"),
                });
            }
        }
        catch (error) {
            setWorking(false);
            setOpenUpdateUser(false);
            notification["error"]({
                message: t("Failed to update "),
                description: "An error occurred while update",
            });
        }
        // eslint-disable-next-line
    },[right, fileChanged, pictureDeleted, file1, working,assistant, isVisible, firm, areaOfIndustryExpertise, taxSpecialism, grade,country, dataRow])// eslint-disable-line react-hooks/exhaustive-deps)

    const handleCreateForVisibleIsFalse = async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        if (!canSubmit || !emailValid || !emailVerified) {
            return;
        }

        try {
            setWorking(true);

            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/createUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    email: data.email,
                    password: data.password,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    mobile: data.mobile,
                    linkedInProfileLink: data.linkedInProfileLink,
                    twitterProfileLink: data.twitterProfileLink,
                    assistantId: !!assistant.id ? assistant.id : "",
                    assistantName: data.assistantName,
                    assistantEmail: data.assistantEmail,
                    assistantTelephone: data.assistantTelephone,
                    assistantMobile: data.assistantMobile,
                    firm: firm,
                    grade: grade,
                    country: country,
                    areaOfIndustryExpertise:
                        areaOfIndustryExpertise.length > 0 ? areaOfIndustryExpertise : "",
                    taxSpecialisme: taxSpecialism.length > 0 ? taxSpecialism : "",
                    personnalBiography: data.personnalBiography,
                    profesionnalBiography: data.profesionnalBiography,
                    right: "Standard user",
                    isVisible: isVisible,
                    deleted: data.deleted,
                    wordpress_id: "",
                    showLandingModal: true,
                    approvalStatus: ApprovalStatus.Default,
                    callType: ""
                })
            });

            if (!response.ok) {
                throw new Error('Error creating the user.');
            }

            const responseData = await response.json();

            if (fileChanged) {
                await uploadImageWithoutRequest(responseData.user_res);
                setFileChanged(false);
            }

            setData({
                id: "",
                email: "",
                password: "",
                againPassword: "",
                firstName: "",
                lastName: "",
                generatedPass: "",
                phone: "",
                mobile: "",
                personnalBiography: "",
                profesionnalBiography: "",
                deleted: false,
                linkedInProfileLink: "",
                twitterProfileLink: "",
                assistantName: "",
                assistantEmail: "",
                assistantTelephone: "",
                assistantMobile: "",
                assistantId:"",
            });

            // ... resetting other states
            setOpenNewUser(false);
            setWorking(false);
            /* send email credentials after create user */
            try {
                const responseSendEmail = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendEmailDetailUser`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        user: data.email,
                        pass: data.password,
                        receiver: data.firstName
                    })
                });
                // Parse and log JSON response only if the response is OK
                if (responseSendEmail.ok) {
                    // Show success notification only once here
                    notification.success({
                        message: "Success",
                        description: "Email was sent successfully",
                    });
                } else {
                    // If the response is not OK, handle it as an error
                    const errorResponse = await responseSendEmail.text(); // Use .text() to get the response text regardless of content type
                    throw new Error(`Server response error: ${errorResponse}`);
                }
            } catch (error: any) {
                console.error('Error sending email credentials:', error);
                notification.error({
                    message: "Error",
                    description: `Failed to send emails. ${error.message}`,
                });
            }
            notification["success"]({
                message: "Success",
                description: `User added successfully`,
                style: { fontFamily: 'ArialBD' },
            });
        } catch (error) {
            setWorking(false);
            setOpenNewUser(false);

            notification["error"]({
                message: "An error occurred",
                description: "An error occurred",
            });

            setWorking(false);
        }
    }

    const globalUpdate = async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (dataRow.isVisible === false && isVisible === false) {
            await handleUpdateForVisibleIsFalse(e);
        } else {
            await handleUpdate(e);
        }
    }

    const globalCreate=async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (isVisible === false) {
            await handleCreateForVisibleIsFalse(e);
        } else {
            await handleSubmit(e);
        }
    }

    // Column Of User In Material Table
    const headCells = [
        {
            field: "avatarUrl",
            title: t(""),
            sortable: false,
            editable: "never",
            filtering:false,
            render: (rowData: UserData) => (
                <div
                    className={`${true ? styles.avatarSmall : ""} ${styles.avatar}`}
                    style={{
                        backgroundImage: `url(${!!rowData.avatarUrl ? rowData.avatarUrl : profileBlank})`,
                        backgroundPosition:'inherit'
                    }}
                />
            ),
        },
        {
            field: "firstName",
            title: t("First Name"),
            sortable: true,
            defaultFilter: filterFirstNameRef.current,
            render: (rowData: UserData) => (
                <div>
                    {rowData.firstName}
                </div>
            ),
            sorter: (a : any, b : any) => a.firstName?.toLowerCase() <  b.firstName?.toLowerCase() ? -1 : 1 ,
            children : [{
                title : ()=>(<Input
                    placeholder={`Search`}
                    value={SearchFirstname}
                    style={{ marginBottom: 8, display: 'block' }}
                    onChange={(e) =>{
                        setSearchFirstname(e.target.value);
                        setRows(dataStore.users.filter((row:any) => row.firstName?.toString()?.toLowerCase().includes(e.target.value?.toLowerCase())))
                    }}
                />),
                dataIndex : 'firstName',
                key : 'fname'
            }],

        },
        {
            field: "lastName",
            title: t("Last Name"),
            sortable: true,
            defaultFilter: filterLastNameRef.current,
            render: (rowData: UserData) => (
                <div>
                    {rowData.lastName}
                </div>
            ),
            sorter: (a : any, b : any) => a.lastName?.toLowerCase() <  b.lastName?.toLowerCase() ? -1 : 1 ,
            children : [{
                title : ()=>(<Input
                    placeholder={`Search`}
                    value={SearchLastname}
                    style={{ marginBottom: 8, display: 'block' }}
                    onChange={(e) =>{
                        setSearchLastname(e.target.value);
                        setRows(dataStore.users.filter((row:any) => row.lastName?.toString()?.toLowerCase().includes(e.target.value?.toLowerCase())))
                    }}
                />),
                dataIndex : 'lastName',
                key : 'lname'
            }]
        },
        {
            title: t("Firm"),
            key : "firm",
            render: (rowData: UserData) => (
                <div>
                    {rowData !== undefined ? rowData.firm?.firm : "--"}
                </div>
            ),
            sorter: (a : any, b : any) => a.firm?.firm?.toLowerCase() <  b.firm?.firm?.toLowerCase() ? -1 : 1 ,
            children : [{
                title : ()=>(<Input
                        placeholder={`Search`}
                        value={SearchFirm}
                        style={{ marginBottom: 8, display: 'block' }}
                        onChange={(e) =>{
                            setSearchFirm(e.target.value);
                            setRows(dataStore.users.filter((row:any) => row.firm.firm?.toString()?.toLowerCase().includes(e.target.value?.toLowerCase())))
                        }}
                    />
                ),
                dataIndex : 'firm',
                key : 'firm',
                render : (firm: any) => (
                    <span>
            {firm.firm}
          </span>
                )
            }],
        },
        {
            field: "country",
            title: t("Country"),
            sortable: true,
            defaultFilter: filterCountryRef.current,
            render: (rowData: UserData) => (
                <div>
                    {rowData.country}
                </div>
            ),
            sorter: (a : any, b : any) => a.country?.toLowerCase() <  b.country?.toLowerCase() ? -1 : 1 ,
            children : [{
                title : ()=>(<Input
                    placeholder={`Search`}
                    value={SearchCountry}
                    style={{ marginBottom: 8, display: 'block' }}
                    onChange={(e) =>{
                        setSearchCountry(e.target.value);
                        setRows(dataStore.users.filter((row:any) => row.country?.toString()?.toLowerCase().includes(e.target.value?.toLowerCase())))

                    }}
                />),
                dataIndex : 'country',
                key : 'countryK'
            }]
        },
        {
            field: "lastUpdated",
            title: t("Last updated"),
            sortable: true,
            filtering:false,
            render: (rowData: UserData) => (
                <div>
                    {rowData.lastUpdated}
                </div>
            ),
        },
        {
            field: "",
            title: t(""),
            sortable: true,
            filtering:false,
            width:"5%",
            cellStyle:{
                padding:"0"
            },
            render: (userData: UserData) => (
                approval.find(s=>s.userId===userData.id)?.userId===userData.id?
                    <Tooltip title="An approval request is pending validation">
                        <img style={{width:'20px',height:'20px',float:"right"}}  alt={""} src={require("../../../assets/pending-actions.svg")}/>
                    </Tooltip>:
                    <></>
            ),
        },{
            field: "Actions",
            title: "Actions",
            sortable: false,
            filtering:false,
            render: (rowData: UserData) => (
                <div className="IconsContainer">
                    <EditFilled  onClick={(event : any)=>{
                        event.stopPropagation();
                        setDataRow(rowData);
                        setIsVisible(rowData.isVisible)
                        setTaxSpecialism(rowData.taxSpecialisme)
                        setGrade(rowData.grade);
                        setCountry(rowData.country);
                        setFirm(rowData.firm);
                        setRight(rowData.right);
                        setAreaOfIndustryExpertise(rowData.areaOfIndustryExpertise);
                        setOpenUpdateUser(true);
                        setFileChanged(false)
                    }}/>
                    <InfoCircleFilled onClick={(event : any)=>{
                        event.stopPropagation();
                        setDataRow(rowData);
                        handleOpen( rowData);

                    }}/>
                    <DeleteFilled  onClick={(event:any)=>{
                        event.stopPropagation();
                        showDeleteConfirm(rowData);
                    }} />
                </div>
            )
        }
    ];

    const getCurrentUser=useCallback(async ()=>{
        try {
            firebase.auth().onAuthStateChanged(async(user) => {
                if (user) {
                    const token = await user.getIdToken();
                    setToken(token);
                    var uid = user.uid;
                    const userInfo=await API.getUserInfo(uid as string);
                    setCurrentUserRight(userInfo.right as string);
                    const detailedFirm=await API.getFirmUserInfo(uid)
                    if(detailedFirm && detailedFirm.firm){
                        setUserFirmInfo(detailedFirm.firm as any)
                    }
                }
            }); }
        catch (error) {
            messageStore.snackbar({
                message: t("Failed fetching current user"),
                type: "error",
            });
        }
    },[t])

    const loadData = useCallback(async () => {
        try {
            usersRef.on("value", async (snapshot: any) => {
                let users = snapshot.val();
                let newUsers: any[] = [];
                for (let user in users) {
                    const detailedUser = await API.getUserInfo(user);
                    const detailedFirm=await API.getFirmUserInfo(user)
                    if (detailedUser && !detailedUser.deleted && detailedUser.firm ) {
                        if(currentUserRight==="Firm administrator") {
                            if(detailedFirm.firm===userFirmInfo as any ) {
                                newUsers.push(detailedUser);
                            }}
                        if(currentUserRight==="Assistant"){
                            const currentUser = await API.getUserInfo(firebase.auth().currentUser?.uid as string)
                            if (detailedFirm.firm === userFirmInfo as any ) {
                                if(detailedUser.assistantId !== '')
                                    if(detailedUser.assistantId && detailedUser.assistantId === currentUser.id) {
                                        newUsers.push(detailedUser);
                                    }
                            }
                        }
                    }
                }
                dataStore.updateUsers(newUsers);
                setRows(dataStore.users);
                setDataLoading(false);

            });
        } catch (error) {
            messageStore.snackbar({
                message: t("Failed fetching users"),
                type: "error",
            });
        }
    }, [t,userFirmInfo,currentUserRight]);


    const deleteUserRequest = async (user: UserData) => {
        try {
            const requestId = await API.getRequestId();
            const userInfo = await API.getUserInfo(firebase.auth().currentUser?.uid as string);
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUserRequest`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    requestId: requestId,
                    userId: user.id,
                    type: "Delete",
                    requestedBy: userInfo.firstName + " " + userInfo.lastName,
                    subject: user.firstName + " " + user.lastName,
                    wordpress_id: !!user.wordpress_id ? user.wordpress_id : "",
                })
            });

            if (!response.ok) {
                throw new Error('Error deleting the user request.');
            }

            notification["success"]({
                message: "Success",
                description: t("Thank you for your update, it is being processed"),
            });
        } catch (error) {
            messageStore.snackbar({
                message: `Error when deleting user request`,
                type: "error",
            });
        }
    }

    const handleOpen = ( user: UserData) => {
        dataStore.updateUser(user);
        setOpenUserInfo(true);
    };

    //export to excel
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportXlsx = () => {
        const dataGlobal: any = [];
        const column = ['firstName', "lastName", "email", "country", "firm", "phone", "mobile", "grade", "personnalBiography", "profesionnalBiography",
            "linkedInProfileLink", "twitterProfileLink", "assistantName", "assistantEmail", "assistantTelephone", "assistantMobile", "isVisible",
            "areaOfIndustryExpertise", "taxSpecialism", "lastUpdated"]
        let users=rows.slice().sort((a:any, b:any) => a.lastName?.replace(/\s/g, "")+""+a.firstName?.replace(/\s/g, "") > b.lastName?.replace(/\s/g, "")+""+b.firstName?.replace(/\s/g, "") ? 1 : -1)
        for (let userId in users) {
            if (!users[userId].deleted) {
                let user: any = [];
                user.push(users[userId].firstName);
                user.push(users[userId].lastName);
                user.push(users[userId].email);
                user.push(users[userId].country);
                user.push(users[userId].firm?.firm);
                user.push(users[userId].phone);
                user.push(users[userId].mobile);
                user.push(users[userId].grade);
                user.push(users[userId].personnalBiography);
                user.push(users[userId].profesionnalBiography);
                user.push(users[userId].linkedInProfileLink);
                user.push(users[userId].twitterProfileLink);
                user.push(users[userId].assistantName);
                user.push(users[userId].assistantEmail);
                user.push(users[userId].assistantTelephone);
                user.push(users[userId].assistantMobile);
                user.push(users[userId].isVisible);
                if (users[userId].areaOfIndustryExpertise) {
                    user.push(users[userId].areaOfIndustryExpertise?.join());
                } else {
                    user.push(users[userId].areaOfIndustryExpertise)
                }
                if (users[userId].taxSpecialisme) {
                    user.push(users[userId].taxSpecialisme?.join());
                } else {
                    user.push(users[userId].taxSpecialisme)
                }
                user.push(users[userId].lastUpdated);
                dataGlobal.push(user)
                user = []
            }
        }
        dataGlobal.sort((a: any, b: any) => a[1] + "" + a[0] > b[1] + "" + b[0] ? 1 : -1)
        dataGlobal.splice(0, 0, column);
        const timestamp = new Date().toISOString().replace('T', ' ').substr(0, 10);
        const ws = XLSX.utils.aoa_to_sheet(dataGlobal);
        const Header=['A1','B1','C1','D1','E1','F1','G1','H1','I1','J1','K1','L1','M1','N1','O1','P1','Q1','R1','S1','T1'];
        for(let h in Header){
            ws[Header[h]].s = {
                font: {
                    sz: 12,
                    color: { rgb: "#FF000000" },
                    bold: true,
                    italic: false,
                    underline: false
                }}
        }
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array',cellStyles: true});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "taxand_repository_" + timestamp + fileExtension);
    }

    useEffect(() => {
        getCurrentUser().then();
        loadData().then();
        loadAdmins().then();
    }, [getCurrentUser,loadData]);

    return (
        <div className={styles.root}>
            <div className="ButtonContainer">
                {currentUserRight ==="Firm administrator" || "Assistant" ? (
                    <>
                        <Button type="primary"
                                size='large'
                                style={{marginRight : "10px",fontFamily:'Arial',fontSize:"14px"}}
                                icon={<PlusOutlined />}
                                onClick={() => setOpenNewUser(true)}>
                            Request for a new professional
                        </Button>
                        {currentUserRight==="Firm administrator"  ?(
                                <Button
                                    type="primary"
                                    size='large'
                                    style={{marginRight : "10px",fontFamily:'Arial',fontSize:"14px"}}
                                    icon={<ExportOutlined/>}
                                    onClick={(e) => exportXlsx()}
                                > Export
                                </Button>):
                            (<></>)
                        }
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="TableContainer">
                <Table
                    style={{overflowX:"auto",cursor : "pointer"}}
                    dataSource={rows.slice().sort((a:any, b:any) => a.lastName+""+a.firstName > b.lastName+""+b.firstName ? 1 : -1)}
                    columns={headCells}
                    onRow={(row) => ({ onClick: () => {

                            setDataRow(row);
                            handleOpen( row);
                        } })}
                    rowKey={(row) => Math.random() }
                    loading={DataLoading}
                />
            </div>
            <Modal
                title="Add professional"
                visible={openNewUser}
                onCancel={() => {
                    setOpenNewUser(false);
                    setAssistants([]);

                    setData({
                        id: "",
                        email: "",
                        password: "",
                        againPassword: "",
                        firstName: "",
                        lastName: "",
                        generatedPass: "",
                        phone: "",
                        mobile: "",
                        personnalBiography: "",
                        profesionnalBiography: "",
                        deleted: false,
                        linkedInProfileLink: "",
                        twitterProfileLink: "",
                        assistantName: "",
                        assistantEmail: "",
                        assistantTelephone: "",
                        assistantMobile: "",
                        assistantId:""
                    });
                    setTaxSpecialism([]);
                    setGrade("");
                    setFirm("");
                    setCountry("");
                    setAreaOfIndustryExpertise([]);
                    setRight("");
                    setIsVisible(false)
                    setEmailVerified(true)
                    setEmailValid(true)
                    setAssistant('');
                }}
                okText='Create'
                width={'80%'}
                style={{top:' 5%'}}
                destroyOnClose
                footer={[
                    <Button key="back" onClick={() => {
                        setOpenNewUser(false);
                        setAssistants([]);
                    }}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" disabled={!canSubmit || shortPassword || !emailVerified || !emailValid || !usernameVerified || country === ""} loading={working}
                            onClick={(e)=>globalCreate(e)}>
                        Create
                    </Button>,
                ]}
                bodyStyle={{ height: "70vh", overflowY: "scroll" }}
            >
                <>
                    <AddUserTaxand
                        data={data}
                        setUsernameVerified={setUsernameVerified}
                        setEmailVerified={setEmailVerified}
                        emailValid={emailValid}
                        setEmailValid={setEmailValid}
                        setDeletedUsernameVerified={setDeletedUsernameVerified}
                        setData={setData}
                        usernameVerified={usernameVerified}
                        emailVerified={emailVerified}
                        canSubmit={canSubmit}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        grade={grade}
                        setGrade={setGrade}
                        country={country}
                        setCountry={setCountry}
                        areaOfIndustryExpertise={areaOfIndustryExpertise}
                        setAreaOfIndustryExpertise={setAreaOfIndustryExpertise}
                        taxSpecialism={taxSpecialism}
                        setTaxSpecialism={setTaxSpecialism}
                        fileChanged={fileChanged}
                        setFileChanged={setFileChanged}
                        right={right}
                        setRight={setRight}
                        firm={firm}
                        setFirm={setFirm}
                        assistant={assistant}
                        setAssistant={setAssistant}
                        getCountryId={getCountryId}
                        setfile1={setfile1}
                        assistants={assistants}
                        setAssistants={setAssistants}
                        action="Add"
                        setAction={setAction}
                        openNewUser={openNewUser}
                        pictureDeleted={pictureDeleted}
                        setPictureDeleted={setPictureDeleted}
                    />
                </>
            </Modal>
            <Modal
                title="Update user"
                visible={openUpdateUser}
                onCancel={() => {
                    setOpenUpdateUser(false)
                    setAssistants([]);
                }}
                width={'80%'}
                style={{top:' 5%'}}
                bodyStyle={{ height: "70vh", overflowY: "scroll" }}
                destroyOnClose
                footer={[
                    <Button key="back" onClick={() => {
                        setOpenUpdateUser(false)
                        setAssistants([]);
                    }}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" disabled={!usernameVerified || country === ""} loading={working}  onClick={(e)=>globalUpdate(e)}>
                        Update
                    </Button>,
                ]}

            >
                <>
                    <AddUserTaxand
                        data={dataRow}
                        setUsernameVerified={setUsernameVerified}
                        setEmailVerified={setEmailVerified}
                        emailValid={emailValid}
                        setEmailValid={setEmailValid}
                        setDeletedUsernameVerified={setDeletedUsernameVerified}
                        setData={setDataRow}
                        usernameVerified={usernameVerified}
                        emailVerified={emailVerified}
                        canSubmit={canSubmit}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        grade={grade}
                        setGrade={setGrade}
                        country={country}
                        setCountry={setCountry}
                        areaOfIndustryExpertise={areaOfIndustryExpertise}
                        setAreaOfIndustryExpertise={setAreaOfIndustryExpertise}
                        taxSpecialism={taxSpecialism}
                        setTaxSpecialism={setTaxSpecialism}
                        fileChanged={fileChanged}
                        setFileChanged={setFileChanged}
                        right={right}
                        setRight={setRight}
                        firm={firm}
                        setFirm={setFirm}
                        assistant={assistant}
                        setAssistant={setAssistant}
                        getCountryId={getCountryId}
                        setfile1={setfile1}
                        assistants={assistants}
                        setAssistants={setAssistants}
                        action="Edit"
                        setAction={setAction}
                        pictureDeleted={pictureDeleted}
                        setPictureDeleted={setPictureDeleted}
                    />
                </>
            </Modal>
            <Modal
                title={`${dataRow.firstName} ${dataRow.lastName}`}
                visible={openUserInfo}
                onCancel={() => {
                    setOpenUserInfo(false);
                }}
                width={'80%'}
                style={{top:' 5%'}}
                bodyStyle={{ height: "70vh", overflowY: "scroll" }}
                destroyOnClose
                footer={null}
            >
                <>
                    <UserInfo
                        data={dataRow}
                        assistant={assistant}
                        assistants={assistants}
                    />
                </>
            </Modal>

        </div>
    );
};

export default inject("dataStore", "messageStore")(observer(FirmsProfessionals));
