import React, {FormEvent, useCallback, useEffect, useMemo, useState} from "react"
import {Paper, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {
    areaOfIndustryExpertiseRef,
    countryRef,
    databaseRef,
    firmRef,
    gradeRef,
    rightsRef,
    taxSpecialismRef,
    usersRef
} from "../../../../services/firebase/firebase";
import {API} from "../../../../api";
import messageStore from "../../../../stores/messageStore";
import dataStore from "../../../../stores/dataStore";
import firebase from 'firebase/app';
import styles from "../../profile/Profile.module.scss";

import {toJS} from "mobx";
import {inject, observer} from "mobx-react";
import {taxSpecialismeListWordpress} from "../../../wordpress-conf/tax-specialism-conf";
import {Avatar, Button, Modal, notification, Spin, Tooltip} from 'antd';
import ContactInfo from '../../users/addUser/ContactInfo'
import AssistantInfo from "../../users/addUser/AssistantInfo"
import AdministrationInfo from "../../users/addUser/AdministrationInfo"

import PeopleInfo from "../../users/addUser/PeopleInfo"
import {CloudUploadOutlined, UserOutlined} from '@ant-design/icons';
import {DeleteOutlined} from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import {ApprovalStatus} from "../../../../models/ApprovalStatusEnum";
import { callFirebaseFunction } from '../../../../Utils/apiUtils';


// Interface Parent Props
interface UpdateUserTaxandProps {
    onCloseCallback: any;
    dataRow: any;

}

const UpdateApproval = (props: UpdateUserTaxandProps) => {

    //Props
    const {onCloseCallback, dataRow} = props;

    // Verifaction Username State
    const [usernameVerified, setUsernameVerified] = useState(true);
    const emailVerified = true ;
    const [DeletedusernameVerified, setDeletedUsernameVerified] = useState(true);
    const [checkUserCountryWithFirmCountryActive,setCheckUserCountryWithFirmCountryActive]=useState(true);
    const isValidPassword = true;
    const isValidPasswordAgain = true;
    //Style
    // Translation
    const {t} = useTranslation();

    //Avatar State
    const [fileChanged, setFileChanged] = useState(false)
    const [preview, setPreview] = useState<string | undefined>();
    const [pictureDeleted,setPictureDeleted]=useState(false)
    //Submit Working State
    const [working, setWorking] = useState(false);

    // Data Load Field State
    const [firms, setFirms] = React.useState<any>([]);
    const [grade, setGrade] = React.useState<any>("");
    const [grades, setGrades] = React.useState<any>([]);
    const [listAreaOfIndustryExpertise, setListAreaOfIndustryExpertise] = React.useState<string[]>([]);
    const [listTaxSpecialism, setListTaxSpecialism] = React.useState<string[]>([]);
    const [rights, setRights] = useState([]);

    //   User Date Field
    const [isVisible, setIsVisible] = useState(false);
    const [firm, setFirm] = React.useState<any>("");
    const [country, setCountry] = React.useState<any>("");
    const [areaOfIndustryExpertise, setAreaOfIndustryExpertise] = React.useState<any>([]);
    const [taxSpecialism, setTaxSpecialism] = React.useState<any>([]);
    const [right, setRight] = useState("");
    const [countryId, setCountryId] = useState("");
    const [assistant,setAssistant]=useState<any>("")
    const [assistants,setAssistants]=useState<any>([])
    const [token, setToken] = useState('');
    const [userData, setUserData] = useState({
        requestId: "",
        type: "",
        userId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        avatarUrl: "",
        personnalBiography: "",
        profesionnalBiography: "",
        deleted: false,
        linkedInProfileLink: "",
        twitterProfileLink: "",
        assistantId:"",
        assistantName: "",
        assistantEmail: "",
        assistantTelephone: "",
        assistantMobile: "",
        wordpress_id:"",
        lastUpdated:""
    });

    // Add New Area State
    const [openNewArea, setOpenNewArea] = React.useState(false);
    const [inputValue, setInputValue] = useState("");

    // Loading the data before fill in the inputs
    const [loadingDataModal, setLoadingDataModal] = useState(true);

    // List Countries Store
    const listCountries = dataStore.countries;

    // Avatar File1 , WordPress User id
    let file1: any;
    let wordpress_id: any;
    let deleteduserwordpressId: any;
    const characters = "abcdefghijklmnopqrstuvwxyz";
    // Submit Condition
    const canSubmitArea = useMemo(
        () =>
            inputValue !== "",
        [inputValue]
    );

    // Handle Change Field in WebForm Model
    const toggleVisible = useCallback(() => setIsVisible((v) => !v), []);

    const handleDataChange = useCallback(
        ({
            currentTarget,
          }:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>) => {
            setUserData((d) => ({...d, [currentTarget.name]: currentTarget.value}));
        },
        []
    );

    const handleChangeGrade = useCallback((event: any) => {
        setGrade(event)
    }, []);

    const handleChangeCountry = useCallback((event: any) => {
        setCountry(event)
        getCountryId(event)
    }, []);

    const handleChangeTaxSpecialism = useCallback((value) => {
        setTaxSpecialism(value);
    }, []);

    const handleChangeAreaOfIndustryExpertise = useCallback((value) => {
        setAreaOfIndustryExpertise(value)

    }, []);

    const handleChange = useCallback((event:any) => {
        setRight(event as string);
    }, []);

    const handleChangefirm = async (value : any) => {
        const searchedFirm = await firms.find((e : any) => e.id === value) ; 
        setFirm(searchedFirm);
    }

/*    const handleChangeAssistant = useCallback((value: any) => {
        if (assistants.find((e: any) => e.id === value) !== undefined) {
            setAssistant(assistants.find((e: any) => e.id === value));
            userData.assistantEmail = assistants.find((e: any) => e.id === value).email;
            userData.assistantName =
              assistants.find((e: any) => e.id === value).firstName +
              " " +
              assistants.find((e: any) => e.id === value).lastName;
            userData.assistantMobile = assistants.find(
              (e: any) => e.id === value
            ).mobile;
            userData.assistantTelephone = assistants.find(
              (e: any) => e.id === value
            ).phone;
          }
    }, [userData,assistants]);*/

    const handleChangeAssistant = useCallback((event: React.ChangeEvent<{ value: any }>) => {
        setAssistant(event.target.value as any)
        userData.assistantEmail=event.target.value.email
        userData.assistantName=event.target.value.firstName+ " " +event.target.value.lastName
        userData.assistantMobile=event.target.value.mobile
        userData.assistantTelephone=event.target.value.phone
    }, [userData]);

    const checkUserCountryWithFirmCountry= async ()=> {
        let firmCountries = firm?.country?.split(',').map((c:string)=>c.trim())
        if(firmCountries?.includes(country) || !country || !firm){
            setCheckUserCountryWithFirmCountryActive(true)
        }
        else {
            setCheckUserCountryWithFirmCountryActive(false)
        }
    }
    useEffect(()=>{
        checkUserCountryWithFirmCountry().then();
    },[country,firm]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeFile = useCallback((event) => {
        // Clear any existing file
        setPreview('');
        setFileChanged(false);
        // Check if file is selected
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileType = file.type;
            // Check if the file is a PNG or JPG
            if (fileType === 'image/png' || fileType === 'image/jpeg') {
                const objectUrl = URL.createObjectURL(event.target.files[0]);
                setPreview(objectUrl)
                file1 = event.target.files[0] // eslint-disable-line react-hooks/exhaustive-deps
                setFileChanged(true)
                setPictureDeleted(false)
            } else {
                // Display error message if file is not PNG or JPG
                notification["error"]({
                    message: "Unsupported File Format",
                    description: "Please choose an image file with a .png or .jpg extension. Other file formats are not supported for upload.",
                });
                event.target.value = '';
            }
        }
    }, [file1, setPreview, setFileChanged]);

    const handleClick = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setInputValue(event.target.value as string);
    }, []);

    const handleSubmitArea = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }, []);

    const checkDeletedUsername = () => {
        setDeletedUsernameVerified(true)
        usersRef.on("value", async (snapshot: any) => {
            let users = snapshot.val();
            for (let user in users) {
                const detailedUser = await API.getUserInfo(user)
                if (detailedUser.deleted) {
                    const usernameDetailedUser = detailedUser.firstName + " " + detailedUser.lastName;
                    const usernameData = userData.firstName + " " + userData.lastName;
                    if (usernameDetailedUser.toLocaleLowerCase() === usernameData.toLocaleLowerCase()) {
                        setDeletedUsernameVerified(false)
                        dataStore.updateDeletedUserWordpressId(detailedUser.wordpress_id)
                    }
                }
            }
        });
    }

    const checkUsername = async () => {
        const snapshot = await usersRef.once("value");
        const users = snapshot.val();

        setUsernameVerified(true);

        for (let user in users) {
            const userinfo=await API.getUserInfo(dataRow.userId);
            const detailedUser = await API.getUserInfo(user);

            if(!detailedUser.deleted) {
                let usernameDetailedUser = detailedUser.firstName+" "+detailedUser.lastName;
                let usernameData = userData.firstName+" "+userData.lastName;

                if (dataRow.type === "Update") {
                    let currentUsernameUpdate = dataRow.firstName + " " + dataRow.lastName;
                    if(dataRow.firstName || dataRow.lastName) {
                        if (usernameDetailedUser.toLocaleLowerCase() === currentUsernameUpdate.toLocaleLowerCase()
                            || usernameDetailedUser.toLocaleLowerCase() ===usernameData.toLocaleLowerCase()) {
                            setUsernameVerified(false);
                        }
                    } else {
                        const userInfoDataBase=userinfo.firstName+" "+userinfo.lastName;
                        if (usernameDetailedUser.toLocaleLowerCase() === usernameData.toLocaleLowerCase() &&
                               usernameDetailedUser.toLocaleLowerCase() !== userInfoDataBase.toLocaleLowerCase() ) {
                               setUsernameVerified(false);
                        }
                    }
                }

                if (dataRow.type === "Create") {
                    if (usernameDetailedUser.toLocaleLowerCase() === usernameData.toLocaleLowerCase()
                            && !detailedUser.deleted ) {
                            setUsernameVerified(false);
                    }
                }
            }
        }
    }

    // New Area Of Expertise
    async function addNewAreas(inputValue: string) {
        if (inputValue !== "") {
            await API.addAreaOfExpertise(inputValue);
            messageStore.snackbar({
                message: t("New Value successfully added"),
                type: "success",
            });
            handleClose();
        }
    }
    const handleOpen = () => {
        setOpenNewArea(true);
    };

    const handleClose = () => {
        setOpenNewArea(false);
    };

    // Load Data Field
    const loadDataRights = () => {
        rightsRef.on('value', (snap) => {
            let rightsList: any[] = [];
            snap.forEach(s => {
                rightsList.push(s.val())
            })
            setRights(rightsList as any);


        });
    }

    const loadDataFirms = async () => {
        firmRef.on('value', async (snap) => {
            let firmsList: any[] = [];
            let firms = snap.val();
            for (let f in firms) {
                const detailedFirm = await API.getFirmInfo(f);
                if (!detailedFirm.deleted) {
                    firmsList.push(detailedFirm);
                    const res = await API.getUserInfo(dataRow.userId);
                    if (dataRow.firm || res?.firm) {
                        if (detailedFirm.id === dataRow?.firm?.id ||detailedFirm.id.toString() === res?.firm?.id) {
                            setFirm(detailedFirm)
                        }
                    }
                    if (!dataRow.firm) {
                        const firmUser = await API.getFirmUserInfo(dataRow.userId);

                        if (firmUser && detailedFirm.id === firmUser.id) {
                            setFirm(detailedFirm)
                        }
                    }
                }
            }

            setFirms(firmsList)
            dataStore.updateFirms(firmsList);
        });
    }

    const loadDataAssistant = async () => {
        usersRef.on('value', async (snap) => {
            let AssistantList: any[] = [];
            let users = snap.val();
            for (let user in users) {
                const detailedUser = await API.getUserInfo(user);
                if (!detailedUser.deleted) {
                if((detailedUser.right==="Firm administrator" || detailedUser.right==='Assistant') && detailedUser.country===country) {
                    AssistantList.push(detailedUser);
                        if (detailedUser.id ===dataRow.assistantId) {
                            setAssistant(detailedUser)
                        }
                }
                }
                }
            setAssistants(AssistantList)
        });
    }


    const loadDataGrade = () => {
        gradeRef.on('value', (snap) => {
            let result = snap.val();
            setGrades(result);
        });
    }
    const loadDataCountry = () => {
        countryRef.on('value', async (snap) => {
            let newListCountry: any[] = [];
            let result = snap.val();
            for (let country in result) {
                const detailedCountry = await API.getCountryInfo(country);
                if(detailedCountry===dataRow.country){
                    setCountryId(country);
                }
                newListCountry.push(detailedCountry);
            }
            dataStore.updateCountries(newListCountry)
        });
    }

    const loadDataAreaOfIndustryExpertise = async () => {
        areaOfIndustryExpertiseRef.on('value', async (snap) => {
            let areaOfIndustryExpertiseList: string[] = [];
            let areaOfIndustryExpertise = snap.val();
            for (let f in areaOfIndustryExpertise) {
                const detailedAreaOfExpertise = await API.getAreaOfExpertiseInfo(f);
                areaOfIndustryExpertiseList.push(detailedAreaOfExpertise);
            }
            setListAreaOfIndustryExpertise(areaOfIndustryExpertiseList);
        })
    }
    const loadDataTaxSpecialism = () => {
        taxSpecialismRef.on('value', (snap) => {
            let result = snap.val();
            setListTaxSpecialism(result);
        });
    }
    const loadDataUser = async () => {
        const res = await API.getUserInfo(dataRow.userId);
        if (dataRow.type === "Update") {
            setUserData({
                requestId: dataRow.requestId,
                type: dataRow.type,
                userId: dataRow.userId,
                email: !!dataRow.email ? dataRow.email : res.email,
                firstName: !!dataRow.firstName ? dataRow.firstName : res.firstName,
                lastName: !!dataRow.lastName ? dataRow.lastName : res.lastName,
                phone: dataRow.phone !== undefined ? dataRow.phone : res.phone,
                mobile: dataRow.mobile !== undefined ? dataRow.mobile : res.mobile,
                avatarUrl: dataRow.avatarUrl !== undefined ? dataRow.avatarUrl : res.avatarUrl,
                deleted: false,
                personnalBiography: dataRow.personnalBiography !== undefined ? dataRow.personnalBiography : res.personnalBiography,
                profesionnalBiography: dataRow.profesionnalBiography !== undefined ? dataRow.profesionnalBiography : res.profesionnalBiography,
                linkedInProfileLink: dataRow.linkedInProfileLink !== undefined ? dataRow.linkedInProfileLink : res.linkedInProfileLink,
                twitterProfileLink: dataRow.twitterProfileLink !== undefined ? dataRow.twitterProfileLink : res.twitterProfileLink,
                assistantId: !!dataRow.assistantId ? dataRow.assistantId : !!res.assistantId ?res.assistantId : "",
                assistantName: dataRow.assistantName !== undefined ? dataRow.assistantName : res.assistantName,
                assistantEmail: dataRow.assistantEmail !== undefined ? dataRow.assistantEmail : res.assistantEmail,
                assistantTelephone: dataRow.assistantTelephone !== undefined ? dataRow.assistantTelephone : res.assistantTelephone,
                assistantMobile: dataRow.assistantMobile !== undefined ? dataRow.assistantMobile : res.assistantMobile,
                wordpress_id: !!dataRow.wordpress_id ? dataRow.wordpress_id : res.wordpress_id,
                lastUpdated: !!res.lastUpdated ? res.lastUpdated : "",

            });

            setIsVisible(typeof dataRow.isVisible === "boolean" ? dataRow.isVisible : !!res.isVisible ? res.isVisible:false );
            setRight(!!dataRow.right ? dataRow.right : !!res.right ?res.right:"");
            setGrade(!!dataRow.grade ? dataRow.grade : !!res.grade ? res.grade : "");
            setCountry(!!dataRow.country ? dataRow.country : !!res.country ? res.country : "");
            setAreaOfIndustryExpertise(dataRow.areaOfIndustryExpertise !== undefined ? dataRow.areaOfIndustryExpertise : res.areaOfIndustryExpertise);
            setTaxSpecialism(dataRow.taxSpecialisme !== undefined ? dataRow.taxSpecialisme : res.taxSpecialisme);
            setPreview(dataRow.avatarUrl !== undefined ? dataRow.avatarUrl : res.avatarUrl);
            setAssistant([]);
            getCountryId(!!dataRow.country ? dataRow.country : !!res.country ? res.country : "");
        }
        if (dataRow.type === "Create") {

            setUserData({
                requestId: dataRow.requestId,
                type: dataRow.type,
                userId: dataRow.userId,
                email: !!dataRow.email ? dataRow.email : "",
                firstName: !!dataRow.firstName ? dataRow.firstName : "",
                lastName: !!dataRow.lastName ? dataRow.lastName : "",
                phone: !!dataRow.phone ? dataRow.phone : "",
                mobile: !!dataRow.mobile ? dataRow.mobile : "",
                avatarUrl: !!dataRow.avatarUrl ? dataRow.avatarUrl : "",
                deleted: false,
                personnalBiography: !!dataRow.personnalBiography ? dataRow.personnalBiography : "",
                profesionnalBiography: !!dataRow.profesionnalBiography ? dataRow.profesionnalBiography : "",
                linkedInProfileLink: !!dataRow.linkedInProfileLink ? dataRow.linkedInProfileLink : "",
                twitterProfileLink: !!dataRow.twitterProfileLink ? dataRow.twitterProfileLink : "",
                assistantId:!!dataRow.assistantId?dataRow.assistantId:"",
                assistantName: !!dataRow.assistantName ? dataRow.assistantName : "",
                assistantEmail: !!dataRow.assistantEmail ? dataRow.assistantEmail : "",
                assistantTelephone: !!dataRow.assistantTelephone ? dataRow.assistantTelephone : "",
                assistantMobile: !!dataRow.assistantMobile ? dataRow.assistantMobile : "",
                wordpress_id: dataRow.wordpress_id,
                lastUpdated: "",
            });
            setIsVisible(typeof dataRow.isVisible === "boolean" ? dataRow.isVisible : false);
            setRight(dataRow.right as any);
            setGrade(!!dataRow.grade ? dataRow.grade : "");
            setCountry(!!dataRow.country ? dataRow.country : "");
            setAreaOfIndustryExpertise(!!dataRow.areaOfIndustryExpertise ? dataRow.areaOfIndustryExpertise : []);
            setTaxSpecialism(!!dataRow.taxSpecialisme ? dataRow.taxSpecialisme : []);
            setPreview(dataRow.avatarUrl);
            setAssistant(!!dataRow.assistant ? dataRow.assistant : "")
        }


    }

    const updateUserAssitantInfo=async (assistantId:string)=>{
        const updatedInfo:any={
            assistantName: userData.firstName+" "+userData.lastName,
            assistantTelephone: userData.phone,
            assistantMobile: userData.mobile,
        }
        usersRef.on('value', async (snap) => {
        let users = snap.val();
        for (let user in users) {
            const detailedUser = await API.getUserInfo(user);
            if (detailedUser.assistantId) {
                if (detailedUser.assistantId === assistantId) {
                    await API.updateUser(updatedInfo, detailedUser.id)
                }
            }

        }});
    }
    // Get Country id from List Country Firebase
    const getCountryId =(countryName:string)=>{
        countryRef.on('value', async (snap) => {
            let result = snap.val();
            for (let country in result) {
                const detailedCountry = await API.getCountryInfo(country);
                    if(detailedCountry===countryName){
                        setCountryId(country);
                        break
                }
            }

        });

    }

    // Change Biography Format To Be Accept By WordPress
    const textFormat=(biography:any)=>{
        let text:string=biography;
        let p:string="<p>";
        let pEnd:string="</p>";
        let count=0;
        for(let i=0;i<userData.profesionnalBiography.length;i++){
            if(count%2===0) {
                text = text.replace("\n", pEnd + '&nbsp;');
                count++
            }else {
                text = text.replace("\n", p);
                count++
            }
        }
        text=text+pEnd;
        return text
    }

    //Delete Request Approval
    const deleteRequest = useCallback(async () => {
        const requestInfo = await API.getRequestInfo(dataRow.requestId);

        if (dataRow.type === "Create") {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUser`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    uid: dataRow.userId,
                    callType: ""
                })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }

        const requestRes = await firebase.storage().ref("userRequest").child(dataRow.requestId + ".jpg")
        if (requestInfo.avatarUrl) {
            await requestRes.delete();
        }
        await databaseRef.ref("approval_request/" + dataRow.requestId).remove();
        onCloseCallback();
        // Update the approvalStatus to Declined when the admin rejects the approval
        const updatedInfo: any = {
            approvalStatus: ApprovalStatus.Declined
        }
        await API.updateUser(updatedInfo, dataRow.userId);
        messageStore.snackbar({
            message: t("Request deleted"),
            type: "success",
        });
    }, [t, dataRow.type, dataRow.requestId, onCloseCallback, dataRow.userId,token]);


    // Upload Avatar firebase
    const uploadImage = useCallback(async (id:string) => {
        const userInfo=await API.getUserInfo(id);
        const res = await firebase.storage().ref("userAvatar").child(id + ".jpg")
        if (userInfo.avatarUrl) {
            await res.delete()
        }

        await firebase.storage().ref("userAvatar/" + id + ".jpg").put(file1)
        await firebase.storage().ref("userAvatar").child(id + ".jpg").getDownloadURL().then(url => {
            const updates: any = {};
            updates['avatarUrl'] = url;
            firebase.database().ref('users/' + id).update(updates)

        })

    },[file1])

    // Move Image From UserRequest Storage To UserAvatar Storage
    const moveFirebaseFile = useCallback( async (id: string, requestId: string) => {

        let oldRef = await firebase.storage().ref("userRequest").child(requestId + ".jpg")

        await oldRef.getDownloadURL().then(async url => {
            let blob = await fetch(url).then(r => r.blob());
            await firebase.storage().ref("userAvatar/" + id + ".jpg").put(blob)
            await firebase.storage().ref("userAvatar").child(id + ".jpg").getDownloadURL().then(async url => {
                const updates: any = {};
                updates['avatarUrl'] = url;
                await firebase.database().ref('users/' + id).update(updates)
            })

        })
    },[])

    // Convert Blob To File
    const blobToFile = (theBlob: Blob, fileName:string): File => {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    // Download Image From Firebase And Upload It To WordPress
    const uploadImageWordpress=useCallback(async ()=>{

        let id:any;
        const formData = new FormData();// eslint-disable-line react-hooks/exhaustive-deps

        // Determine the Firebase reference
        const storagePath = dataRow.avatarUrl
            ? `userRequest/${userData.requestId}.jpg`
            : `userAvatar/${userData.userId}.jpg`;
        const oldRef = firebase.storage().ref(storagePath);

        // Fetch the image from Firebase Storage
        await oldRef.getDownloadURL().then(async (url) => {
            let blob = await fetch(url).then(r => r.blob());

            // Use the userId or requestId to generate the file name
            const fileName = userData.userId;

            // Convert the blob to a file with the same name
            const myFile = blobToFile(blob, `${fileName}.jpg`);

            // Append the file to the form data
            formData.append("file", myFile);

            // Upload the image to WordPress
            await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("wordpressToken")}`,
                },
                body: formData
            }).then(async (response) => await response.json())
                .then((res) => {
                    id = res.id;
                    dataStore.updateImageWordPressSelected(id);
                });
        });
    },[userData,dataRow])


    // Upload Image WordPress And Get id
    const getMediaId=useCallback( async ()=>{
        let id:any;
        const formData=new FormData();
        formData.append("file",file1)
        await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
            },
            body: formData
        }).then(async response => await response.json())
            .then( res => {
                id=res.id
                dataStore.updateImageWordPressSelected(id)
            });
    },[file1])

    // Filter TaxSpecialismList To Be included In The List /Wordpress-conf/taxSpecialism.tsx
    const filterTaxSpecialisme=(taxSpecialismList:string[])=>{
        return taxSpecialismList.filter(x => taxSpecialismeListWordpress.includes(x))
    }

    // Build Acf Data
    const userWordpressUpdateACF= {
        fields: {
            //display_email: updatedUserData.email,
            telephone: userData.phone,
            mobile: userData.mobile,
            biography: !!userData.profesionnalBiography ? textFormat(userData.profesionnalBiography):"",
            connect_url: userData.linkedInProfileLink,
            assistants_name: userData.assistantName,
            assistants_email: userData.assistantEmail,
            position: grade,
            user_location: countryId,
            show: isVisible,
            area_of_expertise: taxSpecialism && taxSpecialism.length > 0 ? filterTaxSpecialisme(taxSpecialism) : "",
            company_logo:firm.logo_id
        }
    }

    //Build Native Data
    let randomCharacter=characters.charAt(Math.floor(Math.random() * characters.length))
    const userWordpressUpdateNative={
        name:userData.firstName.replace(/[^a-zA-Z0-9]/g,randomCharacter)+" "+userData.lastName.replace(/[^a-zA-Z0-9]/g,randomCharacter),
        first_name: userData.firstName,
        last_name: userData.lastName,
        url: firm.webSite,
    }
    // Check If New Email Added Already Exist And Get His WordPress id
    const checkUserWordpress=async (email:string)=>{
        const users=dataStore.deletedUsers;
        let id;
        for (let user in users) {
            if(users[user].email===email ){
                id=users[user].wordpress_id;
                break
            }
        }
        return id;
    }
    // Global Submit function
    const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            try {

                setWorking(true)
                //If File Not Changed And AvatarUrl Exist on Firebase So We Execute UploadImageWordpress
                if(!fileChanged && userData.avatarUrl){
                    await uploadImageWordpress()
                }

                // If File Changed We Execute getMediaId() to Upload Image To WordPress And Get Id
                if(fileChanged) {
                    await getMediaId();
                }
                //Get Image WordPress id from Store depend on Witch last Two Condition Executed
                const image=dataStore.imageWordPressSelected;

                // If on Firebase IsVisible And On Web Form Not
                if(dataRow.isVisible === true && !isVisible){

                    // Update Native Data WordPress For The User Exist Already
                    await callFirebaseFunction('updateUserWordpressNative', 'PUT',{
                        userIdWordpress: userData.wordpress_id,
                        token: localStorage.getItem("wordpressToken"),
                        first_name: userWordpressUpdateNative.first_name,
                        last_name: userWordpressUpdateNative.last_name,
                        name: userWordpressUpdateNative.name,
                        url: userWordpressUpdateNative.url
                    }, token);

                    // Update Acf Data WordPress For The User Exist Already
                    await callFirebaseFunction('updateUserWordpressAcf','PUT', {
                        userIdWordpress:wordpress_id,
                        token:localStorage.getItem("wordpressToken"),
                        image:image,
                        telephone: userWordpressUpdateACF.fields.telephone,
                        mobile: userWordpressUpdateACF.fields.mobile,
                        biography: userWordpressUpdateACF.fields.biography,
                        connect_url: userWordpressUpdateACF.fields.connect_url,
                        assistants_name: userWordpressUpdateACF.fields.assistants_name,
                        assistants_email: userWordpressUpdateACF.fields.assistants_email,
                        position: userWordpressUpdateACF.fields.position,
                        user_location: userWordpressUpdateACF.fields.user_location,
                        show: userWordpressUpdateACF.fields.show,
                        area_of_expertise:  userWordpressUpdateACF.fields.area_of_expertise,
                        company_logo:userWordpressUpdateACF.fields.company_logo

                    }, token);
                }
                // Check If User Email Already Exist
                const id= await checkUserWordpress(userData.email)

                // if User isVisible Field === True
                if(isVisible){

                // If Type Of Request Is Create
                if(dataRow.type === "Create"){

                    //Check If id User Already Exist
                    if(id || !DeletedusernameVerified){

                        // Update Native Data WordPress For The User Exist Already
                        await callFirebaseFunction('updateUserWordpressNative','PUT', {
                            userIdWordpress: !!deleteduserwordpressId?deleteduserwordpressId:id,
                            token: localStorage.getItem("wordpressToken"),
                            first_name: userWordpressUpdateNative.first_name,
                            last_name: userWordpressUpdateNative.last_name,
                            name: userWordpressUpdateNative.name,
                            url: userWordpressUpdateNative.url

                        }, token);
                        // Update Acf Data WordPress For The User Exist Already
                        await callFirebaseFunction('updateUserWordpressAcf','PUT', {
                            userIdWordpress:!!deleteduserwordpressId?deleteduserwordpressId:id,
                            token:localStorage.getItem("wordpressToken"),
                            image:image,
                            telephone: userWordpressUpdateACF.fields.telephone,
                            mobile: userWordpressUpdateACF.fields.mobile,
                            biography: userWordpressUpdateACF.fields.biography,
                            connect_url: userWordpressUpdateACF.fields.connect_url,
                            assistants_name: userWordpressUpdateACF.fields.assistants_name,
                            assistants_email: userWordpressUpdateACF.fields.assistants_email,
                            position: userWordpressUpdateACF.fields.position,
                            user_location: userWordpressUpdateACF.fields.user_location,
                            show: userWordpressUpdateACF.fields.show,
                            area_of_expertise:  userWordpressUpdateACF.fields.area_of_expertise,
                            company_logo:userWordpressUpdateACF.fields.company_logo
                        }, token);
                    }
                  //Check If id User Not Exist
                else {
                        // Create WordPress User By Insert Native Data
                        await callFirebaseFunction('createUserWordPressNativeFunction','POST', {
                            token: localStorage.getItem("wordpressToken"),
                            name: userWordpressUpdateNative.name,
                            username: userWordpressUpdateNative.name,
                            first_name: userWordpressUpdateNative.first_name,
                            last_name: userWordpressUpdateNative.last_name,
                            email: userData.email,
                            password: "i5Z%sTF6tdu&#*h#ydq$J%PM",
                            url: userWordpressUpdateNative.url,
                            description: ""
                        }, token).then(res => {
                            //Return WordPress id
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            wordpress_id = res.data.userId;

                        });
                        // To Insert ACF data Should Use Update Because Creation needs Just Native Data
                        await callFirebaseFunction('updateUserWordpressAcf','PUT', {
                            userIdWordpress: wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            image: image,
                            telephone: userWordpressUpdateACF.fields.telephone,
                            mobile: userWordpressUpdateACF.fields.mobile,
                            biography: userWordpressUpdateACF.fields.biography,
                            connect_url: userWordpressUpdateACF.fields.connect_url,
                            assistants_name: userWordpressUpdateACF.fields.assistants_name,
                            assistants_email: userWordpressUpdateACF.fields.assistants_email,
                            position: userWordpressUpdateACF.fields.position,
                            user_location: userWordpressUpdateACF.fields.user_location,
                            show: userWordpressUpdateACF.fields.show,
                            area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                            company_logo:userWordpressUpdateACF.fields.company_logo

                        }, token);
                    } }

                //If Type Of Request Is Update
                if(dataRow.type === "Update"){

                    // If User Has Already WordPress id
                    if(userData.wordpress_id) {

                        // Update Native Data WordPress For The User Exist Already
                        await callFirebaseFunction('updateUserWordpressNative','PUT', {
                            userIdWordpress: userData.wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            first_name: userWordpressUpdateNative.first_name,
                            last_name: userWordpressUpdateNative.last_name,
                            name: userWordpressUpdateNative.name,
                            url: userWordpressUpdateNative.url

                        }, token);
                    }
                    // id User Has No WordPress id
                    if(!userData.wordpress_id){
                        // Create WordPress User By Insert Native Data
                        const res =await callFirebaseFunction('createUserWordPressNativeFunction', 'POST',{
                            token:localStorage.getItem("wordpressToken"),
                            name:userWordpressUpdateNative.name,
                            username:userWordpressUpdateNative.name,
                            first_name:userWordpressUpdateNative.first_name,
                            last_name:userWordpressUpdateNative.last_name,
                            email:userData.email,
                            password:"i5Z%sTF6tdu&#*h#ydq$J%PM",
                            url:userWordpressUpdateNative.url,
                            description: "",
                            callType: ""
                        }, token);
                        if (res.data && res.data.userId) {
                            // eslint-disable-next-line
                            wordpress_id = res.data.userId;
                        } else {
                            notification["error"]({
                                message: "User created but no ID returned",
                                description: "User created but no ID returned",
                            });
                        }
                    }

                    // For The Two last Condition If He Has WordPress Or Not We Update ACF Data
                    await callFirebaseFunction('updateUserWordpressAcf','PUT', {
                        userIdWordpress: !!userData.wordpress_id ? userData.wordpress_id : wordpress_id,
                        token: localStorage.getItem("wordpressToken"),
                        image: image && !pictureDeleted ? image : 9999999,
                        telephone: userWordpressUpdateACF.fields.telephone,
                        mobile: userWordpressUpdateACF.fields.mobile,
                        biography: userWordpressUpdateACF.fields.biography,
                        connect_url: userWordpressUpdateACF.fields.connect_url,
                        assistants_name: userWordpressUpdateACF.fields.assistants_name,
                        assistants_email: userWordpressUpdateACF.fields.assistants_email,
                        position: userWordpressUpdateACF.fields.position,
                        // user_location: userWordpressUpdateACF.fields.user_location,
                        show: userWordpressUpdateACF.fields.show,
                        area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                        company_logo:userWordpressUpdateACF.fields.company_logo
                    }, token);
                }

                }
                if(!DeletedusernameVerified){
                    deleteduserwordpressId=dataStore.deletedUserWordpressId; // eslint-disable-line react-hooks/exhaustive-deps
                }
                // Build Data For Firebase database
                const userUpdate = {
                    id: userData.userId,
                    email: userData.email,
                    firstName: !!userData.firstName ? userData.firstName:"",
                    lastName: !!userData.lastName ? userData.lastName:"",
                    phone: !!userData.phone ? userData.phone:"",
                    mobile: !!userData.mobile ? userData.mobile:"",
                    avatarUrl: !!userData.avatarUrl ? userData.avatarUrl:"",
                    deleted: userData.deleted ? userData.deleted:false,
                    linkedInProfileLink: !!userData.linkedInProfileLink ? userData.linkedInProfileLink:"",
                    twitterProfileLink: !!userData.twitterProfileLink ? userData.twitterProfileLink:"",
                    assistantId:!!assistant.id?assistant.id:userData.assistantId,
                    assistantName: !!userData.assistantName ? userData.assistantName:"",
                    assistantEmail: !!userData.assistantEmail ? userData.assistantEmail:"",
                    assistantTelephone: !!userData.assistantTelephone ? userData.assistantTelephone:"",
                    assistantMobile: !!userData.assistantMobile ? userData.assistantMobile:"",
                    personnalBiography: !!userData.personnalBiography ? userData.personnalBiography:"",
                    profesionnalBiography: !!userData.profesionnalBiography ? userData.profesionnalBiography:"",
                    isVisible: isVisible ? isVisible:false,
                    right: !!right ? right:"",
                    firm: !!firm ? firm:"",
                    grade: !!grade ? grade : "",
                    country: !!country ? country : "",
                    areaOfIndustryExpertise: areaOfIndustryExpertise.length > 0 ? toJS(areaOfIndustryExpertise) : "",
                    taxSpecialisme: taxSpecialism.length > 0 ? toJS(taxSpecialism) : "",
                    wordpress_id:!!deleteduserwordpressId ?deleteduserwordpressId :!!id ? id: !!userData.wordpress_id ? userData.wordpress_id : !!wordpress_id?wordpress_id:"",
                    approvalStatus: ApprovalStatus.Approved,
                    lastUpdated: new Date().toISOString().replace('T', ' ').substr(0, 10)
                };

                await updateUserAssitantInfo(userUpdate.id)
                // Update User Firebase
                const res = await API.updateUser(userUpdate, userData.userId);
                if (res) {

                    if(dataRow.type === 'Create') {
                        //Enable Account Authentication After Request Accepted By Admin
                        await callFirebaseFunction('settingAccount','POST', {userId: userData.userId}, token);
                    }
                    // If File Changed Upload Image Using id Of User
                    if (fileChanged) {
                        await uploadImage(userData.userId)
                        setFileChanged(false)
                    }

                    //Get Request Info
                    const requestRes = await API.getRequestInfo(userData.requestId)
                    //If File Not Change and Request Contain avatarUrl Move Image From userRequest Storage To userAvatar Storage
                    if (!fileChanged && !!requestRes.avatarUrl) {
                        await moveFirebaseFile(userData.userId, userData.requestId)
                    }

                    //Clean DataStore
                    dataStore.updateImageWordPressSelected(0);
                    await databaseRef.ref("approval_request/" + userData.requestId).remove();
                    // On Success Stop Submit And Close Model and Return Message Success
                    onCloseCallback();
                    notification["success"]({
                        message: "Success",
                        description: t("Approval request successfully accepted"),
                    });
                }
                setWorking(true);
                setPictureDeleted(false)
                onCloseCallback();
            } catch (error) {
                // On Error Stop Submit And Close Model and Return Message Error
                onCloseCallback();
                notification["error"]({
                    message: "Error",
                    description: t("Failed to accept approval request "),
                });
                setWorking(false)
                setPictureDeleted(false)
            }
        }, [isVisible,pictureDeleted,fileChanged, firm,right,onCloseCallback,assistant,taxSpecialism, areaOfIndustryExpertise, grade, country, userData, t]// eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    const token = await user.getIdToken();
                    setToken(token);
                } else {
                    setToken('');
                }
            }
        );
    }, []);

    // First Loading Components
    useEffect(() => {
        const loadData = async () => {
            setLoadingDataModal(true);
            try {
                await loadDataUser();
                await loadDataFirms();
                await loadDataGrade();
                await loadDataCountry();
                await loadDataAreaOfIndustryExpertise();
                await loadDataTaxSpecialism();
                await loadDataRights();
            } finally {
                setLoadingDataModal(false);
            }
        };

        loadData().then();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        checkUsername().then();
        checkDeletedUsername()
    }, [userData.firstName,userData.lastName]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
       loadDataAssistant().then()
    }, [country,firm]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleRemove = () => {
        setPreview(undefined)
        setPictureDeleted(true)

    };

    return (
    <div className="UserModal">
        <Spin spinning={loadingDataModal} size="large">
            <div className="ProfileHeader" style={{display : "flex" , alignItems : "center" , justifyContent : "space-between"}}>
                <div className="flexHeader">
                    <label  htmlFor="upload">
                        <Avatar
                            size={80}
                            icon={preview === "" ? <UserOutlined /> : undefined}
                            src={preview}
                            style={dataRow?.avatarUrl !== undefined && dataRow?.type !== "Create" ? {border: "2px solid #3FA037",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)" , cursor: 'pointer'} : {
                                marginTop: "10px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)" , cursor: 'pointer'
                            }}
                        />
                    </label>

                    <label className="UploadFileContainer"  htmlFor="upload">
                        <div className="UploadFile">
                            <CloudUploadOutlined />
                            <span>Upload your image</span>
                        </div>
                        <span className={"d-flex"}>
                      <Tooltip title="For the photo fits perfectly in the circle, the width and height of the photo must be as close as possible.">
                       <InfoIcon/>
                     </Tooltip>
                        Only PNG or JPG files are valid .</span>
                    </label>
                    <DeleteOutlined className={"UploadContainerAdd"} onClick={handleRemove}/>
                    <input
                        multiple={true}
                        type="file"
                        onChange={(e) => handleChangeFile(e)}
                        id="upload"
                        accept="image/png, image/jpeg"
                    />
                </div>
                <div className="d-flex">
                    <Button key="back" onClick={deleteRequest}>
                        Reject changes
                    </Button>
                    <Button style={{marginLeft : "15px"}} key="submit" type="primary" disabled={working || !usernameVerified} loading={working}  onClick={(e)=>handleSubmit(e)}>
                        Accept changes
                    </Button>
                </div>
            </div>
            <h2 className="ContactTitle">Contact</h2>
            <ContactInfo
                listCountries={listCountries}
                handleChangeCountry={handleChangeCountry}
                data={userData}
                handleDataChange={handleDataChange}
                usernameVerified={usernameVerified}
                emailVerified={emailVerified}
                canSubmit={canSubmitArea}
                isValidPassword={isValidPassword}
                isValidPasswordAgain={isValidPasswordAgain}
                action={'Edit'}
                country={country}
                dataRow={dataRow}
                checkUserCountryWithFirmCountryActive={checkUserCountryWithFirmCountryActive}
            />
            <h2 className="ContactTitle">Executive Assistant Contact Details</h2>
            <AssistantInfo
                assistant={assistant}
                assistants={assistants}
                handleChangeAssistant={
                    handleChangeAssistant
                }
                data={userData}
                handleDataChange={
                    handleDataChange
                }
                action="Edit"
                dataRow={dataRow}

            />
            <h2 className="ContactTitle">People information</h2>
            <PeopleInfo
                handleChangefirm={handleChangefirm}
                handleChangeGrade={handleChangeGrade}
                handleDataChange={
                    handleDataChange
                }
                firms={firms}
                firm={firm}
                grades={grades}
                grade={grade}
                handleChangeAreaOfIndustryExpertise={
                    handleChangeAreaOfIndustryExpertise
                }
                handleChangeTaxSpecialism={handleChangeTaxSpecialism}
                listAreaOfIndustryExpertise={listAreaOfIndustryExpertise}
                areaOfIndustryExpertise={areaOfIndustryExpertise}
                listTaxSpecialism={listTaxSpecialism}
                taxSpecialism={taxSpecialism}
                handleOpen={handleOpen}
                data={userData}
                action={'Edit'}
                dataRow={dataRow}
                checkUserCountryWithFirmCountryActive={checkUserCountryWithFirmCountryActive}

            />
            <h2 className="ContactTitle">Administration information</h2>
            <AdministrationInfo
                handleChange={handleChange}
                right={right}
                rights={rights}
                isVisible={isVisible}
                toggleVisible={toggleVisible}
                data={userData}
                action={'Edit'}
                dataRow={dataRow}

            />

            {/*Modal add new area */}
            <Modal
                visible={openNewArea}
                title="Add new area"
                onOk={() => {
                    addNewAreas(inputValue).then();
                }}
                onCancel={handleClose}
                destroyOnClose
                footer={[
                    <Button key="back" onClick={handleClose}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        disabled={!canSubmitArea}
                        onClick={() => {
                            addNewAreas(inputValue).then();
                        }}
                    >
                        add
                    </Button>,
                ]}
            >
                <Paper className={styles.paperArea}>
                    <form onSubmit={handleSubmitArea}>
                        {/*Content*/}
                        {/*Areas of industry Expertise*/}
                        <div style={{ padding: "20px" }}>
                            <TextField
                                required
                                style={{ width: "100%" }}
                                id="value-input"
                                onChange={handleClick}
                                label="Areas of industry Expertise"
                            />
                        </div>
                        <br />
                    </form>
                </Paper>
            </Modal>
        </Spin>
    </div>)

}

export default inject("dataStore", "messageStore")(observer(UpdateApproval));
