
import {inject, observer} from "mobx-react";
import React, {FormEvent, useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Button, CircularProgress, FormHelperText, Grid, IconButton, Paper, TextField} from "@material-ui/core";
import {API} from "../../../../api";
import styles from "../../users/addUser/AddUserTaxand.module.scss";
import company from '../../../../assets/company-logo.jpg';
import firebase from 'firebase/app';
import {makeStyles} from "@material-ui/core/styles";
import {notification} from "antd";


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiAvatar-img": {
            objectFit:"contain"
        }
    }
}));


function getModalStyle() {
    return {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };
}

interface AddFirmProps {
    onCloseCallback: any;
}
const AddFirm = (props: AddFirmProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { onCloseCallback } = props;
    const [preview, setPreview] = useState(company);
    const [fileChanged, setFileChanged] = useState(false)
    const [modalStyle] = React.useState(getModalStyle);
    const [working, setWorking] = useState(false);
    const [token, setToken] = useState('');
    const [data, setData] = useState({
        firm:"",
        webSite:"",
        country:"",
        deleted:false,
    });
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [pdfChanged, setPdfChanged] = useState(false);

    let file1: any;

    const handleDataChange = useCallback(
        ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
            setData((d) => ({ ...d, [currentTarget.id]: currentTarget.value }));
        },
        []
    );


    const handleChangeFile = useCallback(async (event: any) => {
        const objectUrl = URL.createObjectURL(event.target.files[0]);
        setPreview(objectUrl)
        file1 = event.target.files[0] //eslint-disable-line react-hooks/exhaustive-deps
        setFileChanged(true)
    },[]);

    // Handle PDF file change
    const handlePdfChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            setPdfFile(event.target.files[0]);
            setPdfChanged(true);
        }
    }, []);

    const canSubmit = useMemo(() => !!data.firm , [data]);

    const uploadImage = useCallback(async (id) => {
        await firebase.storage().ref("firmLogo/" + id + ".jpg").put(file1)
        await firebase.storage().ref("firmLogo").child(id + ".jpg").getDownloadURL().then(url => {
            const updates: any = {};
            updates['firmLogoUrl'] = url;
            firebase.database().ref('firm/' + id).update(updates)
        })
    }, [file1]);

    // Function to upload the PDF file to Firebase Storage
    const uploadPdf = useCallback(async (firmId: string): Promise<string | null> => {
        if (pdfFile) {
            const storageRef = firebase.storage().ref(`firmPdf/${firmId}.pdf`);
            await storageRef.put(pdfFile);
            return storageRef.getDownloadURL();
        }
        return null;
    }, [pdfFile]);

    const getMediaId = useCallback(async () => {
        let id = '';
        const formData = new FormData();
        formData.append("file", file1);
        const response = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("wordpressToken")}`,
            },
            body: formData
        });

        if (response.ok) {
            const data = await response.json();
            id = data.id;
        } else {
            // Handle the error here
            throw new Error('Failed to upload media');
        }
        return id;
    }, [file1]);

    const handleSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!canSubmit) {
                return;
            }

            try {
                setWorking(true);

                let firmLogoId = '';
                if (fileChanged) {
                    firmLogoId = await getMediaId();
                }

                const newFirmId = await API.getFirmId();

                // Upload PDF and get URL
                const pdfUrl = pdfChanged ? await uploadPdf(newFirmId) : null;

                const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/createFirmFunction`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        id: newFirmId,
                        firm: data.firm,
                        webSite: data.webSite,
                        country: data.country,
                        logo_id: firmLogoId,
                        deleted: data.deleted,
                        firmFilePdf: pdfUrl,
                    })
                });

                if (response.ok) {
                    const responseData = await response.json();
                    // Change Image Firebase And Name It By The id of User
                    if (fileChanged) await uploadImage(responseData.firm_res);
                    notification["success"]({
                        message: "Success",
                        description: t("Firm successfully added")
                    });
                    onCloseCallback();
                } else {
                    throw new Error('An error occurred while creating the firm');
                }
            } catch (error:any) {
                notification["error"]({
                    message: "error",
                    description: t("Failed to add firm") + (error.message ? `: ${error.message}` : '')
                });
                onCloseCallback();
                setWorking(false);
                setData({
                    firm: "",
                    webSite: "",
                    country: "",
                    deleted: false
                });
            }
        },
        [canSubmit, data, onCloseCallback, fileChanged, pdfChanged, t, token, getMediaId, uploadImage, uploadPdf]
    );

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    const token = await user.getIdToken();
                    setToken(token);
                } else {
                    setToken('');
                }
            }
        );
    }, []);
    useEffect(()=>{
    },[file1])


    return(
        <div style={modalStyle} className={"modal-firm"}>

                <Paper className={'paper'}>
                    <form onSubmit={handleSubmit}>
                 <Grid container>
                   <Grid item sm={4} xs={12} style={{paddingTop:"5%",opacity:fileChanged?1:0.5}} >
                            <input multiple={true} type="file" onChange={(e) => handleChangeFile(e)} id="upload"
                                   accept="image/*" style={{display: "none"}}/>
                            <label htmlFor="upload" className={styles.cardHeaderFirm}>
                                <IconButton color="primary" aria-label="upload picture" component="span" style={{borderRadius:'0'}}>
                                    <Avatar id="avatar" src={preview} className={`${classes.root }`}
                                            style={{
                                                borderRadius:0,
                                                width: "140px" ,
                                                height: "110px",
                                            }}
                                    />
                                </IconButton>
                            </label>
                            <label htmlFor="avatar"/>
                        </Grid>
                        <Grid item sm={8} xs={12} >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <TextField
                                className={'input'}
                                required
                                id="firm"
                                label="Firm"
                                onChange={handleDataChange}
                            />
                            <TextField
                                className={'input'}
                                required
                                id="webSite"
                                label="WebSite"
                                onChange={handleDataChange}
                            />
                            <TextField
                                className={'input'}
                                id="country"
                                label="Country"
                                onChange={handleDataChange}
                            />
                            <FormHelperText>make sure that you add commas between countries.</FormHelperText>
                            <FormHelperText>ex: Spain, Italy, ....</FormHelperText>
                            <br/>
                            <input
                                type="file"
                                id="uploadPdf"
                                onChange={handlePdfChange}
                                accept="application/pdf"
                                style={{ display: "none" }}
                            />
                            <label htmlFor="uploadPdf">
                                <Button component="span" variant="outlined">
                                    {t('Upload PDF')}
                                </Button>
                            </label>
                            <br/>
                            <Button
                                style={{margin:"10px"}}
                                className={'input'}
                                type="submit"
                                variant="contained"
                                disabled={!canSubmit || working}
                            >
                                {t('Create new firm')}
                                {working === true ? <CircularProgress/> : ""}
                            </Button>
                        </Grid>
                        </Grid>
                    </Grid>
                    </form>
                </Paper>

        </div>);
};

export default inject("dataStore", "messageStore")(observer(AddFirm));
