import {inject, observer} from "mobx-react";
import React, {useCallback, useEffect, useState} from "react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {Button, DatePicker, Input, notification, Popconfirm, Spin, Tabs, Progress} from "antd";
import {useTranslation} from "react-i18next";
import firebase from 'firebase/app';
import {
    areaOfIndustryExpertiseRef,
    countryRef,
    firmRef,
    gradeRef,
    rightsRef,
    taxSpecialismRef
} from "../../../../services/firebase/firebase";
import dataStore from "../../../../stores/dataStore";
import {API} from "../../../../api";
import messageStore from "../../../../stores/messageStore";
import "./ExportUser.scss";
import {LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import Alert from '@material-ui/lab/Alert';
import {ReactComponent as Upload} from "assets/taxanders/upload.svg";
import {ReactComponent as File} from "assets/taxanders/file.svg";
import {ReactComponent as Delete} from "assets/taxanders/delete.svg";
import moment, {Moment} from 'moment';
import {taxSpecialismeListWordpress} from "../../../wordpress-conf/tax-specialism-conf";
import TooltipPassword from "../../Widgets/TooltipPassword";
import {sendEmailCredentials} from "../../../../Utils/emailUtils";
import {callFirebaseFunction} from "../../../../Utils/apiUtils";

interface UserDetail {
    email: string;
    firstName: string;
    lastName: string;
    right: string;
    country: string;
    firm: string;
    lastSignInTime: string;
}

const ExportUser = () => {
    const [token, setToken] = useState(''); // State hook for Firebase authentication token
    const {t} = useTranslation();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [csvFile, setCsvFile] = useState<any>();
    const [csvFileToDelete, setCsvFileToDelete] = useState<any>();
    const [csvFileToSendEmail, setCsvFileToSendEmail] = useState<any>();
    const [csvFileToUpdatePassword, setCsvFileToUpdatePassword] = useState<any>();
    const [listTaxSpecialism, setListTaxSpecialism] = useState<string[]>([]);
    const [grades, setListGrades] = useState<string[]>([]);
    const [rights, setListRights] = useState<string[]>([]);
    const [listAreaOfIndustryExpertise, setListAreaOfIndustryExpertise] = useState<string[]>([]);
    const isAddButtonDisabled = !csvFile;
    const isDeleteButtonDisabled = !csvFileToDelete;
    const isSendEmailButtonDisabled = !csvFileToSendEmail;
    const isUpdateListPasswordButtonDisabled = !csvFileToUpdatePassword;
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const fileDeleteInputRef = React.useRef<HTMLInputElement>(null);
    const fileSendEmailInputRef = React.useRef<HTMLInputElement>(null);
    const fileUpdatePasswordInputRef = React.useRef<HTMLInputElement>(null);
    const [currentUserRight, setCurrentUserRight] = useState("");
    const [addFileName, setAddFileName] = useState('Not selected file');
    const [deleteFileName, setDeleteFileName] = useState('Not selected file');
    const [updatePasswordFileName, setUpdatePasswordFileName] = useState('Not selected file');
    const [sendEmailFileName, setSendEmailFileName] = useState('Not selected file');
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    // eslint-disable-next-line
    let [rows, setRows] = useState<any>([]);
    let characters = "abcdefghijklmnopqrstuvwxyz";
    //let wordpress_id: any;
    let randomCharacter = characters.charAt(Math.floor(Math.random() * characters.length));
    /* reset password */
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingUpdatePassword, setIsLoadingUpdatePassword] = useState(false);
    const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
    const [isLoadingLastConnection, setIsLoadingLastConnection] = useState(false);
    const [isLoadingDateCreatedAfter, setIsLoadingDateCreatedAfter] = useState(false);
    const [isLoadingAllUsers, setIsLoadingAllUsers] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingIcon = <LoadingOutlined style={{fontSize: 24, color: "white", marginLeft: '5px'}}/>
    const [retVal, setRetVal] = useState('');
    // eslint-disable-next-line
    const [file1, setfile1] = useState<any>();
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [passwordVal, setPasswordValidation] = useState({
        displayVal: "none",
        uppercase: false,
        lowercase: false,
        specialChar: false,
        numeral: false,
        minChar: false,
        maxChar: true,
        valid: false
    });

    /* number percent */
    const [percentAdd, setAddPercent] = useState<number>(0);
    const [percentDelete, setDeletePercent] = useState<number>(0);
    const [percentUpdate, setUpdatePercent] = useState<number>(0);
    const [percentSend, setSendPercent] = useState<number>(0);
    const [copiedMessage, setCopiedMessage] = useState('');
    const [selectedDateCreatedAfter, setSelectedDateCreatedAfter] = useState<string | null>(null);
    /* Load Data  */
    const loadDataFirm = useCallback(async () => {
        try {
            firmRef.on("value", async (snapshot: any) => {
                let firms = snapshot.val();
                let newFirms: any[] = [];
                for (let firm in firms) {
                    const detailedFirm: any = await API.getFirmInfo(firm);
                    if (!detailedFirm.deleted) {
                        newFirms.push(detailedFirm);
                    }
                }
                dataStore.updateFirms(newFirms);
            });

        } catch (error) {
            messageStore.snackbar({
                message: t("Failed fetching firms"),
                type: "error",
            });
        }
    }, [t]);
    const loadDataTaxSpecialism = useCallback(() => {
        taxSpecialismRef.on('value', (snap) => {
            const result = snap.val();
            setListTaxSpecialism(result);
        });
    }, []);
    const loadDataAreaOfIndustryExpertise = useCallback(() => {
        areaOfIndustryExpertiseRef.on('value', async (snap) => {
            const areaOfIndustryExpertiseList: string[] = [];
            const areaOfIndustryExpertise = snap.val();
            for (let f in areaOfIndustryExpertise) {
                const detailedAreaOfExpertise = await API.getAreaOfExpertiseInfo(f);
                areaOfIndustryExpertiseList.push(detailedAreaOfExpertise);
            }
            setListAreaOfIndustryExpertise(areaOfIndustryExpertiseList);
        });
    }, []);
    const loadDataGrade = useCallback(() => {
        gradeRef.on('value', (snap) => {
            let gradesList: any[] = [];
            snap.forEach(s => {
                gradesList.push(s.val())
            })
            setListGrades(gradesList as any);
        });

    }, []);
    const loadDataRight = useCallback(() => {
        rightsRef.on('value', (snap) => {
            let rightsList: any[] = [];
            snap.forEach(s => {
                rightsList.push(s.val())
            })
            setListRights(rightsList as any);
        });
    }, []);
    const hasGlobalAdminRight = currentUserRight === "Global administrator";

    /* Import file drop and drag and set name file  */
    const handleFileChangeAdd = (e: any) => {
        setAddPercent(0);
        const selectedFile = e.target.files[0];
        setCsvFile(selectedFile);
        setAddFileName(selectedFile ? selectedFile.name : 'Not selected file');
        e.target.value = '';
    };
    const handleFileChangeDelete = (e: any) => {
        setDeletePercent(0);
        const selectedFile = e.target.files[0];
        setCsvFileToDelete(selectedFile);
        setDeleteFileName(selectedFile ? selectedFile.name : 'Not selected file');
        e.target.value = '';
    };
    const handleFileChangeUpdateListPassword = (e: any) => {
        setUpdatePercent(0);
        const selectedFile = e.target.files[0];
        setCsvFileToUpdatePassword(selectedFile);
        setUpdatePasswordFileName(selectedFile ? selectedFile.name : 'Not selected file');
        e.target.value = '';
    };
    const handleFileChangeSend = (e: any) => {
        setSendPercent(0);
        const selectedFile = e.target.files[0];
        setCsvFileToSendEmail(selectedFile);
        setSendEmailFileName(selectedFile ? selectedFile.name : 'Not selected file');
        e.target.value = '';
    };

    const handleDropAdd = (e: any) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        if (selectedFile) {
            setAddFileName(selectedFile.name);
            setCsvFile(selectedFile);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };
    const handleDropDelete = (e: any) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        if (selectedFile) {
            setDeleteFileName(selectedFile.name);
            setCsvFile(selectedFile);
            if (fileDeleteInputRef.current) {
                fileDeleteInputRef.current.value = '';
            }
        }
    };

    const handleDropUpdatePassword = (e: any) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        if (selectedFile) {
            setUpdatePasswordFileName(selectedFile.name);
            setCsvFile(selectedFile);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };
    const handleDropSend = (e: any) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        if (selectedFile) {
            setSendEmailFileName(selectedFile.name);
            setCsvFileToSendEmail(selectedFile);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };
    const handleDragOver = (e: any) => {
        e.preventDefault();
    };
    const handleDateChange = (date: moment.Moment | null) => {
        if (date) {
            // No need to convert to start of day as backend does this
            const utcDate = date.utc().format(); // Convert to UTC but keep the time
            setSelectedDate(utcDate);
        } else {
            setSelectedDate(null);
        }
    };
    const onDateChange = (date: Moment | null, dateString: string) => {
        if (date) {
            setSelectedDateCreatedAfter(dateString);
        } else {
            setSelectedDateCreatedAfter(null);
        }
    };

    const handleDeleteFile = ((e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (fileInputRef.current) {
            fileInputRef.current.value = "";
            setAddFileName('Not selected file');
            setCsvFile(null);
        }

        if (fileDeleteInputRef.current) {
            fileDeleteInputRef.current.value = "";
            setDeleteFileName('Not selected file');
            setCsvFileToDelete(null);
        }

        if (fileUpdatePasswordInputRef.current) {
            fileUpdatePasswordInputRef.current.value = "";
            setUpdatePasswordFileName('Not selected file');
            setCsvFileToUpdatePassword(null);
        }

        if (fileSendEmailInputRef.current) {
            fileSendEmailInputRef.current.value = "";
            setSendEmailFileName('Not selected file');
            setCsvFileToSendEmail(null);
        }
    });
    const handleUploadClick = () => {
        // Programmatically click the hidden file input
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleUploadClickDelete = () => {
        if (fileDeleteInputRef.current) {
            fileDeleteInputRef.current.click();
        }
    };

    const handleUploadClickUpdate = () => {
        if (fileUpdatePasswordInputRef.current) {
            fileUpdatePasswordInputRef.current.click();
        }
    };

    const handleUploadClickSend = () => {
        if (fileSendEmailInputRef.current) {
            fileSendEmailInputRef.current.click();
        }
    };

    // Utility function for generating a random password
    const generatePassword = () => {
        const length = Math.floor(Math.random() * (20 - 8 + 1)) + 8; // Random length between 8 and 20,
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+={[}]|<?/";
        let retVal = "";

        // At least one of each character type
        const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*()_-+={[}]|<?/';

        retVal += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
        retVal += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
        retVal += numbers.charAt(Math.floor(Math.random() * numbers.length));
        retVal += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

        for (let i = 4; i < length; i++) {
            retVal += charset.charAt(Math.floor(Math.random() * charset.length));
        }

        // Shuffle the password characters
        retVal = retVal.split('').sort(() => Math.random() - 0.5).join('');

        setRetVal(retVal);

        return retVal;
    };

    const generatePasswordImport = () => {
        const length = Math.floor(Math.random() * (20 - 8 + 1)) + 8; // Random length between 8 and 20,
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+={[}]|<?/";
        let retValImport = "";

        // At least one of each character type
        const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*()_-+={[}]|<?/';

        retValImport += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
        retValImport += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
        retValImport += numbers.charAt(Math.floor(Math.random() * numbers.length));
        retValImport += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

        for (let i = 4; i < length; i++) {
            retValImport += charset.charAt(Math.floor(Math.random() * charset.length));
        }

        // Shuffle the password characters
        retValImport = retValImport.split('').sort(() => Math.random() - 0.5).join('');

        return retValImport;
    };

    // Load Data User
    const loadData = useCallback(async () => {
        try {
            API.getAllUserInfo().then(res => {
                dataStore.updateUsers(res);
                setRows(dataStore.users);
                API.getDeletedUserInfo().then(delRes => {
                    dataStore.updateDeletedUsers(delRes);
                    //setDataLoading(false);
                })
            })

        } catch (error) {
            messageStore.snackbar({
                message: t("Failed fetching users"),
                type: "error",
            });
        }
    }, [t]);

    // Check If New Email Added Already Exist And Get His WordPress Id
    const checkUserWordpress = async (email: string) => {
        try {
            const tokenWordpress = localStorage.getItem("wordpressToken");
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUserWordPressById?email=${email.trim()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokenWordpress}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const res = await response.json();
                return res.userId;
            } else if (response.status === 404) {
                return null; // Return null if user not found
            } else {
                throw new Error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error('Error checking WordPress user:', error);
            throw error;
        }
    };

    // Change Biography Formt To Be Accept By WordPress
    const textFormat = (biography: string) => {
        let text = biography;
        let pStart = "<p>";
        let pEnd = "</p>";
        let formattedText = pStart;
        let count = 0;

        for (let i = 0; i < text.length; i++) {
            if (text[i] === '\n') {
                if (count % 2 === 0) {
                    formattedText += text.substring(count, i) + pEnd + "&nbsp;";
                } else {
                    formattedText += text.substring(count, i) + pStart;
                }
                count = i + 1;
            }
        }
        formattedText += text.substring(count) + pEnd;
        return formattedText;
    }

    const filterTaxSpecialisme = (taxSpecialismList: string[]) => {
        return taxSpecialismList.filter(x => taxSpecialismeListWordpress.includes(x))
    }

    // Get Country Id from List Country Firebase
    const getCountryId = async (countryName: string) => {
        let countryId = null;
        const snapshot = await countryRef.once('value');
        const result = snapshot.val();

        for (let country in result) {
            const detailedCountry = await API.getCountryInfo(country);
            if (detailedCountry === countryName) {
                countryId = country;
                break;
            }
        }

        return countryId;
    };
    function generateValidUsername(firstName:string, lastName:string) {
        // Combine and remove non-alphanumeric characters, no replacement with random characters
        // eslint-disable-next-line
        let username = (firstName + lastName).replace(/[^a-zA-Z0-9_\-]/g, '');
        // Ensure the username is not empty
        if (!username) {
            throw new Error('Invalid username generated from first and last names.');
        }
        return username;
    }

    // Function to process adding new users from Excel file
    const addNewUsers = async (data: any) => {
        let users = data.slice(0, -1);
        const oldUsers = dataStore.users;
        const errors = [];
        const dataGlobal: any = [];
        const invalidEmails: any[] = []; // Array to store invalid emails
        const updatedUsers: any[] = [];// Array to store updated users
        const invalidDataUsers: any[] = []; // Array to store users with invalid data

        // Create a map to keep track of the frequency of each email
        let emailFrequencyMap = new Map();
        // Assuming rights is an array of arrays, and you need the first sub-array
        const validRights = rights.length > 0 ? rights : [];

        // Iterate over the users and populate the frequency map
        users.forEach((user: any) => {
            let userEmail = (user.Email || "").trim().toLowerCase();
            if (userEmail) {
                emailFrequencyMap.set(userEmail, (emailFrequencyMap.get(userEmail) || 0) + 1);
            }
        });

        // Find duplicate emails based on the frequency map
        let duplicateEmails = Array.from(emailFrequencyMap).filter(([, count]) => count > 1).map(([email]) => email);
        const usersKeys = Object.keys(users);
        const totalUsers = usersKeys.length;

        for (let i = 0; i < usersKeys.length; i++) {
            // Calculate the percentage and round it to the nearest integer
            const progress = Math.round(((i + 1) / totalUsers) * 100);
            setAddPercent(progress);
            const userKey = usersKeys[i];
            const user = users[userKey];

            if (user.Email) {
                const userEmail = (user.Email || "").trim().toLocaleLowerCase();
                const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userEmail);
                const isEmailEmpty = userEmail === '';
                const userRight = user.Right ? user.Right.trim() : '';
                const isRightValid = validRights.includes(userRight);

                if (!isValidEmail) {
                    invalidEmails.push(userEmail);
                    continue; // Skip this iteration and move to the next user
                }
                if (duplicateEmails.includes(userEmail)) {
                    continue; // This email is a duplicate within the import file, so we skip further processing
                }
                // Check for empty email or missing/invalid mandatory fields
                if (user.Email === '' || !user.FirstName || !user.LastName || !isRightValid || !user.Country) {
                    invalidDataUsers.push({
                        email: isEmailEmpty ? '' : userEmail,
                        firstName: user.FirstName,
                        lastName: user.LastName,
                        right: user.Right,
                        country: user.Country
                    });
                    continue; // Skip this iteration and move to the next user
                }
                try {
                    //Check If User Email Already Exist In WordPress
                    let wordpress_id = await checkUserWordpress(userEmail);
                    let countryName = user.Country;
                    let countryId = await getCountryId(countryName);
                    let checkUser = oldUsers.find(u => u.email && u.email.trim().toLocaleLowerCase() === user.Email.trim().toLocaleLowerCase())
                    let checkFirm = dataStore.firms.find(f => f.firm.trim().toLocaleLowerCase() === user.Firm.trim().toLocaleLowerCase())
                    let checkGrad = grades.length > 0 ? grades.filter((g: any) => g.trim().toLocaleLowerCase() === user.Grade.trim().toLocaleLowerCase()) : [];
                    let checkRight = rights.length > 0 ? rights.filter((g: any) => g.trim().toLocaleLowerCase() === user.Right.trim().toLocaleLowerCase()) : [];

                    const taxSpecialism: string[] = [];
                    if (user.TaxSpecialism) {
                        const taxSpecialismTable = user.TaxSpecialism.split('/');
                        taxSpecialism.push(...taxSpecialismTable.filter((tax: string) => listTaxSpecialism.includes(tax.trim())))
                    }
                    const areaOfIndustryExpertise: string[] = [];
                    if (user.AreaOfIndustryExpertise) {
                        const areaOfIndustryExpertiseTable = user.AreaOfIndustryExpertise.split('/');
                        for (let area of areaOfIndustryExpertiseTable) {
                            if (listAreaOfIndustryExpertise.includes(area.trim())) {
                                areaOfIndustryExpertise.push(area.trim());
                            }
                        }
                    }
                    //Case Update User
                    if (checkUser) {
                        const updateUser: any = {}
                        // If on Firebase IsVisible And On Web Form Not
                        if (checkUser.isVisible === true && user.IsVisible === "false") {
                            const username = generateValidUsername(user.FirstName ? user.FirstName : checkUser.firstName ? checkUser.firstName : "", user.LastName ? user.LastName : checkUser.lastName ? checkUser.lastName : "");
                            // Update Native Data WordPress For The User Exist Already
                            await callFirebaseFunction('updateUserWordpressNative', 'PUT', {
                                userIdWordpress: checkUser.wordpress_id,
                                token: localStorage.getItem("wordpressToken"),
                                first_name: user.FirstName ? user.FirstName : checkUser.firstName ? checkUser.firstName : "",
                                last_name: user.LastName ? user.LastName : checkUser.lastName ? checkUser.lastName : "",
                                // eslint-disable-next-line
                                name: username,
                                url: checkFirm ? checkFirm.webSite : checkUser.firm ? checkUser.firm.webSite : "",
                                callType: ""
                            }, token);
                            // Update Acf Data WordPress For The User Exist Already
                            await callFirebaseFunction('updateUserWordpressAcf', 'PUT', {
                                userIdWordpress: !!checkUser.wordpress_id ? checkUser.wordpress_id : wordpress_id,
                                token: localStorage.getItem("wordpressToken"),
                                telephone: user.Phone ? user.Phone : checkUser.phone ? checkUser.phone : "",
                                mobile: user.Mobile ? user.Mobile : checkUser.mobile ? checkUser.mobile : "",
                                biography: user.ProfesionnalBiography ? textFormat(user.ProfesionnalBiography) : "",
                                connect_url: user.LinkedInProfileLink ? user.LinkedInProfileLink : checkUser.linkedInProfileLink ? checkUser.linkedInProfileLink : "",
                                assistants_name: user.AssistantName ? user.AssistantName : checkUser.assistantName ? checkUser.assistantName : "",
                                assistants_email: user.AssistantEmail ? user.AssistantEmail : checkUser.assistantEmail ? checkUser.assistantEmail : "",
                                position: checkGrad.length > 0 ? checkGrad[0] : checkUser.grade ? checkUser.grade : "",
                                user_location: countryId,
                                show: user.IsVisible === "true" || (user.IsVisible !== "false" && checkUser.isVisible),
                                area_of_expertise: taxSpecialism.length > 0 ? filterTaxSpecialisme(taxSpecialism) : checkUser.taxSpecialisme ? filterTaxSpecialisme(checkUser.taxSpecialisme) : "",
                                company_logo: checkFirm ? checkFirm.webSite : checkUser.firm ? checkUser.firm.webSite : "",

                            }, token);
                        }
                        // If In The WebForm IsVisible
                        if (user.IsVisible === "true") {
                            // Build Acf Data
                            const userWordpressUpdateACF = {
                                fields: {
                                    telephone: user.Phone ? user.Phone : checkUser.phone ? checkUser.phone : "",
                                    mobile: user.Mobile,
                                    biography: user.ProfesionnalBiography ? textFormat(user.ProfesionnalBiography) : "",
                                    connect_url: user.LinkedInProfileLink ? user.LinkedInProfileLink : checkUser.linkedInProfileLink ? checkUser.linkedInProfileLink : "",
                                    assistants_name: user.AssistantName ? user.AssistantName : checkUser.assistantName ? checkUser.assistantName : "",
                                    assistants_email: user.AssistantEmail ? user.AssistantEmail : checkUser.assistantEmail ? checkUser.assistantEmail : "",
                                    position: checkGrad.length > 0 ? checkGrad[0] : checkUser.grade ? checkUser.grade : "",
                                    user_location: countryId,
                                    show: user.IsVisible === "true" || (user.IsVisible !== "false" && checkUser.isVisible),
                                    area_of_expertise: taxSpecialism.length > 0 ? filterTaxSpecialisme(taxSpecialism) : checkUser.taxSpecialisme ? filterTaxSpecialisme(checkUser.taxSpecialisme) : "",
                                    company_logo: checkFirm ? checkFirm.webSite : checkUser.firm ? checkUser.firm.webSite : "",
                                }
                            };
                            //Build Native Data
                            const userWordpressUpdateNative = {
                                name: user.FirstName ? user.FirstName : checkUser.firstName ? checkUser.firstName : "".replace(/[^a-zA-Z0-9]/g, randomCharacter) + " " + user.LastName ? user.LastName : checkUser.lastName ? checkUser.lastName : "".replace(/[^a-zA-Z0-9]/g, randomCharacter),
                                first_name: user.FirstName ? user.FirstName : checkUser.firstName ? checkUser.firstName : "",
                                last_name: user.LastName ? user.LastName : checkUser.lastName ? checkUser.lastName : "",
                                url: checkFirm ? checkFirm.webSite : checkUser.firm ? checkUser.firm.webSite : "",
                            }
                            // If User Has Already Wordpress Id or User Email Already Exist In Wordpress
                            if (checkUser.wordpress_id || wordpress_id) {
                                // Generate a sanitized username
                                // eslint-disable-next-line
                                const username = generateValidUsername(user.FirstName ? user.FirstName : checkUser.firstName ? checkUser.firstName : "", user.LastName ? user.LastName : checkUser.lastName ? checkUser.lastName : "");
                                // Update Native Data Wordpress For The User Exist Already
                                await callFirebaseFunction('updateUserWordpressNative', 'PUT', {
                                    userIdWordpress: checkUser.wordpress_id,
                                    token: localStorage.getItem("wordpressToken"),
                                    first_name: user.FirstName ? user.FirstName : checkUser.firstName ? checkUser.firstName : "",
                                    last_name: user.LastName ? user.LastName : checkUser.lastName ? checkUser.lastName : "",
                                    // eslint-disable-next-line
                                    name: username,
                                    url: checkFirm ? checkFirm.webSite : checkUser.firm ? checkUser.firm.webSite : "",
                                    callType: ""

                                }, token);
                            }
                            // Id User Has Not Wordpress Id
                            if (!checkUser.wordpress_id && !wordpress_id) {
                                const username = generateValidUsername(user.FirstName ? user.FirstName : checkUser.firstName ? checkUser.firstName : "", user.LastName ? user.LastName : checkUser.lastName ? checkUser.lastName : "");
                                const res = await callFirebaseFunction('createUserWordPressNativeFunction', 'POST', {
                                    token: localStorage.getItem("wordpressToken"),
                                    name: username,
                                    username: username,
                                    first_name: userWordpressUpdateNative.first_name,
                                    last_name: userWordpressUpdateNative.last_name,
                                    email: userEmail,
                                    password: "i5Z%sTF6tdu&#*h#ydq$J%PM",
                                    url: userWordpressUpdateNative.url,
                                    description: "",
                                    callType: ""
                                }, token);
                                if (res.data && res.data.userId) {
                                    // eslint-disable-next-line
                                    wordpress_id = res.data.userId;
                                } else {
                                    notification["error"]({
                                        message: "User created but no ID returned",
                                        description: "User created but no ID returned",
                                    });
                                }
                            }

                            // For The Two last Condition If He Has Wordpress Or Not We Update ACF Data
                            await callFirebaseFunction('updateUserWordpressAcf', 'PUT', {
                                userIdWordpress: !!checkUser.wordpress_id ? checkUser.wordpress_id : wordpress_id,
                                token: localStorage.getItem("wordpressToken"),
                                telephone: userWordpressUpdateACF.fields.telephone,
                                mobile: userWordpressUpdateACF.fields.mobile,
                                biography: userWordpressUpdateACF.fields.biography,
                                connect_url: userWordpressUpdateACF.fields.connect_url,
                                assistants_name: userWordpressUpdateACF.fields.assistants_name,
                                assistants_email: userWordpressUpdateACF.fields.assistants_email,
                                position: userWordpressUpdateACF.fields.position,
                                user_location: userWordpressUpdateACF.fields.user_location,
                                show: userWordpressUpdateACF.fields.show,
                                area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                                company_logo: userWordpressUpdateACF.fields.company_logo,
                                callType: ""

                            }, token);
                        }
                        if (user.FirstName)
                            updateUser["firstName"] = user.FirstName ? user.FirstName : checkUser.firstName ? checkUser.firstName : ""
                        if (user.LastName)
                            updateUser["lastName"] = user.LastName ? user.LastName : checkUser.lastName ? checkUser.lastName : ""
                        if (user.Country)
                            updateUser["country"] = user.Country ? user.Country : checkUser.country ? checkUser.country : ""
                        if (user.Firm)
                            updateUser["firm"] = checkFirm ? checkFirm : ""
                        if (user.Grade)
                            updateUser["grade"] = checkGrad.length > 0 ? checkGrad[0] : checkUser.grade ? checkUser.grade : ""
                        if (user.Right)
                            updateUser["right"] = checkRight.length > 0 ? checkRight[0] : checkUser.right ? checkUser.right : ""
                        if (user.AssistantName)
                            updateUser["assistantName"] = user.AssistantName ? user.AssistantName : checkUser.assistantName ? checkUser.assistantName : ""
                        if (user.AssistantEmai)
                            updateUser["assistantEmail"] = user.AssistantEmail ? user.AssistantEmail : checkUser.assistantEmail ? checkUser.assistantEmail : ""
                        if (user.AssistantTelephone)
                            updateUser["assistantTelephone"] = user.AssistantTelephone ? user.AssistantTelephone : checkUser.assistantTelephone ? checkUser.assistantTelephone : ""
                        if (user.AssistantMobile)
                            updateUser["assistantMobile"] = user.AssistantMobile ? user.AssistantMobile : checkUser.assistantMobile ? checkUser.assistantMobile : ""
                        if (user.TaxSpecialism)
                            updateUser["taxSpecialisme"] = taxSpecialism.length > 0 ? taxSpecialism : checkUser.taxSpecialisme ? checkUser.taxSpecialisme : ""
                        if (user.AreaOfIndustryExpertise)
                            updateUser["areaOfIndustryExpertise"] = areaOfIndustryExpertise.length > 0 ? areaOfIndustryExpertise : checkUser.areaOfIndustryExpertise ? checkUser.areaOfIndustryExpertise : ""
                        if (user.IsVisible)
                            updateUser["isVisible"] = user.IsVisible === "true" || (user.IsVisible !== "false" && checkUser.isVisible);
                        if (user.PersonnalBiography)
                            updateUser["personnalBiography"] = user.PersonnalBiography ? user.PersonnalBiography : checkUser.personnalBiography ? checkUser.personnalBiography : ""
                        if (user.ProfesionnalBiography)
                            updateUser["profesionnalBiography"] = user.ProfesionnalBiography ? user.ProfesionnalBiography : checkUser.profesionnalBiography ? checkUser.profesionnalBiography : ""
                        if (user.Phone)
                            updateUser["phone"] = user.Phone ? user.Phone : checkUser.phone ? checkUser.phone : ""
                        if (user.Mobile)
                            updateUser["mobile"] = user.Mobile ? user.Mobile : checkUser.mobile ? checkUser.mobile : ""
                        if (user.LinkedInProfileLink)
                            updateUser["linkedInProfileLink"] = user.LinkedInProfileLink ? user.LinkedInProfileLink : checkUser.linkedInProfileLink ? checkUser.linkedInProfileLink : ""
                        if (user.TwitterProfileLink)
                            updateUser["twitterProfileLink"] = user.TwitterProfileLink ? user.TwitterProfileLink : checkUser.twitterProfileLink ? checkUser.twitterProfileLink : ""

                        updateUser["wordpress_id"] = !!checkUser.wordpress_id ? checkUser.wordpress_id : wordpress_id;
                        await API.updateUser(updateUser, checkUser.id)
                        // Add the updated user's details to the updatedUsers array
                        updatedUsers.push({
                            email: checkUser.email,
                            firstName: updateUser.firstName,
                            lastName: updateUser.lastName
                        });
                    }

                    //Case Add User
                    else {
                        const password = generatePasswordImport();
                        try {
                            if (user.IsVisible === "true") {
                                // Creating user data for WordPress
                                let userWordpressUpdateNative = {
                                    name: user.FirstName.replace(/[^a-zA-Z0-9]/g, randomCharacter) + " " + user.LastName.replace(/[^a-zA-Z0-9]/g, randomCharacter),
                                    first_name: user.FirstName,
                                    last_name: user.LastName,
                                    url: checkFirm ? checkFirm.webSite : "",

                                };

                                let userWordpressUpdateACF = {
                                    fields: {
                                        display_email: user.Email,
                                        telephone: user.Phone,
                                        mobile: user.Mobile,
                                        biography: user.ProfesionnalBiography ? textFormat(user.ProfesionnalBiography) : "",
                                        connect_url: user.LinkedInProfileLink,
                                        assistants_name: user.AssistantName,
                                        assistants_email: user.AssistantEmail,
                                        position: checkGrad.length > 0 ? checkGrad[0] : "",
                                        user_location: countryId,
                                        show: user.IsVisible,
                                        area_of_expertise: taxSpecialism.length > 0 ? filterTaxSpecialisme(taxSpecialism) : "",
                                        company_logo: checkFirm ? checkFirm.logo_id : "",
                                    }
                                };
                                if (wordpress_id) {
                                    const username = generateValidUsername(userWordpressUpdateNative.first_name, userWordpressUpdateNative.last_name);

                                    // Update WordPress data for existing user
                                    await callFirebaseFunction('updateUserWordpressNative', 'PUT', {
                                        userIdWordpress: wordpress_id,
                                        token: localStorage.getItem("wordpressToken"),
                                        first_name: userWordpressUpdateNative.first_name,
                                        last_name: userWordpressUpdateNative.last_name,
                                        name: username,
                                        url: userWordpressUpdateNative.url,
                                        callType: ""
                                    }, token);

                                    // Update Acf Data WordPress For The User Exist Already
                                    await callFirebaseFunction('updateUserWordpressAcf', 'PUT', {
                                        userIdWordpress: wordpress_id,
                                        token: localStorage.getItem("wordpressToken"),
                                        telephone: userWordpressUpdateACF.fields.telephone,
                                        mobile: "",
                                        image: "",
                                        biography: userWordpressUpdateACF.fields.biography,
                                        connect_url: "",
                                        assistants_name: userWordpressUpdateACF.fields.assistants_name,
                                        assistants_email: userWordpressUpdateACF.fields.assistants_email,
                                        position: userWordpressUpdateACF.fields.position,
                                        user_location: userWordpressUpdateACF.fields.user_location,
                                        show: userWordpressUpdateACF.fields.show,
                                        area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                                        company_logo: userWordpressUpdateACF.fields.company_logo,
                                        callType: "coldStart"
                                    }, token);
                                }
                                else {
                                    // Create a new WordPress user
                                    const username = generateValidUsername(user.FirstName, user.LastName);
                                    const res = await callFirebaseFunction('createUserWordPressNativeFunction', 'POST', {
                                        token: localStorage.getItem("wordpressToken"),
                                        name: username,
                                        username: username,
                                        first_name: userWordpressUpdateNative.first_name,
                                        last_name: userWordpressUpdateNative.last_name,
                                        email: userEmail,
                                        password: password,
                                        url: userWordpressUpdateNative.url,
                                        description: "",
                                        callType: ""
                                    }, token);
                                    wordpress_id = res.data && res.data.userId ? res.data.userId : null;

                                    // To Insert ACF data Should Use Update Because Creation Support Just Native Data
                                    await callFirebaseFunction('updateUserWordpressAcf', 'PUT', {
                                        userIdWordpress: wordpress_id,
                                        token: localStorage.getItem("wordpressToken"),
                                        telephone: userWordpressUpdateACF.fields.telephone,
                                        mobile: userWordpressUpdateACF.fields.mobile,
                                        biography: userWordpressUpdateACF.fields.biography,
                                        connect_url: userWordpressUpdateACF.fields.connect_url,
                                        assistants_name: userWordpressUpdateACF.fields.assistants_name,
                                        assistants_email: userWordpressUpdateACF.fields.assistants_email,
                                        position: userWordpressUpdateACF.fields.position,
                                        user_location: userWordpressUpdateACF.fields.user_location,
                                        show: userWordpressUpdateACF.fields.show,
                                        area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                                        company_logo: userWordpressUpdateACF.fields.company_logo,
                                        callType: ""
                                    }, token);
                                }
                            }

                            const newUserData = {
                                email: (user.Email || "").trim(),
                                firstName: user.FirstName,
                                lastName: user.LastName,
                                phone: user.Phone ? user.Phone : "",
                                mobile: user.Mobile ? user.Mobile : "",
                                linkedInProfileLink: user.LinkedInProfileLink ? user.LinkedInProfileLink : "",
                                twitterProfileLink: user.TwitterProfileLink ? user.TwitterProfileLink : "",
                                assistantName: user.AssistantName ? user.AssistantName : "",
                                assistantEmail: user.AssistantEmail ? user.AssistantEmail : "",
                                assistantTelephone: user.AssistantTelephone ? user.AssistantTelephone : "",
                                assistantMobile: user.AssistantMobile ? user.AssistantMobile : "",
                                firm: checkFirm ? {
                                    id: checkFirm.id,
                                    firm: checkFirm.firm,
                                    webSite: checkFirm.webSite,
                                    country: checkFirm.country,
                                    logo_id: checkFirm.logo_id,
                                    deleted: checkFirm.deleted,
                                    firmLogoUrl: checkFirm.firmLogoUrl
                                } : undefined,
                                grade: checkGrad.length > 0 ? checkGrad[0] : "",
                                country: user.Country ? user.Country : "",
                                areaOfIndustryExpertise: areaOfIndustryExpertise.length > 0 ? areaOfIndustryExpertise : [],
                                taxSpecialisme: taxSpecialism.length > 0 ? taxSpecialism : [],
                                personnalBiography: user.PersonnalBiography ? user.PersonnalBiography : "",
                                profesionnalBiography: user.ProfesionnalBiography ? user.ProfesionnalBiography : "",
                                right: checkRight.length > 0 ? checkRight[0] : "",
                                isVisible: user.IsVisible ? user.IsVisible === "true" : false,
                                deleted: false,
                                approvalStatus: "Default",
                                showLandingModal: true,
                                wordpress_id: wordpress_id ? wordpress_id : "",
                            };

                            const response = await callFirebaseFunction('createUser', 'POST', newUserData, token);
                            const userId = response.data.user_res;

                            // Set the new user data to Firebase Realtime Database
                            const newUserDataRealtime = {
                                id: userId,
                                ...newUserData,
                            };
                            await firebase.database().ref(`users/${userId}`).set(newUserDataRealtime);

                            // Collect the generated user data for further processing
                            let userGenerated = [newUserDataRealtime.firstName, newUserDataRealtime.email, password];
                            dataGlobal.push(userGenerated);

                            const userData = {
                                email: newUserDataRealtime.email,
                                password: password,
                                firstName: newUserDataRealtime.firstName,
                            };

                            /* send email credentials after create user */
                            await sendEmailCredentials('sendEmailDetailUser', token, userData);
                        } catch (creationError: any) {
                            errors.push({ user: userEmail, errorMessage: creationError.message });
                        }
                    }
                    setAddPercent(progress);
                }
                catch (processingError: any) {
                    errors.push({ user: userEmail, errorMessage: processingError.message });
                }
            }
        }
        if (dataGlobal.length > 0 || invalidEmails.length > 0 || updatedUsers.length > 0
            || invalidDataUsers.length > 0 || duplicateEmails.length > 0) {
            exportXlsxUsers(dataGlobal, invalidEmails, updatedUsers, invalidDataUsers, duplicateEmails);
        }
        // Log any errors to the console
        if (errors.length > 0) {
            console.error("Errors when adding users:", errors);
        }
        await loadData();
        setTimeout(() => setAddPercent(100), 0);
    };

    // Function to export new user,updated user, invalid user email or invalid data user  to an Excel file
    const exportXlsxUsers = useCallback((validUsers: any[], invalidEmails: any[], updatedUsers: any[],
                                         invalidDataUsers: any[], duplicateEmails: any[]) => {
        // Create a sheet for users added
        const validUsersColumn = ["Name", "Email", "Password"];
        validUsers.sort((a: any, b: any) => a[1] + "" + a[0] > b[1] + "" + b[0] ? 1 : -1);
        validUsers.splice(0, 0, validUsersColumn);

        // Create a sheet for invalid emails
        const invalidEmailsColumn = [" Email"];
        const invalidEmailsData = invalidEmails.map(email => [email]);
        invalidEmailsData.splice(0, 0, invalidEmailsColumn);

        // Create a sheet for updated users
        const updatedUsersColumn = ["Email", "First Name", "Last Name"];
        const updatedUsersData = updatedUsers.map(user => [user.email, user.firstName, user.lastName]);
        updatedUsersData.splice(0, 0, updatedUsersColumn);

        // Create a sheet for invalid data required
        const invalidDataColumn = ["Email", "First Name", "Last Name", "Right", "Country", "Firm"];
        const invalidDataSheet = invalidDataUsers.map(user => [user.email, user.firstName, user.lastName, user.right, user.country, user.firm]);
        invalidDataSheet.splice(0, 0, invalidDataColumn);

        // Create a sheet for duplicate emails
        const duplicateEmailsColumn = [" Email"];
        const duplicateEmailsData = duplicateEmails.map(email => [email]);
        duplicateEmailsData.splice(0, 0, duplicateEmailsColumn);

        const timestamp = new Date().toISOString().replace('T', ' ').substr(0, 10);
        const wsValidUsers = XLSX.utils.aoa_to_sheet(validUsers);
        const wsInvalidEmails = XLSX.utils.aoa_to_sheet(invalidEmailsData);
        const wsUpdatedUsers = XLSX.utils.aoa_to_sheet(updatedUsersData);
        const wsInvalidData = XLSX.utils.aoa_to_sheet(invalidDataSheet);
        const wsDuplicateEmails = XLSX.utils.aoa_to_sheet(duplicateEmailsData);


        const Header = ['A1', 'B1', 'C1'];
        for (let h in Header) {
            wsValidUsers[Header[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }

        const invalidEmailHeader = ['A1'];
        for (let h in invalidEmailHeader) {
            wsInvalidEmails[invalidEmailHeader[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }


        const HeaderUpdatedUsers = ['A1', 'B1', 'C1'];
        for (let h in HeaderUpdatedUsers) {
            wsUpdatedUsers[HeaderUpdatedUsers[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }

        const HeaderInvalidData = ['A1', 'B1', 'C1', 'D1'];
        for (let h in HeaderInvalidData) {
            wsInvalidData[HeaderInvalidData[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }

        const HeaderDuplicateEmails = ['A1'];
        for (let h in HeaderDuplicateEmails) {
            wsDuplicateEmails[HeaderDuplicateEmails[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }

        const wb = {
            Sheets: {
                'Added Users': wsValidUsers,
                'Updated Users': wsUpdatedUsers,
                'Invalid Users': wsInvalidData,
                'Invalid Emails': wsInvalidEmails,
                'Duplicated Emails': wsDuplicateEmails
            },
            SheetNames: ['Added Users', 'Updated Users', 'Invalid Users', 'Invalid Emails', 'Duplicated Emails']
        };
        // Export du excel
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array', cellStyles: true});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "taxand_hub_import_users_" + timestamp + fileExtension);

    }, []);

    // Delete a user from Firebase and WordPress
    const usersDelete = (data: any) => {
        let users = data.slice(0, -1);
        const oldUsers = dataStore.users;
        const invalidEmails: any[] = [];
        const notFoundEmails: any[] = [];
        const usersKeys = Object.keys(users);
        const totalUsers = usersKeys.length;
        for (let i = 0; i < usersKeys.length; i++) {
            // Calculate the percentage and round it to the nearest integer
            const progress = Math.round(((i + 1) / totalUsers) * 100);
            setDeletePercent(progress);
            const userKey = usersKeys[i];
            const user = users[userKey];
            if (user.Email) {
                const userEmail = user.Email.trim().toLocaleLowerCase();
                // eslint-disable-next-line
                const isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userEmail);
                if (!isValidEmail) {
                    invalidEmails.push(userEmail);
                    continue; // Skip this iteration and move to the next user
                }
                let checkUsers = oldUsers.find(u => u.email.trim().toLocaleLowerCase() === userEmail);
                if (checkUsers) {
                    if (checkUsers.deleted === false)
                        deleteUser(checkUsers.id);
                } else {
                    notFoundEmails.push(userEmail);
                    continue;
                }
                setDeletePercent(progress);
            }
        }
        if (invalidEmails.length > 0 || notFoundEmails.length > 0) {
            exportInvalidEmailsCaseDelete(invalidEmails, notFoundEmails);
            notification["info"]({
                message: "Invalid or deleted e-mails",
                description: "Invalid e-mails or e-mails already deleted have been exported to an Excel file.",
            });
        }
        if (users.length > invalidEmails.length) {
            notification["success"]({
                message: "Success",
                description: "User(s) deleted successfully",
            });
        }
        setTimeout(() => setDeletePercent(100), 0);
    };

    const usersSendEmailCredentials = async (data: any) => {
        const notFoundEmails: any[] = [];
        const duplicateEmails: any[] = [];
        let users = data.slice(0, -1);
        const oldUsers = dataStore.users;
        const usersToEmail = [];
        const usersKeys = Object.keys(users);
        const totalUsers = usersKeys.length;
        // Create a map to track unique email entries with name and password
        let emailMap = new Map();
        for (let i = 0; i < usersKeys.length; i++){
            const progress = Math.round(((i + 1) / totalUsers) * 100);
            setSendPercent(progress);
            const userKey = usersKeys[i];
            const user = users[userKey];
            if (user && user.Email && user.Name && user.Password) {
                const userEmail = user.Email.trim().toLocaleLowerCase();
                const userName = user.Name.trim();
                const userPassword = user.Password.trim();
                if (userEmail && userName && userPassword) {
                    if (!emailMap.has(userEmail)) {
                        // If the email is not in the map, add it with the current name and password
                        emailMap.set(userEmail, {name: userName, password: userPassword});
                    }
                    else {
                        // If the email exists, compare the name and password
                        let existingEntry = emailMap.get(userEmail);
                        if (existingEntry.name !== userName || existingEntry.password !== userPassword) {
                            // If either name or password is different, consider it a duplicate
                            if (!duplicateEmails.includes(userEmail)) {
                                duplicateEmails.push(userEmail);
                            }
                        }
                    }
                    let checkUser = oldUsers.find(u => u.email && u.email.trim().toLocaleLowerCase() === user.Email.trim().toLocaleLowerCase())
                    if (!checkUser) {
                        notFoundEmails.push(userEmail);
                    } else {
                        usersToEmail.push({
                            email: userEmail,
                            password: user.Password.trim(),
                            name: user.Name.trim()
                        });
                    }
                }
                setSendPercent(progress);
            }
        }
        if (notFoundEmails.length > 0 || duplicateEmails.length > 0) {
            exportNotFoundOrDuplicateEmailsCaseSendEmail(notFoundEmails, duplicateEmails);
            notification.info({
                message: "Not Found Emails or Duplicate Emails",
                description: "Not Found or Duplicate emails have been exported to an Excel file.",
            });
        }
        if (usersToEmail.length > 0) {
            try {
                const responseSendEmailUsers = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendEmailUserlistDetails`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(usersToEmail)
                });
                // Parse and log JSON response only if the response is OK
                if (responseSendEmailUsers.ok) {
                    // Show success notification only once here
                    notification.success({
                        message: "Success",
                        description: "All emails sent successfully",
                    });
                    setTimeout(() => setSendPercent(100), 0);
                } else {
                    // If the response is not OK, handle it as an error
                    const errorResponse = await responseSendEmailUsers.text(); // Use .text() to get the response text regardless of content type
                    throw new Error(`Server response error: ${errorResponse}`);
                }
            } catch (error: any) {
                console.error('Error sending email credentials:', error);
                notification.error({
                    message: "Error",
                    description: `Failed to send emails. ${error.message}`,
                });
            }
        }

    };
    const usersUpdatePassword = async (data: any) => {
        const notFoundEmails: any[] = [];
        const notCorrectPass: any[] = [];
        const duplicateEmails: any[] = [];
        let users = data.slice(0, -1);
        const oldUsers = dataStore.users;
        const usersToEmail = [];
        const usersKeys = Object.keys(users);
        const totalUsers = usersKeys.length;
        // Create a map to track unique email entries with password
        let emailMap = new Map();
        for (let i = 0; i < usersKeys.length; i++){
            // Calculate the percentage and round it to the nearest integer
            const progress = Math.round(((i + 1) / totalUsers) * 100);
            setUpdatePercent(progress);
            const userKey = usersKeys[i];
            const user = users[userKey];
            if (user.Email) {
                const userEmail = user.Email.trim().toLocaleLowerCase();
                const userPassword = user.Password.trim();
                const userByEmail = await API.getUserByEmail(userEmail);

                //todo password check
                if (userEmail && userPassword) {
                    if (!emailMap.has(userEmail)) {
                        // If the email is not in the map, add it with the current name and password
                        emailMap.set(userEmail, {password: userPassword});
                    } else {
                        // If the email exists, compare the name and password
                        let existingEntry = emailMap.get(userEmail);
                        if (existingEntry.password !== userPassword) {
                            // If either name or password is different, consider it a duplicate
                            if (!duplicateEmails.includes(userEmail)) {
                                duplicateEmails.push(userEmail);
                            }
                        }
                    }

                    // Ensure that oldUsers is an array and user.Email is a string before proceeding
                    const checkUser = oldUsers.find(u =>
                        typeof u.email === 'string' &&
                        typeof user.Email === 'string' &&
                        u.email.trim().toLocaleLowerCase() === user.Email.trim().toLocaleLowerCase()
                    );

                    if (!checkUser) {
                        notFoundEmails.push(userEmail);
                    } else {
                        if(
                            user.Password.trim().length >= 8 &&
                            user.Password.trim().length <= 20 &&
                            /[A-Z]/.test(user.Password.trim()) &&
                            /[a-z]/.test(user.Password.trim()) &&
                            /[0-9]/.test(user.Password.trim()) &&
                            /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(user.Password.trim())
                        ){
                            usersToEmail.push({
                                email: userEmail,
                                newPassword: user.Password.trim(),
                            });

                            /* send email credentials after update user */
                            try {
                                const responseSendEmail = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendEmailDetailUser`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        user: userEmail,
                                        pass: user.Password,
                                        receiver: userByEmail.firstName,
                                    })
                                });

                                // Parse and log JSON response only if the response is OK
                                if (responseSendEmail.ok) {
                                    // Show success notification only once here
                                    notification.success({
                                        message: "Success",
                                        description: "Email was sent successfully",
                                    });
                                } else {
                                    // If the response is not OK, handle it as an error
                                    const errorResponse = await responseSendEmail.text(); // Use .text() to get the response text regardless of content type
                                    throw new Error(`Server response error: ${errorResponse}`);
                                }
                            }
                            catch (error: any) {
                                console.error('Error sending email credentials:', error);
                                notification.error({
                                    message: "Error",
                                    description: `Failed to send emails. ${error.message}`,
                                });
                            }

                        } else {
                            notCorrectPass.push(userEmail);
                        }
                    }
                }
                setUpdatePercent(progress);
            }
        }
        if (notFoundEmails.length > 0 || duplicateEmails.length > 0 || notCorrectPass.length > 0) {
            exportNotFoundOrDuplicateEmailsOrNotCorrectPassCaseUpdatePassword(notFoundEmails, notCorrectPass, duplicateEmails);
            notification.info({
                message: "Not Found Emails or Duplicate Emails",
                description: "Not Found or Duplicate emails have been exported to an Excel file.",
            });
        }
        if (usersToEmail.length > 0) {
            try {
                const responseUpdatePasswordUsers = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updatePasswordListUsersFunction`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(usersToEmail)
                });
                // Parse and log JSON response only if the response is OK
                if (responseUpdatePasswordUsers.ok) {
                    // Show success notification only once here
                    notification.success({
                        message: "Success",
                        description: "All passwords for these e-mails have been successfully changed",
                    });
                    setTimeout(() => setUpdatePercent(100), 0);
                } else {
                    // If the response is not OK, handle it as an error
                    const errorResponse = await responseUpdatePasswordUsers.text();
                    throw new Error(`Server response error: ${errorResponse}`);
                }
            } catch (error: any) {
                notification.error({
                    message: "Error",
                    description: `Password modification failed. ${error.message}`,
                });
            }
        }
    }

    const deleteUser = useCallback(
        async (userId: string) => {
            const userInfo = await API.getUserInfo(userId);
            try {

                // Delete user from Firebase
                const res = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUser`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        uid: userId,
                        callType: "",
                    }),
                });
                if (userInfo.wordpress_id) {
                    await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUserWordpress`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            userIdWordpress: userInfo.wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            callType: "",
                        }),
                    });
                }

                if (res) {
                    API.getAllUserInfo().then(res => {
                        dataStore.updateUsers(res);
                        API.getDeletedUserInfo().then(delRes => {
                            dataStore.updateDeletedUsers(delRes);
                        })
                    })

                    const imageRes = await firebase.storage().ref("userAvatar/" + userId + ".jpg");
                    if (userInfo.avatarUrl) {
                        imageRes.delete().then();
                    }
                    loadData().then();
                }
            } catch (error) {
                notification["error"]({
                    message: t("Failed to delete user"),
                    description: "An error occurred while deleting the user , check the logs",
                });
            }
        },
        [t, loadData, token]
    );
    const exportInvalidEmailsCaseDelete = useCallback((invalidEmails: any[], notFoundEmails: any[]) => {
        // Define the column header for invalid emails
        const invalidEmailsColumn = [" Email"];
        // Create data for the worksheet
        const invalidEmailsData = invalidEmails.map(email => [email]);
        invalidEmailsData.splice(0, 0, invalidEmailsColumn);

        // Define the column header for Not Found emails
        const notFoundEmailsColumn = [" Email"];
        // Create data for the worksheet
        const notFoundEmailsData = notFoundEmails.map(email => [email]);
        notFoundEmailsData.splice(0, 0, notFoundEmailsColumn);

        const timestamp = new Date().toISOString().replace('T', ' ').substr(0, 10);
        const wsInvalidEmails = XLSX.utils.aoa_to_sheet(invalidEmailsData);
        const wslNotFoundEmail = XLSX.utils.aoa_to_sheet(notFoundEmailsData);

        const invalidEmailHeader = ['A1'];
        for (let h in invalidEmailHeader) {
            wsInvalidEmails[invalidEmailHeader[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }
        const notFoundEmailHeader = ['A1'];
        for (let h in notFoundEmailHeader) {
            wslNotFoundEmail[notFoundEmailHeader[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }
        const wb = {
            Sheets: {
                'Invalid Emails': wsInvalidEmails,
                'Not Found Emails': wslNotFoundEmail
            },
            SheetNames: ['Invalid Emails', 'Not Found Emails']
        };
        // Export du excel
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array', cellStyles: true});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "taxand_hub_delete_users_" + timestamp + fileExtension);
    }, []);

    const exportNotFoundOrDuplicateEmailsCaseSendEmail = useCallback((notFoundEmails: any[], duplicateEmails: any[]) => {
        // Create a sheet for not Found emails
        const notFoundEmailsColumn = [" Email"];
        const notFoundEmailsData = notFoundEmails.map(email => [email]);
        notFoundEmailsData.splice(0, 0, notFoundEmailsColumn);

        // Create a sheet for duplicate emails
        const duplicateEmailsColumn = [" Email"];
        const duplicateEmailsData = duplicateEmails.map(email => [email]);
        duplicateEmailsData.splice(0, 0, duplicateEmailsColumn);

        const timestamp = new Date().toISOString().replace('T', ' ').substr(0, 10);
        const wsNotFoundEmails = XLSX.utils.aoa_to_sheet(notFoundEmailsData);
        const wsDuplicateEmails = XLSX.utils.aoa_to_sheet(duplicateEmailsData);

        const notFoundEmailHeader = ['A1'];
        for (let h in notFoundEmailHeader) {
            wsNotFoundEmails[notFoundEmailHeader[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }
        const HeaderDuplicateEmails = ['A1'];
        for (let h in HeaderDuplicateEmails) {
            wsDuplicateEmails[HeaderDuplicateEmails[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }
        const wb = {
            Sheets: {
                'Not Found Emails': wsNotFoundEmails,
                'Duplicated Emails': wsDuplicateEmails
            },
            SheetNames: ['Not Found Emails', 'Duplicated Emails']
        };
        // Export du excel
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array', cellStyles: true});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "taxand_hub_send_email_credentials_" + timestamp + fileExtension);
    }, []);

    const exportNotFoundOrDuplicateEmailsOrNotCorrectPassCaseUpdatePassword = useCallback((notFoundEmails: any[], notCorrectPass: any[], duplicateEmails: any[]) => {
        // Create a sheet for not Found emails
        const notFoundEmailsColumn = [" Email"];
        const notFoundEmailsData = notFoundEmails.map(email => [email]);
        notFoundEmailsData.splice(0, 0, notFoundEmailsColumn);

        // Create a sheet for duplicate emails
        const duplicateEmailsColumn = [" Email"];
        const duplicateEmailsData = duplicateEmails.map(email => [email]);
        duplicateEmailsData.splice(0, 0, duplicateEmailsColumn);

        // Create a sheet for not Correct password
        const notCorrectPassColumn = [" Email"];
        const notCorrectPassData = notCorrectPass.map(email => [email]);
        notCorrectPassData.splice(0, 0, notCorrectPassColumn);

        const timestamp = new Date().toISOString().replace('T', ' ').substr(0, 10);
        const wsNotFoundEmails = XLSX.utils.aoa_to_sheet(notFoundEmailsData);
        const wsDuplicateEmails = XLSX.utils.aoa_to_sheet(duplicateEmailsData);
        const wsNotCorrectPass = XLSX.utils.aoa_to_sheet(notCorrectPassData);

        const notFoundEmailHeader = ['A1'];
        for (let h in notFoundEmailHeader) {
            wsNotFoundEmails[notFoundEmailHeader[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }

        const HeaderDuplicateEmails = ['A1'];
        for (let h in HeaderDuplicateEmails) {
            wsDuplicateEmails[HeaderDuplicateEmails[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }

        const HeaderNotCorrectPass = ['A1'];
        for (let h in HeaderNotCorrectPass) {
            wsNotCorrectPass[HeaderNotCorrectPass[h]].s = {
                font: {
                    sz: 12,
                    color: {rgb: "#FF000000"},
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        }

        const wb = {
            Sheets: {
                'Not Found Emails': wsNotFoundEmails,
                'Duplicated Emails': wsDuplicateEmails,
                'Not Correct Password': wsNotCorrectPass,
            },
            SheetNames: ['Not Found Emails', 'Duplicated Emails', 'Not Correct Password']
        };
        // Export du excel
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array', cellStyles: true});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "taxand_hub_update_password_" + timestamp + fileExtension);
    }, []);

    // Function to clear the file input after adding or deleting users
    const resetFileInput = (inputRef: React.RefObject<HTMLInputElement>) => {
        if (inputRef.current) {
            inputRef.current.value = ""; // Clear the file input value
        }
    };

    const submitAdd = () => {
        setIsLoadingAdd(true); // Start loading

        // Check if the user has the required right before proceeding
        if (!hasGlobalAdminRight) {
            notification.error({ message: t("You do not have the right to perform this action") });
            setIsLoadingAdd(false);
            return;
        }

        const file = csvFile; // The file selected by the user
        if (!file) {
            notification.error({ message: t('No file selected') });
            setIsLoadingAdd(false);
            return;
        }

        const reader = new FileReader();
        reader.onload = async function (e) {
            const bufferArray = e.target?.result;
            const workbook = XLSX.read(bufferArray, {type: 'buffer'}); // Read the Excel file
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];


            // Convert to CSV format
            const csvOutput = XLSX.utils.sheet_to_csv(worksheet, {
                FS: ',',   // Field separator, default is ','
                RS: '\r\n', // Row separator, default is '\n'
                strip: false, // Keep or strip leading/trailing white spaces
                blankrows: false, // Include or exclude blank rows
            });


            let data = processCSV(csvOutput); // Use the existing CSV processing function

            try {
                await addNewUsers(data); // Add new users with the processed data
                notification.success({message: t('Users added successfully')});
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                    setAddFileName('Not selected file');
                    setCsvFile(null); // Clear the file input after successful operation
                }
            } catch (error) {
                console.error(error);
                notification.error({message: t('Failed to add users')});
            } finally {
                setIsLoadingAdd(false); // Stop loading regardless of the outcome
                setAddPercent(0);
            }
        };
        reader.readAsArrayBuffer(file); // Read as buffer for XLSX processing
        resetFileInput(fileInputRef);
    };

    const submitDelete = () => {
        if (!hasGlobalAdminRight) {
            notification.error({message: t("You do not have the right to perform this action")});
            return;
        }
        const file = csvFileToDelete; // The file selected by the user for deletion
        if (!file) {
            notification.error({message: t('No file selected')});
            return;
        }
        const reader = new FileReader();

        reader.onload = async function (e) {
            const bufferArray = e.target?.result;
            const workbook = XLSX.read(bufferArray, {type: 'buffer'}); // Read the Excel file
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const csvOutput = XLSX.utils.sheet_to_csv(worksheet); // Convert to CSV format
            let data = processCSV(csvOutput); // Use the existing CSV processing function
            setIsLoadingDelete(true);
            try {
                await usersDelete(data); // Delete users with the processed data
            } catch (error) {
                notification.error({message: t('Failed to delete users')});
            } finally {
                if (fileDeleteInputRef.current) {
                    fileDeleteInputRef.current.value = "";
                    setDeleteFileName('Not selected file');
                    setCsvFileToDelete(null);
                }
                setIsLoadingDelete(false); // Stop loading regardless of the outcome
                setDeletePercent(0);
            }
        };

        reader.readAsArrayBuffer(file); // Read as buffer for XLSX processing
    };

    const submitUpdatePasswordForListUsers = () => {
        if (!hasGlobalAdminRight) {
            notification.error({message: t("You do not have the right to perform this action")});
            return;
        }
        const file = csvFileToUpdatePassword; // The file selected by the user
        if (!file) {
            notification.error({message: t('No file selected')});
            return;
        }
        const reader = new FileReader();

        reader.onload = async function (e) {
            const bufferArray = e.target?.result;
            const workbook = XLSX.read(bufferArray, {type: 'buffer'}); // Read the Excel file
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const csvOutput = XLSX.utils.sheet_to_csv(worksheet); // Convert to CSV format
            let data = processCSV(csvOutput); // Use the existing CSV processing function
            setIsLoadingUpdatePassword(true);
            try {
                await usersUpdatePassword(data); // Attempt to send Email credentials
                if (fileUpdatePasswordInputRef.current) {
                    fileUpdatePasswordInputRef.current.value = "";
                    setUpdatePasswordFileName('Not selected file');
                    setCsvFileToUpdatePassword(null);
                }
            } catch (error) {
                notification.error({message: t('Failed to update password')});
            } finally {
                setCsvFileToUpdatePassword(null); // Clear the file input after operation
                resetFileInput(fileUpdatePasswordInputRef); // Reset file input
                setIsLoadingUpdatePassword(false);
                setUpdatePercent(0);
            }
        };
        reader.readAsArrayBuffer(file); // Read as buffer for XLSX processing
    }

    const submitSendEmailCredential = () => {
        if (!hasGlobalAdminRight) {
            notification.error({message: t("You do not have the right to perform this action")});
            return;
        }
        const file = csvFileToSendEmail; // The file selected by the user
        if (!file) {
            notification.error({message: t('No file selected')});
            return;
        }
        const reader = new FileReader();
        reader.onload = async function (e) {
            const bufferArray = e.target?.result;
            const workbook = XLSX.read(bufferArray, {type: 'buffer'}); // Read the Excel file
            const sheetName = workbook.SheetNames.includes('Added Users') ? 'Added Users' : workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const csvOutput = XLSX.utils.sheet_to_csv(worksheet); // Convert to CSV format
            let data = processCSV(csvOutput); // Use the existing CSV processing function
            setIsLoadingSendEmail(true);
            try {
                await usersSendEmailCredentials(data); // Attempt to send Email credentials
                setCsvFileToSendEmail(null); // Clear the file input after operation
            } catch (error) {
                notification.error({message: t('Failed to send emails')});
            } finally {
                setIsLoadingSendEmail(false); // Stop loading regardless of the outcome
                resetFileInput(fileSendEmailInputRef); // Reset file input
                setSendPercent(0);
            }
        };
        reader.readAsArrayBuffer(file); // Read as buffer for XLSX processing
    }

    // const processCSV = (str: string, delim = ',') => {
    //     const headers = str.slice(0, str.indexOf('\n')).split(delim);
    //     const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    //
    //     const newArray: any = rows.map(row => {
    //         const values = row.split(delim);
    //         const eachObject = headers.reduce((obj: any, header, i) => {
    //             obj[header] = values[i];
    //             return obj;
    //         }, {})
    //         return eachObject;
    //     })
    //     return newArray
    // }
    // Example processCSV function
    const processCSV = (str: any, delim = ',') => {
        const lines = [];
        let line = '';
        let insideQuote = false;

        for (let i = 0; i < str.length; i++) {
            const char = str[i];

            if (char === '"') {
                insideQuote = !insideQuote;
            }

            if (char === '\n' && !insideQuote) {
                lines.push(line);
                line = '';
            } else {
                line += char;
            }
        }

        if (line) {
            lines.push(line);
        }

        const headers = lines[0].split(delim);
        const rows = lines.slice(1);

        const newArray = rows.map(row => {
            const values = row.split(new RegExp(`${delim}(?=(?:[^"]*"[^"]*")*[^"]*$)`)); // Handles commas within quotes
            const eachObject = headers.reduce((obj, header, i) => {
                // @ts-ignore
                obj[header.trim()] = values[i] ? values[i].replace(/^"|"$/g, '').trim() : '';
                return obj;
            }, {});
            return eachObject;
        });

        return newArray;
    };

    const handleUpdatePasswordForOneUser = useCallback(
        async (e) => {
            e.preventDefault();
            setIsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updatePasswordUsersFunction`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        email: email,
                        newPassword: newPassword
                    })
                });

                const responseData = await response.json();

                const FirstName = await API.getUserByEmail(email);

                if (response.status !== 200) {
                    throw new Error(responseData.error);
                } else {
                    notification['success']({message: 'Success', description: t('Password update successful')});
                    setNewPassword("");
                    setEmail("");
                }

                /* send email credentials after update user */
                try {
                    const responseSendEmail = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendEmailDetailUser`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            user: email,
                            pass: newPassword,
                            receiver: FirstName.firstName
                        })
                    });
                    // Parse and log JSON response only if the response is OK
                    if (responseSendEmail.ok) {
                        // Show success notification only once here
                        notification.success({
                            message: "Success",
                            description: "Email was sent successfully",
                        });
                    } else {
                        // If the response is not OK, handle it as an error
                        const errorResponse = await responseSendEmail.text(); // Use .text() to get the response text regardless of content type
                        throw new Error(`Server response error: ${errorResponse}`);
                    }
                }
                catch (error: any) {
                    console.error('Error sending email credentials:', error);
                    notification.error({
                        message: "Error",
                        description: `Failed to send emails. ${error.message}`,
                    });
                }

            } catch (error) {
                notification['error']({message: 'Error', description: t('Error updating password')});
            } finally {
                setIsLoading(false);
            }
        },
        [email, newPassword, token, t]
    );

    const [email2, setEmail2] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleUpdateEmail = async () => {
        if (!email2 || !newEmail) {
            setMessage('Both current email and new email are required.');
            return;
        }

        setMessage('');

        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updateEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email2,
                    newEmail,
                }),
            });

            // Handle raw response text to debug unexpected formats
            const rawResponse = await response.text();
            console.log('Raw Response:', rawResponse);

            if (!response.ok) {
                // Attempt to parse error response as JSON, or fallback to raw text
                try {
                    const errorData = JSON.parse(rawResponse);
                    throw new Error(errorData.message || 'Failed to update email.');
                } catch {
                    throw new Error(rawResponse || 'Failed to update email.');
                }
            }

            // Parse successful JSON response
            const result = JSON.parse(rawResponse);
            setMessage(result.message || 'Email updated successfully!');
        } catch (error: any) {
            console.error('Error updating email:', error);
            setMessage(error.message || 'Something went wrong.');
        }
    };


    const handleDownloadUsersDetailsWithLastSignInAfter = async () => {
        // Check if a date has been selected
        if (!selectedDate) {
            notification.error({ message: 'Please select a date first' });
            return;
        }
        setIsLoadingLastConnection(true);
        try {
            // Sending the selected date as the thresholdDate in the request
            const responseGetUsersDetails = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUsersDetailsWithLastSignInAfter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ thresholdDate: selectedDate })
            });

            if (responseGetUsersDetails.ok) {
                const usersDetails: UserDetail[] = await responseGetUsersDetails.json();

                // Filtering out users with the country marked as "Other"
                const formattedUsersDetails = usersDetails
                    .filter(user => user.country && user.country !== "Other") // Exclude "Other"
                    .map(user => ({
                        Email: user.email,
                        "First Name": user.firstName,
                        "Last Name": user.lastName,
                        Right: user.right,
                        Country: user.country,
                        Firm: user.firm,
                        "Last Sign In Time": user.lastSignInTime,
                    }));

                console.log(formattedUsersDetails);

                const wb = XLSX.utils.book_new();

                // Calculate the number of users per country for the summary
                const usersPerCountry = formattedUsersDetails.reduce((acc: any, user: any) => {
                    const country = user.Country || 'Other';
                    acc[country] = (acc[country] || 0) + 1;
                    return acc;
                }, {});

                // Sort the summary data by country name in alphabetical order
                const sortedSummaryData = Object.entries(usersPerCountry)
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .map(([country, count]) => [country, count]);

                // Create a summary sheet for the workbook
                const summarySheetName = 'Summary';
                const ws_summary = XLSX.utils.aoa_to_sheet([["Country", "Number of Users"]]);
                XLSX.utils.sheet_add_aoa(ws_summary, sortedSummaryData, { origin: "A2" });
                XLSX.utils.book_append_sheet(wb, ws_summary, summarySheetName);

                // Add All Users sheet
                const allUsersSheetName = 'All Users';
                const ws_allUsers = XLSX.utils.json_to_sheet(formattedUsersDetails);
                XLSX.utils.book_append_sheet(wb, ws_allUsers, allUsersSheetName);

                // Formatting the selectedDate to ISO format without time
                const dateObject = new Date(selectedDate);
                const formattedDate = dateObject.toISOString().replace('T', ' ').substring(0, 10);

                // Grouping users by country and sorting the country groups alphabetically
                const countryGroups = formattedUsersDetails.reduce((acc: any, user: any) => {
                    const country = user.Country || 'Other';
                    if (!acc[country]) {
                        acc[country] = [];
                    }
                    acc[country].push(user);
                    return acc;
                }, {});

                // Sorting country groups alphabetically by key (country name)
                const sortedCountries = Object.keys(countryGroups).sort();

                // Creating a sheet for each sorted country group and adding users to it
                sortedCountries.forEach(country => {
                    const users = countryGroups[country];
                    const ws = XLSX.utils.json_to_sheet(users);
                    XLSX.utils.book_append_sheet(wb, ws, country);
                });

                // Creating a binary string representation of the workbook
                const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});

                // Creating a Blob from the binary string for download
                const blob = new Blob([s2ab(wbout)], {type: 'application/octet-stream'});

                // Creating a URL for the Blob and triggering the download
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Users_Details_With_Last_SignIn_After_${formattedDate}.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();

                // Notifying the user of success
                notification.success({
                    message: "Success",
                    description: "User details fetched successfully",
                });
            } else {
                // Handling errors from the server response
                const errorResponse = await responseGetUsersDetails.text();
                throw new Error(`Server response error: ${errorResponse}`);
            }
        } catch (error:any) {
            // Notifying the user of an error
            notification.error({
                message: "Error",
                description: `Failed to fetch user details: ${error.message}`,
            });
        } finally {
            setIsLoadingLastConnection(false); // Ensuring the loading indicator is stopped regardless of the outcome
        }
    };

    const handleDownloadPredefinedReport = async () => {
        const predefinedDate = `2023-12-01T00:00:00`; // Predefined date

        setIsLoadingLastConnection(true); // Start loading state
        try {
            // Sending the predefined date as the thresholdDate in the request
            const responseGetUsersDetails = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUsersDetailsWithLastSignInAfter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ thresholdDate: predefinedDate }),
            });

            if (responseGetUsersDetails.ok) {
                const usersDetails: UserDetail[] = await responseGetUsersDetails.json();

                // Filtering and formatting the users' details
                const formattedUsersDetails = usersDetails
                    .filter(user => user.country && user.country !== "Other") // Exclude "Other"
                    .map(user => ({
                        Email: user.email,
                        "First Name": user.firstName,
                        "Last Name": user.lastName,
                        Right: user.right,
                        Country: user.country,
                        Firm: user.firm,
                        "Last Sign In Time": user.lastSignInTime,
                    }));

                console.log(formattedUsersDetails);

                const wb = XLSX.utils.book_new();

                // Calculate and sort the number of users per country for the summary
                const usersPerCountry = formattedUsersDetails.reduce((acc: any, user: any) => {
                    const country = user.Country || 'Other';
                    acc[country] = (acc[country] || 0) + 1;
                    return acc;
                }, {});
                const sortedSummaryData = Object.entries(usersPerCountry)
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .map(([country, count]) => [country, count]);

                // Create a summary sheet for the workbook
                const summarySheetName = 'Summary';
                const ws_summary = XLSX.utils.aoa_to_sheet([["Country", "Number of Users"]]);
                XLSX.utils.sheet_add_aoa(ws_summary, sortedSummaryData, { origin: "A2" });
                XLSX.utils.book_append_sheet(wb, ws_summary, summarySheetName);

                // Add All Users sheet
                const allUsersSheetName = 'All Users';
                const ws_allUsers = XLSX.utils.json_to_sheet(formattedUsersDetails);
                XLSX.utils.book_append_sheet(wb, ws_allUsers, allUsersSheetName);

                // Group and sort users by country
                const countryGroups = formattedUsersDetails.reduce((acc: any, user: any) => {
                    const country = user.Country || 'Other';
                    if (!acc[country]) {
                        acc[country] = [];
                    }
                    acc[country].push(user);
                    return acc;
                }, {});
                const sortedCountries = Object.keys(countryGroups).sort();

                // Create a sheet for each country group
                sortedCountries.forEach(country => {
                    const users = countryGroups[country];
                    const ws = XLSX.utils.json_to_sheet(users);
                    XLSX.utils.book_append_sheet(wb, ws, country);
                });

                // Create a binary string representation of the workbook
                const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

                // Create a Blob and trigger the download
                const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Users_Details_With_Last_SignIn_After_Dec_1_2023.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();

                // Notify success
                notification.success({
                    message: "Success",
                    description: "Predefined report generated successfully",
                });
            } else {
                // Handle server errors
                const errorResponse = await responseGetUsersDetails.text();
                throw new Error(`Server response error: ${errorResponse}`);
            }
        } catch (error: any) {
            // Notify error
            notification.error({
                message: "Error",
                description: `Failed to generate predefined report: ${error.message}`,
            });
        } finally {
            setIsLoadingLastConnection(false); // Stop loading state
        }
    };

    // Helper function to convert a binary string to an ArrayBuffer
    function s2ab(s: string): ArrayBuffer {
        const buffer = new ArrayBuffer(s.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buffer;
    }

    const handleDownloadUsersCreatedAfterCSV = async (selectedDate: string | null)  => {
        if (!selectedDate) {
            notification.error({ message: 'Please select a date first' });
            return;
        }
        setIsLoadingDateCreatedAfter(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUsersCreatedAfter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ thresholdDate: selectedDate })
            });

            if (response.ok) {
                const result = await response.json();
                const users: UserDetail[] = result.users;

                const formattedUsersDetails = users.map(user => ({
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                }));

                const header = 'email,firstName,lastName\n';
                const rows = formattedUsersDetails.map(user => `${user.email},${user.firstName},${user.lastName}`).join('\n');
                const csv = header + rows;

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Users_Created_After_${selectedDate}.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();

                notification.success({
                    message: "Success",
                    description: "User details fetched successfully",
                });
            } else {
                const errorResponse = await response.text();
                throw new Error(`Server response error: ${errorResponse}`);
            }
        } catch (error:any) {
            notification.error({
                message: "Error",
                description: `Failed to fetch user details: ${error.message}`,
            });
        } finally {
            setIsLoadingDateCreatedAfter(false);
        }
    };

    const handleDownloadAllUsersCSV = async () => {
        setIsLoadingAllUsers(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getAllUsers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const result = await response.json();
                const users: UserDetail[] = result.users;

                const formattedUsersDetails = users.map(user => ({
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                }));

                const header = 'email,firstName,lastName\n';
                const rows = formattedUsersDetails.map(user => `${user.email},${user.firstName},${user.lastName}`).join('\n');
                const csv = header + rows;

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `All_Users.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();

                notification.success({
                    message: "Success",
                    description: "User details fetched successfully",
                });
            } else {
                const errorResponse = await response.text();
                throw new Error(`Server response error: ${errorResponse}`);
            }
        } catch (error:any) {
            notification.error({
                message: "Error",
                description: `Failed to fetch user details: ${error.message}`,
            });
        } finally {
            setIsLoadingAllUsers(false);
        }
    };


    // Effect hook to load firm data when component mounts
    useEffect(() => {
        loadDataFirm().then();
        loadDataTaxSpecialism();
        loadDataAreaOfIndustryExpertise();
        loadDataGrade();
        loadDataRight();
        // Handle Firebase authentication state change
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            // setLoading(false);
            if (user) {
                const token = await user.getIdToken();
                setToken(token);
                let uid = user.uid;
                const userInfo = await API.getUserInfo(uid as string);
                setCurrentUserRight(userInfo.right as string);
            } else {
                setToken('');
            }
        });
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [loadDataAreaOfIndustryExpertise, loadDataFirm, loadDataTaxSpecialism, loadDataRight, loadDataGrade]);

    const downloadExcelFileImportUsers = () => {
        const excelFilePath = process.env.PUBLIC_URL + '/Excels/template-ImportUsers.xlsx';

        // Create a download link and trigger the download
        const a = document.createElement('a');
        a.href = excelFilePath;
        a.download = 'template-ImportUsers.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const downloadExcelFileDeleteUsers = () => {
        const excelFilePath = process.env.PUBLIC_URL + '/Excels/template-DeleteUsers.xlsx';

        // Create a download link and trigger the download
        const a = document.createElement('a');
        a.href = excelFilePath;
        a.download = 'template-DeleteUsers.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const downloadExcelFileUpdateUsers = () => {
        const excelFilePath = process.env.PUBLIC_URL + '/Excels/template-UpdateUsers.xlsx';

        // Create a download link and trigger the download
        const a = document.createElement('a');
        a.href = excelFilePath;
        a.download = 'template-UpdateUsers.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const downloadExcelFileSendUsers = () => {
        const excelFilePath = process.env.PUBLIC_URL + '/Excels/template-ListUsersCredentials.xlsx';

        // Create a download link and trigger the download
        const a = document.createElement('a');
        a.href = excelFilePath;
        a.download = 'template-ListUsersCredentials.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        const isValidLength = password.length >= 8 && password.length <= 20;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

        setNewPassword(password);
        if (password.length === 0){
            setIsValidPassword(true);
        } else {
            setIsValidPassword(isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar);
        }

        setPasswordValidation({
            displayVal: "block",
            uppercase: hasUppercase,
            lowercase: hasLowercase,
            specialChar: hasSpecialChar,
            numeral: hasNumber,
            minChar: isValidLength,
            maxChar: password.length <= 20 && password.length !== 0,
            valid: isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar
        });
    };

    const currentYear = new Date().getFullYear();
    return (
        <Tabs defaultActiveKey="1">
            {/*Case Import Users */}
            <Tabs.TabPane tab="Import users" key="1">
                <div>
                    <Alert severity="info">
                        <p>In this section, you can easily import users by utilizing the provided Excel template. Here's
                            a step-by-step guide to ensure a smooth process:</p>
                        <ol>
                            <li>
                                <strong>Download the Template:</strong> Begin by downloading the Excel template through
                                the designated <span
                                className={"linkExcel"} onClick={downloadExcelFileImportUsers}>link</span> . This
                                template is specifically designed to integrate seamlessly with our system.
                            </li>
                            <li>
                                <strong>Upload Your File:</strong> After populating the template with your user data,
                                press the 'Add' button to upload your file.
                            </li>
                            <li>
                                <strong>Automatic Processing:</strong> Once uploaded, the system will process your file.
                                If successful, you will receive an exported Excel file with several sheets, each serving
                                a distinct purpose:
                                <ul>
                                    <li>
                                        <strong>Added Users Sheet:</strong> This sheet lists all the new users you've
                                        added, complete with their names, emails,country and passwords. It's formatted to
                                        facilitate the distribution of email credentials.
                                    </li>
                                    <li>
                                        <strong>Updated Users Sheet:</strong> Here, you'll find existing users whose
                                        details have been updated according to your imported file.
                                    </li>
                                    <li>
                                        <strong>Invalid Users Sheet:</strong> Any entries with missing or incorrect
                                        information will be flagged here.
                                    </li>
                                    <li>
                                        <strong>Duplicated Emails Sheet: </strong> Should there be any duplicate email
                                        addresses in your file, they'll be noted on this sheet.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </Alert>
                    <br/>
                    <Alert severity="warning">
                        <strong>Please Note:</strong>
                        <ol className="b">
                            <li>The columns
                                titled <strong>TaxSpecialism</strong> and <strong>AreaOfIndustryExpertise</strong> can
                                contain multiple entries. Be sure to separate these with a slash ('/'). It's crucial to
                                use only the predefined values that are compatible with the TaxAnd Hub system.
                            </li>
                            <li>The essential fields for creating a user profile are: <strong>email</strong>, <strong>first
                                name</strong>, <strong>last name</strong>, <strong>rights</strong>, <strong>firm</strong>, and <strong>country</strong>.
                            </li>
                            <li>If the 'Is Visible' field is left blank, it will automatically be set to 'false'.</li>
                        </ol>
                    </Alert>
                    <br/>
                    <div className="container_upload">
                        {/* Label for uploading files */}
                        <label htmlFor="excelFile" className="header_upload" onDrop={handleDropAdd}
                               onDragOver={handleDragOver}>
                            <Upload/>
                            <p>Browse Excel File to upload!</p>
                        </label>
                        {/* Separate container for the selected file and delete icon */}
                        <div className="footer_upload">
                            <File onClick={handleUploadClick}/>
                            <p>{`Selected file: ${addFileName}`}</p>
                            {/* The delete icon will call the handleDeleteFile when clicked */}
                            <Delete onClick={handleDeleteFile}/>
                        </div>
                        {/* File input field */}
                        <input
                            ref={fileInputRef}
                            type='file'
                            accept='.xlsx'
                            id='excelFile'
                            style={{display: 'none'}} // Hide the input field
                            onChange={handleFileChangeAdd}
                        />
                    </div>

                    <Button
                        className={"button_upload"}
                        onClick={submitAdd}
                        type="primary"
                        size='large'
                        disabled={isAddButtonDisabled}
                        style={{fontFamily: 'Arial', fontSize: "14px", margin: "10px", width: "calc(100% - 20px)"}}>
                        {isLoadingAdd ? <Spin indicator={loadingIcon}/> : "Add"}
                    </Button>
                    <Progress percent={percentAdd} />
                </div>
            </Tabs.TabPane>
            {/*Case Delete Users */}
            <Tabs.TabPane tab="Delete users" key="2">
                <div>
                    <Alert severity="info"> <strong>In this section, delete users using the provided Excel
                        template </strong>
                        <span className={"linkExcel"} onClick={downloadExcelFileDeleteUsers}> link</span>.<br/>
                        The necessary template for this operation can be downloaded. Once your file is selected, press
                        the
                        <strong> 'Delete'</strong> button to begin the deletion process.<br/>
                        If the process completes without issues, you will receive a confirmation message stating that
                        all users listed in the Excel file have been successfully removed.<br/>
                        Conversely, if the imported Excel file contains users with <strong>invalid or not found
                            emails</strong>, an Excel file will be
                        created to detail these discrepancies.</Alert>
                    <br/>
                    <div className="container_upload">
                        <label htmlFor="excelFileDelete" className="header_upload" onDrop={handleDropDelete}
                               onDragOver={handleDragOver}>
                            <Upload/>
                            <p>Browse Excel File to upload!</p>
                        </label>
                        <div className="footer_upload">
                            <File onClick={handleUploadClickDelete}/>
                            <p>{`Selected file: ${deleteFileName}`}</p>
                            <Delete onClick={handleDeleteFile}/>
                        </div>
                        <form id='excel-form-delete' className="file-upload-form">
                            <input
                                ref={fileDeleteInputRef}
                                accept='.xlsx' // Accept .xlsx files
                                id='excelFileDelete'
                                type="file"
                                onChange={handleFileChangeDelete}
                            />
                        </form>
                        <Popconfirm
                            title="Are you sure you want to delete this users?"
                            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                            onConfirm={submitDelete}
                        >
                            <Button
                                className={"button_upload"}
                                type="primary"
                                size='large'
                                disabled={isDeleteButtonDisabled}
                                style={{
                                    fontFamily: 'Arial',
                                    fontSize: "14px",
                                    margin: "10px",
                                    width: "calc(100% - 20px)"
                                }}
                            >
                                {isLoadingDelete ? <Spin indicator={loadingIcon}/> : "Delete"}
                            </Button>
                        </Popconfirm>
                        <Progress percent={percentDelete} />
                    </div>
                </div>
            </Tabs.TabPane>
            {/*Case Update Password */}
            <Tabs.TabPane tab="Update password or Email" key="3">
                <Alert severity="info">
                    <strong>In this section, you have two password update options.</strong> To update a single user's
                    password, follow the first step. For updating passwords of multiple users, import an Excel file
                    using the provided template
                    <span className={"linkExcel"} onClick={downloadExcelFileUpdateUsers}> link</span>.<br/>
                    After selecting your file, click <strong>'Update Password List.'</strong> If successful, a message
                    will confirm all users' password modifications.<br/>
                    If the imported Excel file contains users with <strong>invalid, incorrectly formatted passwords, or duplicate email addresses</strong>, an exported Excel file will include a list of these emails
                </Alert>
                <br/>
                <h5>Update a single user's password </h5>
                <div className="ButtonContainer messageContainer">
                    <form onSubmit={handleUpdatePasswordForOneUser}>
                        <input
                            type="email"
                            className={"btn_user_pass"}
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            className={"btn_user_pass"}
                            placeholder="New password"
                            value={newPassword}
                            onChange={handlePasswordChange}
                            style={{
                                marginTop: "10px",
                                borderColor: isValidPassword ? "" : "red",
                            }}
                        />
                        <button type="submit" className={"btn_update_pass"}>Update Password
                            <Spin spinning={isLoading} indicator={loadingIcon}/>
                        </button>
                    </form>
                    {!isValidPassword && (
                        <div id="message" style={{left: "180px"}}>
                            <TooltipPassword displayVal={passwordVal.displayVal} lowercase={passwordVal.lowercase} uppercase={passwordVal.uppercase} maxChar={passwordVal.maxChar} minChar={passwordVal.minChar} numeral={passwordVal.numeral} specialChar={passwordVal.specialChar} />
                        </div>
                    )}
                </div>

                <div style={{display: "flex", marginTop: "10px"}}>
                    <Button
                        style={{marginRight: "5px"}}
                        onClick={generatePassword}
                    >
                        Generate password
                    </Button>
                    <br/>
                    {!!retVal ? (
                        <>
                            <Input
                                className={"input"}
                                id="generatedPass"
                                value={retVal}
                                readOnly
                                onFocus={(e) => {
                                    e.target.select();
                                    document.execCommand('copy');
                                    setCopiedMessage('Value copied!');
                                    setTimeout(() => {
                                        setCopiedMessage('');
                                    }, 2000);
                                }}
                            />
                            {copiedMessage && <span style={{display: "flex", marginLeft: "10px", alignItems: "center"}}>{copiedMessage}</span>}
                        </>
                    ) : null}
                </div>
                <br/>

                <h5>Update a single user's Email </h5>
                <div className="ButtonContainer messageContainer">
                        <input
                            type="email"
                            value={email2}
                            className={ "input_user_email" }
                            onChange={(e) => setEmail2(e.target.value)}
                            placeholder="Current Email"
                        />
                        <input
                            type="email"
                            value={newEmail}
                            className={ "input_user_email" }
                            onChange={(e) => setNewEmail(e.target.value)}
                            placeholder="New Email"
                        />
                    <button onClick={handleUpdateEmail} className={"btn_update_email"} style={{ height: 'fit-content', marginTop: '15px' }}>
                        Update Email
                    </button>
                    {message && <p>{message}</p>}
                </div>
                <br/>
                <h5>Update the password of a user list </h5>
                <br/>
                <Alert severity="warning">
                    <strong>Please Note:</strong>
                    <ul className="b">
                        <li>Password must be between <strong>8 and 20</strong> characters long and contain at least one <strong>uppercase</strong> letter, one <strong>lowercase</strong> letter, one <strong>number</strong>, and one <strong>special character</strong>.
                        </li>
                    </ul>
                </Alert>
                <div className="container_upload">
                    <label htmlFor="excelFileUpdate" className="header_upload" onDrop={handleDropUpdatePassword}
                           onDragOver={handleDragOver}>
                        <Upload/>
                        <p>Browse Excel File to upload!</p>
                    </label>
                    <div className="footer_upload">
                        <File onClick={handleUploadClickUpdate}/>
                        <p>{`Selected file: ${updatePasswordFileName}`}</p>
                        <Delete onClick={handleDeleteFile}/>
                    </div>
                    <form id='excel-form' className="file-upload-form">
                        <input
                            ref={fileUpdatePasswordInputRef}
                            type='file'
                            accept='.xlsx'
                            id='excelFileUpdate'
                            onChange={handleFileChangeUpdateListPassword}
                        />
                    </form>
                </div>
                <Button
                    className={"button_upload"}
                    onClick={submitUpdatePasswordForListUsers}
                    type="primary"
                    size='large'
                    disabled={isUpdateListPasswordButtonDisabled}
                    style={{fontFamily: 'Arial', fontSize: "14px", margin: "10px", width: "calc(100% - 20px)"}}>
                    {isLoadingUpdatePassword ? <Spin indicator={loadingIcon}/> : "Update Password List"}
                </Button>
                <Progress percent={percentUpdate} />
            </Tabs.TabPane>
            {/*Case Send Email Credential*/}
            <Tabs.TabPane tab="Send email credential" key="4">
                <div>
                    <Alert severity="info">
                        <strong>In this section, to send email credentials, import a user list using the provided Excel
                            template</strong>
                        <span className={"linkExcel"} onClick={downloadExcelFileSendUsers}> link</span>.<br/>
                        Use the Excel file generated from the user import process, which includes an <strong>'Added
                        Users'</strong> sheet in the required format.<br/>
                        After selecting your file, click <strong>'Send Email Credential.'</strong> A success message
                        will confirm the dispatch of emails to all listed users.<br/>
                        If the imported Excel contains entries with <strong>invalid or duplicated email
                        addresses</strong>, an exported
                        Excel file will detail these invalid and duplicate emails.
                    </Alert>
                    <br/>
                    <div className="container_upload">
                        <label htmlFor="excelFileSendEmail" className="header_upload" onDrop={handleDropSend}
                               onDragOver={handleDragOver}>
                            <Upload/>
                            <p>Browse Excel File to upload!</p>
                        </label>
                        <div className="footer_upload">
                            <File onClick={handleUploadClickSend}/>
                            <p>{`Selected file: ${sendEmailFileName}`}</p>
                            <Delete onClick={handleDeleteFile}/>
                        </div>
                        <form id='excel-form-delete' className="file-upload-form">
                            <input
                                ref={fileSendEmailInputRef}
                                accept='.xlsx'
                                id='excelFileSendEmail'
                                type="file"
                                onChange={handleFileChangeSend}
                            />
                        </form>
                    </div>
                    <Button
                        className={"button_upload"}
                        onClick={submitSendEmailCredential}
                        type="primary"
                        size='large'
                        disabled={isSendEmailButtonDisabled}
                        style={{fontFamily: 'Arial', fontSize: "14px", margin: "10px", width: "calc(100% - 20px)"}}>
                        {isLoadingSendEmail ? <Spin indicator={loadingIcon}/> : "Send email Credential"}
                    </Button>
                    <Progress percent={percentSend} />
                </div>
            </Tabs.TabPane>
            {/*Case Last Connection*/}
            <Tabs.TabPane tab="Last connection" key="5">
                <Alert severity="info">
                    In this section, <strong>specify a date to generate a user list detailing their recent logins to the
                    TaxAnd Hub</strong>.<br/>
                    The resulting Excel file includes key details, such as <strong>Email, Last Sign In Time, First Name,
                    Country, and Firm</strong>.<br/>
                    The data is organized by <strong>country</strong> for quick analysis of user activity within the
                    chosen timeframe.<br/>
                    Additionally, a <strong>'Summary'</strong> sheet is included in the Excel file,
                    which provides an overview of the number of users per country,
                    aiding in a high-level analysis of the distribution of user logins.
                </Alert>
                <br/>
                <div className="container">
                    <div className="row">
                        <h5 className="col-12 mb-3" style={{ fontFamily: 'Arial', fontWeight: 'bold', color: 'rgb(13, 60, 97)' }}>
                            Select Date and Download Users
                        </h5>
                        <div className="col-6 d-flex">
                            <DatePicker
                                showTime
                                className={"w-100 h-100"}
                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={handleDateChange}
                                disabledDate={(currentDate) => currentDate && currentDate > moment().endOf('day')}
                            />
                        </div>
                        <div className="col-6">
                            <Button
                                onClick={handleDownloadUsersDetailsWithLastSignInAfter}
                                type="primary"
                                size='large'
                                style={{ fontFamily: 'Arial', fontSize: "14px" }}
                            >
                                {isLoadingLastConnection ? <Spin indicator={loadingIcon}/> : "Download Users"}
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <h5 className="col-12 mt-4" style={{ fontFamily: 'Arial', fontWeight: 'bold', color: 'rgb(13, 60, 97)' }}>
                            Generate Predefined Report
                        </h5>
                        <div className="col-12">
                            <Button
                                onClick={handleDownloadPredefinedReport}
                                type="primary"
                                size="large"
                                style={{
                                    fontFamily: 'Arial',
                                    fontSize: '14px',
                                }}
                                block
                            >
                                {isLoadingLastConnection ? <Spin indicator={loadingIcon}/> : `Generate Report from Dec 1, ${currentYear - 1}`}
                            </Button>
                        </div>
                    </div>
                </div>
            </Tabs.TabPane>
            {/*Case to download a CSV of users created after a selected date*/}
            <Tabs.TabPane tab="Export Users to Mailchimp" key="6">
                <Alert severity="info">
                    In this section, you can either <strong>export all users</strong> or
                    <strong> specify a date to generate a CSV file of users created after the selected date</strong>.<br/>
                    The resulting CSV file includes key details, such as <strong>Email, First Name, and Last Name</strong>.<br/>
                    This data can be easily imported into <strong>Mailchimp</strong> for further use.
                </Alert>
                <br/>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-12">
                            <h5>Export All Users</h5>
                            <Button
                                onClick={handleDownloadAllUsersCSV}
                                type="primary"
                                size='large'
                                style={{fontFamily: 'Arial', fontSize: "14px", margin: "10px"}}>
                                {isLoadingAllUsers ? <Spin indicator={loadingIcon}/> : "Download All Users"}
                            </Button>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5>Export Users Created After a Specified Date</h5>
                            <div className="d-flex">
                                <DatePicker
                                    showTime
                                    className={"w-100"}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    onChange={onDateChange}
                                    disabledDate={(currentDate) => currentDate && currentDate > moment().endOf('day')}
                                />
                                <Button
                                    onClick={() => handleDownloadUsersCreatedAfterCSV(selectedDateCreatedAfter)}
                                    type="primary"
                                    size='large'
                                    style={{fontFamily: 'Arial', fontSize: "14px", margin: "10px"}}>
                                    {isLoadingDateCreatedAfter ? <Spin indicator={loadingIcon}/> : "Download Users"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Tabs.TabPane>
        </Tabs>
    );
}

export default inject("dataStore", "messageStore")(observer(ExportUser));
