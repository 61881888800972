
import {inject, observer} from "mobx-react";
import React, {FormEvent, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Button, CircularProgress, FormHelperText, Grid, IconButton, Paper, TextField} from "@material-ui/core";
import styles from "../../users/addUser/AddUserTaxand.module.scss";
import company from '../../../../assets/company-logo.jpg';
import {FirmData} from "../../../../models/general";
import {approvalRequestRef, usersRef} from "../../../../services/firebase/firebase";
import dataStore from "../../../../stores/dataStore";
import {API} from "../../../../api";
import firebase from 'firebase/app';
import {makeStyles} from "@material-ui/core/styles";
import {notification} from "antd";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiAvatar-img": {
            objectFit:"contain"
        }
    }
}));

function getModalStyle() {
    return {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };
}

interface UpdateFirmProps {
    onCloseCallback: any;
    dataRow: FirmData;
}
const UpdateFirm = (props: UpdateFirmProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { onCloseCallback,dataRow } = props;
    const [preview, setPreview] = useState(!dataRow.firmLogoUrl ? company:"");
    const [fileChanged, setFileChanged] = useState(false)
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [pdfChanged, setPdfChanged] = useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        id:dataRow.id,
        firm:dataRow.firm,
        webSite:dataRow.webSite,
        country:dataRow.country,
        logo_id:dataRow.logo_id,
        deleted:dataRow.deleted,
        firmLogoUrl:dataRow.firmLogoUrl,
        firmFilePdf:dataRow.firmFilePdf,
    });
    let file1: any;
    let firmLogoId:any;

    const handleDataChange = useCallback(
        ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
            setData((d) => ({ ...d, [currentTarget.id]: currentTarget.value }));
        },
        []
    );

    const handleChangeFile = useCallback(async (event: any) => {
        const objectUrl = URL.createObjectURL(event.target.files[0]);
        setPreview(objectUrl)
        file1 = event.target.files[0] //eslint-disable-line react-hooks/exhaustive-deps
        setFileChanged(true)
    },[file1]);

    // Handle PDF file change
    const handlePdfChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            setPdfFile(event.target.files[0]);
            setPdfChanged(true);
        }
    }, []);

    const uploadPdf = useCallback(async (id: string) => {
        if (!pdfFile) return null;

        const storageRef = firebase.storage().ref(`firmPdf/${id}.pdf`);
        await storageRef.put(pdfFile);

        return storageRef.getDownloadURL();
    }, [pdfFile]);

    const uploadImage = useCallback(async (id) => {
        firmLogoId = await getMediaId();// eslint-disable-line react-hooks/exhaustive-deps
        const res = await firebase.storage().ref("firmLogo/" + id + ".jpg")
        if (res) {
            await res.delete();
        }
        await firebase.storage().ref("firmLogo/" + id + ".jpg").put(file1);

        await firebase.storage().ref("firmLogo").child(id + ".jpg").getDownloadURL().then(url => {
            const updates: any = {};
            updates['firmLogoUrl'] = url;
            updates['logo_id'] = firmLogoId;
            firebase.database().ref('firm/' + id).update(updates)
        })
    },[file1])

    const updateUserFirmWordpress=async (userIdWordpress:number,url:string)=>{
        await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/users/${userIdWordpress}`,
            {  method: 'PUT',
                body: JSON.stringify({url:url}),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                }}).then(async res=> await res.json())
            .then(re=>console.log(re))
            .catch(err=>{
                console.log(err)
            })
    };
    const updateUserFirmLogoWordpress=async (userIdWordpress:number,logo_id:string)=>{
        let body:any={};
        body ={  fields: {company_logo:logo_id}}
        await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/acf/v3/users/${userIdWordpress}`,
            {  method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                }}).then(async res=> await res.json())
            .then(re=>console.log(re))
            .catch(err=>{
                console.log(err)
            })
    };

    const getMediaId = useCallback(async () => {
        let id = '';
        const formData = new FormData();
        formData.append("file", file1);
        const response = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("wordpressToken")}`,
            },
            body: formData
        });

        if (response.ok) {
            const data = await response.json();
            id = data.id;
        } else {
            // Handle the error here
            throw new Error('Failed to upload media');
        }
        return id;
    }, [file1]);

    const updateFirm = async (updatedFirm: FirmData, firmId: string) => {

        const users=dataStore.users
        for(let user in users){
            if(users[user].wordpress_id && users[user].firm?.id===updatedFirm.id) {
                await updateUserFirmWordpress(users[user].wordpress_id, updatedFirm.webSite)
                await updateUserFirmLogoWordpress(users[user].wordpress_id, updatedFirm.logo_id)
            }
        }

        await API.updateFirm(updatedFirm, firmId);
    }

    const updateUserFirm =  async (newData:any,firmId:string)=>{
        await usersRef.once("value", async (snapshot: any) => {
            let users=snapshot.val();
            for (let user in users) {
                const userDetails = await API.getUserInfo(user);
                if(userDetails.firm?.id) {
                    if (userDetails.firm?.id.toString() === firmId.toString()) {
                        await API.updateUserFirm(newData, userDetails.id)
                    }
                }
            }
        });
    }

    const updateApprovalFirm = async (newData:any,firmId:string)=>{
        await  approvalRequestRef.once("value", async (snapshot: any) => {
            let approvals=snapshot.val();
            for(let approval in approvals) {
                const approvalDetails = await API.getApprovalRequestInfo(approval);
                if(approvalDetails.firm?.id) {
                    if (approvalDetails.firm?.id === firmId) {
                        await API.updateApprovalFirm(newData, approval);
                    }
                }
            }
        });
    }

    const handleSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setWorking(true);
            try {
                if(fileChanged){
                    await uploadImage(data.id)
                }

                if (pdfChanged) {
                    const pdfUrl = await uploadPdf(data.id);
                    if (pdfUrl) {
                        data.firmFilePdf = pdfUrl;
                    }
                }

               const newData=await API.getFirmInfo(data.id);
               data.logo_id=!!newData.logo_id ? newData.logo_id:"";
               data.firmLogoUrl=!!newData.firmLogoUrl?newData.firmLogoUrl:"";
               await  updateFirm(data, data.id);
               await  updateUserFirm(data,data.id)
               await  updateApprovalFirm(data,data.id)

                notification["success"]({
                    message: "Success",
                    description: t("Firm successfully updated"),
                });
                onCloseCallback();
            } catch (error) {
                notification.error({
                    message: t("Error"),
                    description: t("Failed to update firm"),
                });
            } finally {
                setWorking(false);
            }
        },
        [data, onCloseCallback,file1,working,fileChanged, t, uploadPdf] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return(
        <div style={modalStyle} className={"modal-firm"}>

            <Paper className={'paper'}>
                <form onSubmit={handleSubmit}>
                   <Grid container>
                    <Grid item sm={4} xs={12}  style={{paddingTop:"5%",opacity:!!data.firmLogoUrl?1:0.5,objectFit:"contain"}} >
                        <input  type="file" onChange={(e) => handleChangeFile(e)} id="upload"
                               accept="image/*" style={{display: "none"}}/>
                        <label htmlFor="upload" className={styles.cardHeaderFirm}>
                            <IconButton color="primary" aria-label="upload picture" component="span" style={{borderRadius:'0'}}>
                                <Avatar id="avatar" src={!!preview ? preview : data.firmLogoUrl} className={`${classes.root }`}
                                        style={{
                                            borderRadius:0,
                                            width: "140px" ,
                                            height: "110px",
                                            objectFit:"contain"
                                        }}
                                />
                            </IconButton>
                        </label>
                        <label htmlFor="avatar"/>
                   </Grid>
                    <Grid item sm={8} xs={12} >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <TextField
                            className={'input'}
                            required
                            value={data.firm}
                            id="firm"
                            label="Firm"
                            onChange={handleDataChange}
                        />
                        <TextField
                            className={'input'}
                            required
                            value={data.webSite}
                            id="webSite"
                            label="WebSite"
                            onChange={handleDataChange}
                        />
                        <TextField
                            className={'input'}
                            value={data.country}
                            id="country"
                            label="Country"
                            onChange={handleDataChange}
                        />
                        <FormHelperText>make sure that you add commas between countries.</FormHelperText>
                        <FormHelperText>ex: Spain, Italy, ....</FormHelperText>
                        <br/>
                        <input
                            type="file"
                            id="uploadPdf"
                            onChange={handlePdfChange}
                            accept="application/pdf"
                            style={{ display: "none" }}
                        />
                        <label htmlFor="uploadPdf">
                            <Button variant="outlined" component="span">
                                {t("Upload PDF")}
                            </Button>
                        </label>
                        <br/>
                        <Button
                            style={{margin:"10px"}}
                            className={'input'}
                            type="submit"
                            variant="contained"
                            disabled={working}
                        >
                            {t('Update')}
                            {working ? <CircularProgress/> : ""}
                        </Button>
                        </Grid>
                        </Grid>
                   </Grid>
                </form>
            </Paper>

        </div>);
};

export default inject("dataStore", "messageStore")(observer(UpdateFirm));
