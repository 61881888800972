import {inject, observer} from "mobx-react";
import React, {FormEvent, useCallback, useEffect, useMemo, useState} from "react"
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {Collapse, IconButton, Paper, TextField} from "@material-ui/core";
import {CloudUploadOutlined, LoadingOutlined, ReloadOutlined, SaveOutlined, UserOutlined} from '@ant-design/icons';
import {
    areaOfIndustryExpertiseRef,
    countryRef,
    firmRef,
    gradeRef,
    taxSpecialismRef,
    usersRef
} from "../../../services/firebase/firebase";
import {API} from "../../../api";
import {Avatar, Button, Modal, notification, Skeleton, Spin, Tooltip} from 'antd';
import dataStore from "../../../stores/dataStore";
import {useTranslation} from "react-i18next";
import messageStore from "../../../stores/messageStore";
import ContactInfo from 'routes/core/users/addUser/ContactInfo';
import AssistantInfo from 'routes/core/users/addUser/AssistantInfo';
import PeopleInfo from 'routes/core/users/addUser/PeopleInfo';
import authStore from "../../../stores/authStore";
import {taxSpecialismeListWordpress} from "../../wordpress-conf/tax-specialism-conf";
import {DeleteOutlined} from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import {UserData} from "../../../models/general";
import {ApprovalStatus} from "../../../models/ApprovalStatusEnum";
import {Alert} from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

const Profile = () => {
    const [token, setToken] = useState('');
    // Translation
    const {t} = useTranslation();

    // Verifaction Username State
    const [inputValue, setInputValue] = useState("");
    const [usernameVerified, setUsernameVerified] = useState(true);
    const canSubmitArea = useMemo(() => inputValue !== "", [inputValue]);

    // Button Save State
    const [isEnabled, setIsEnabled] = useState(true);

    // Alert Message State
    const [openAlert, setOpenAlert] = useState(true);

    //Avatar State
    const [fileChanged, setFileChanged] = useState(false);
    const [preview, setPreview] = useState<string | undefined>();
    const [pictureDeleted, setPictureDeleted] = useState(false);
    //Submit Working State
    const [working, setWorking] = useState(false);
    const [deletingProfile, setDeletingProfile] = useState(false);
    const [loadingData,setLoadingData] = useState(true);
    // Data Load Field State
    const [listAreaOfIndustryExpertise, setListAreaOfIndustryExpertise] = React.useState<string[]>([]);
    const [listTaxSpecialism, setListTaxSpecialism] = React.useState<string[]>([]);
    const [firms, setFirms] = React.useState<any>([]);
    const [grades, setGrades] = React.useState<any>([]);
    const emailVerified = true ;

    //   User Date Field
    const [userRight, setRight] = useState("");
    const [currentUser, setCurrentUser] = useState<UserData>();
    const [isVisible, setIsVisible] = useState(false);
    const [areaOfIndustryExpertise, setAreaOfIndustryExpertise] = React.useState<any>([]);
    const [taxSpecialism, setTaxSpecialism] = React.useState<any>([]);
    const [firm, setFirm] = React.useState<any>("");
    const [adminsMail, setAdminsMail] = React.useState<string[]>([]);
    const [grade, setGrade] = React.useState<any>("");
    const [country, setCountry] = React.useState<any>("");
    const [countryId, setCountryId] = useState("");
    const [assistant,setAssistant]=useState<any>("")
    const [assistants,setAssistants]=useState<any>([])
    const [checkUserCountryWithFirmCountryActive,setCheckUserCountryWithFirmCountryActive]=useState(true);
    const [data, setData] = useState({
        id:"",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        avatarUrl: "",
        personnalBiography: "",
        profesionnalBiography: "",
        deleted: false,
        linkedInProfileLink: "",
        twitterProfileLink: "",
        assistantId:"",
        assistantName: "",
        assistantEmail: "",
        assistantTelephone: "",
        assistantMobile: "",
        wordpress_id:"",
        country:"",
        isConsentNotifications: false,
    });
    const [isConsentNotifications, setIsConsentNotifications] = useState(false);
    // Add New Area State
    const [openNewArea, setOpenNewArea] = React.useState(false);
    // List Countries Store
    const listCountries = dataStore.countries;

    // Spinner loading when Reset password button is clicked
    const [isLoading, setIsLoading] = useState(false);
    const loadingIcon = <LoadingOutlined style={{fontSize: 24, color: "#222850", marginLeft: '5px'}}/>

    // Avatar File1 , Wordpress User Id, Token Wordpress Validation
    var file1: any;
    var wordpress_id:any;

    // Validation URL
    //const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleInputChange = (inputValue: any, isValidUrl: any) => {
        //setIsButtonDisabled(!isValidUrl);
    };

    // Handle Change Field in WebForm Model
    const handleChangeFile = useCallback((event) => {
        // Clear any existing file
        setPreview('');
        setFileChanged(false);
        // Check if file is selected
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileType = file.type;
            // Check if the file is a PNG or JPG
            if (fileType === 'image/png' || fileType === 'image/jpeg') {
                dataStore.updateProfileFormChanged(true);
                const objectUrl = URL.createObjectURL(file);
                setPreview(objectUrl);
                file1 = file; //eslint-disable-line react-hooks/exhaustive-deps
                setFileChanged(true);
            } else {
                // Display error message if file is not PNG or JPG
                notification["error"]({
                    message: "Unsupported File Format",
                    description: "Please choose an image file with a .png or .jpg extension. Other file formats are not supported for upload.",
                });
                event.target.value = '';
            }
        }
    }, [file1, setPreview, setFileChanged]);

    const handleDataChange = useCallback(
        ({currentTarget}: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
            dataStore.updateProfileFormChanged(true)
            setData((d) => ({...d, [currentTarget.name]: currentTarget.value}));
        },
        []
    );
    const handleChangefirm = async (value : any) => {
        dataStore.updateProfileFormChanged(true)
        const searchedFirm = await firms.find((e : any) => e.id === value) ;
        setFirm(searchedFirm);
    }

    const handleChangeAssistant = useCallback((event: React.ChangeEvent<{ value: any }>) => {
        dataStore.updateProfileFormChanged(true)
        setAssistant(event.target.value as any)
        data.assistantEmail=event.target.value.email
        data.assistantName=event.target.value.firstName+ " " +event.target.value.lastName
        data.assistantMobile=event.target.value.mobile
        data.assistantTelephone=event.target.value.phone
    }, [data]);

    const handleChangeGrade = useCallback((value) => {
        dataStore.updateProfileFormChanged(true)
        setGrade(value)
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeCountry = useCallback((value) => {
        dataStore.updateProfileFormChanged(true)
        setCountry(value as any)
        getCountryId(value as any)
    }, []);

    const handleChangeTaxSpecialism = useCallback((value) => {
        dataStore.updateProfileFormChanged(true)
        setTaxSpecialism(value);
    }, []);

    const handleChangeAreaOfIndustryExpertise = useCallback((value) => {
        dataStore.updateProfileFormChanged(true)
        setAreaOfIndustryExpertise(value)
    }, []);

    const checkUserName = async () => {
        await API.getAllUserInfo().then(res => {
            dataStore.updateUsers(res);
        });
        const userId = firebase.auth().currentUser?.uid;
        const userInfo = await API.getUserInfo(userId as string);
        const usernameData = data.firstName + " " + data.lastName;
        const currentUsername = userInfo.firstName + " " + userInfo.lastName;
        const users = dataStore.users;
        const userCheck = users.filter(user=>((user.firstName+" "+user.lastName).toLocaleLowerCase() === usernameData.toLocaleLowerCase() &&
            (user.firstName+" "+user.lastName).toLocaleLowerCase() !== currentUsername.toLocaleLowerCase() ))
        if(userCheck.length > 0){
            setUsernameVerified(false);
        }
        else {
            setUsernameVerified(true)
        }
    }


    // New Area Of Expertise
    async function addNewAreas(inputValue: string) {
        if(inputValue!==""){
            await API.addAreaOfExpertise(inputValue);
            handleClose();
            messageStore.snackbar({
                message: t("New Value successfully added"),
                type: "success",
            });
        }
    }
    const handleOpen = () => {
        setOpenNewArea(true);
    };

    const handleClose = () => {
        setOpenNewArea(false);
    };

    const handleSubmitArea = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
        },
        []
    );

    const resetPassword = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendMailResetPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    username: data.lastName + " " + data.firstName,
                    email: data.email,
                    token: token
                })
            });
            if(response.status !== 200) {
                // Error occurred
                notification["error"]({
                    message: "An error occurred",
                    description: "An error occurred",
                });
            } else {
                setTimeout(()=>authStore.logout(),3000);
                // Password reset email sent.
                notification["success"]({
                    message: "Success",
                    description: t("Check your email to reset password"),
                });
            }
        } catch (error) {
            notification["error"]({
                message: "An error occurred",
                description: "An error occurred",
            });
        } finally {
            setIsLoading(false);
        }
    }

    // Load Data Field
    const loadDataFirms = async () => {

        const userinfo = await API.getUserInfo(firebase.auth().currentUser?.uid as string);
        firmRef.on('value', async (snap) => {

            let firmsList: any[] = [];
            let firms = snap.val();
            for (let f in firms) {
                const detailedFirm = await API.getFirmInfo(f);
                if (!detailedFirm.deleted) {
                    firmsList.push(detailedFirm);
                    if (detailedFirm.id === userinfo.firm?.id) {
                        setFirm(detailedFirm)
                    }
                }
            }

            setFirms(firmsList);
            dataStore.updateFirms(firmsList);
        });
    }


    const handleClick = useCallback(
        (event: React.ChangeEvent<{ value: unknown }>) => {
            setInputValue(event.target.value as string);
        },
        []
    );

    const loadDataAssistant = async () => {
        const userId = firebase.auth().currentUser?.uid;
        const res = await API.getUserInfo(userId as any);
        usersRef.on('value', async (snap) => {
            let AssistantList: any[] = [];
            let users = snap.val();
            for (let user in users) {
                const detailedUser = await API.getUserInfo(user);
                if (detailedUser && !detailedUser.deleted) {
                    if((detailedUser.right==="Firm administrator" || detailedUser.right==='Assistant') && detailedUser.country===country) {
                        AssistantList.push(detailedUser);
                        if (detailedUser.id === res.assistantId) {
                            setAssistant(detailedUser)
                        }
                    }
                }
            }
            setAssistants(AssistantList)
        });
    }
    const loadDataAreaOfIndustryExpertise = async () => {

        areaOfIndustryExpertiseRef.on('value', async (snap) => {

            let areaOfIndustryExpertiseList: string[] = [];
            let areaOfIndustryExpertise = snap.val();
            for (let f in areaOfIndustryExpertise) {
                const detailedAreaOfExpertise = await API.getAreaOfExpertiseInfo(f);
                areaOfIndustryExpertiseList.push(detailedAreaOfExpertise);
            }
            setListAreaOfIndustryExpertise(areaOfIndustryExpertiseList);
        })
    }


    const loadDataGrade = () => {
        gradeRef.on('value', (snap) => {
            let result = snap.val();
            setGrades(result);
        });
    }

    const loadDataCountry = () => {
        countryRef.on('value', async (snap) => {
            let newListCountry: any[] = [];
            let result = snap.val();
            for (let country in result) {
                const detailedCountry = await API.getCountryInfo(country);
                if(detailedCountry===dataStore.countrySelected){
                    setCountryId(country);
                }
                newListCountry.push(detailedCountry);
            }
            dataStore.updateCountries(newListCountry)
        });
    }

    const loadAdmins = async () => {
        await firebase.database().ref().child("users").orderByChild('deleted').equalTo(false).on('value', async (snapshot) => {
            let users=snapshot.val();
            let emails:any[]=[];
            for(let user in users){
                let userInfo=await API.getUserInfo(user);
                if(userInfo && userInfo.right === "Global administrator"){
                    emails.push(userInfo.email)
                }
            }
            setAdminsMail(emails);
            setLoadingData(false);
        });
    }

    const loadDataTaxSpecialism = () => {
        taxSpecialismRef.on('value', (snap) => {
            let result = snap.val();
            setListTaxSpecialism(result);
        });
    }

    // Get Country Id from List Country Firebase
    const getCountryId =(countryName:string)=>{
        countryRef.on('value', async (snap) => {
            let result = snap.val();
            for (let country in result) {
                const detailedCountry = await API.getCountryInfo(country);
                if(detailedCountry===countryName){
                    setCountryId(country);
                    break;
                }
            }

        });
    }

    // Get Current User From Firebase Database
    const getCurrentUser =async () => {
        const userId = firebase.auth().currentUser?.uid;
        const userInfo = await API.getUserInfo(userId as string);
        setRight(userInfo.right as string);
        setCurrentUser(userInfo);
        setData({
            id:userInfo.id,
            email: userInfo.email,
            firstName: !!userInfo.firstName ? userInfo.firstName : "",
            lastName: !!userInfo.lastName ? userInfo.lastName : "",
            phone: !!userInfo.phone ? userInfo.phone : "",
            mobile: !!userInfo.mobile ? userInfo.mobile : "",
            avatarUrl: !!userInfo.avatarUrl ? userInfo.avatarUrl : "",
            personnalBiography: !!userInfo.personnalBiography ? userInfo.personnalBiography : "",
            deleted: false,
            profesionnalBiography: !!userInfo.profesionnalBiography ? userInfo.profesionnalBiography : "",
            linkedInProfileLink: !!userInfo.linkedInProfileLink ? userInfo.linkedInProfileLink : "",
            twitterProfileLink: !!userInfo.twitterProfileLink ? userInfo.twitterProfileLink : "",
            assistantId:!!userInfo.assistantId ? userInfo.assistantId : "",
            assistantName: !!userInfo.assistantName ? userInfo.assistantName : "",
            assistantEmail: !!userInfo.assistantEmail ? userInfo.assistantEmail : "",
            assistantTelephone: !!userInfo.assistantTelephone ? userInfo.assistantTelephone : "",
            assistantMobile: !!userInfo.assistantMobile ? userInfo.assistantMobile : "",
            wordpress_id:!!userInfo.wordpress_id ?userInfo.wordpress_id:"",
            country:!!userInfo.country ?userInfo.country:"",
            isConsentNotifications:userInfo.isConsentNotifications ?? false
        })
        setFirm(!!userInfo.firm ? userInfo.firm : "");
        setGrade(!!userInfo.grade ? userInfo.grade : "");
        setCountry(!!userInfo.country ? userInfo.country : "");
        setData((d) => ({...d, areaOfIndustryExpertise: userInfo.areaOfIndustryExpertise}));
        setData((d) => ({...d, taxSpecialisme: userInfo.taxSpecialisme}));
        setAreaOfIndustryExpertise(!!userInfo.areaOfIndustryExpertise ? userInfo.areaOfIndustryExpertise : []);
        setTaxSpecialism(!!userInfo.taxSpecialisme ? userInfo.taxSpecialisme : []);
        setPreview(userInfo.avatarUrl);
        // setIsVisible(userInfo.isVisible as any);
        setIsVisible(!!userInfo.isVisible ? userInfo.isVisible : false);
        setIsConsentNotifications(userInfo.isConsentNotifications ?? false);
        return userInfo;
    }


    // Upload Avatar firebase
    const uploadImage = useCallback(async (id:string, requestId:string) => {
        const userInfo = await API.getUserInfo(firebase.auth().currentUser?.uid as string);

        //If User Is Global Admin or has isVisible a false Can Update Image Directly
         if (userInfo.right === "Global administrator" || userInfo.isVisible===false) {
            const res = await firebase.storage().ref("userAvatar/" + id + ".jpg")
            if(userInfo.avatarUrl){
                res.delete()
            }
            await firebase.storage().ref("userAvatar/" + id + ".jpg").put(file1)
            await firebase.storage().ref("userAvatar").child(id + ".jpg").getDownloadURL().then(async url => {
                const updates: any = {};
                updates['avatarUrl'] = url;
                await firebase.database().ref('users/' + id).update(updates)

            })
            // If User Is Standard User Or Firm Admin Send Image UserRequest
        } else {
          if(!pictureDeleted) {
              await firebase.storage().ref("userRequest/" + requestId + ".jpg").put(file1)
              await firebase.storage().ref("userRequest").child(requestId + ".jpg").getDownloadURL().then(async url => {
                  const updates: any = {};
                  updates['avatarUrl'] = url;
                  await firebase.database().ref("approval_request/" + requestId).update(updates)
              })
          }
        }
    },[file1])// eslint-disable-line react-hooks/exhaustive-deps

    // Convert Blob To File
    const blobToFile = (theBlob: Blob, fileName:string): File => {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    //Create Random Name File Image
    function makeid(length:any) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    // Download Image From Firebase And Upload It To Wordpress
    const uploadImageWordpress=useCallback(async ()=>{
        let id:any;
        var formData=new FormData();
        const userId = firebase.auth().currentUser?.uid;
        let oldRef = await firebase.storage().ref("userAvatar").child(userId + ".jpg")
        await oldRef.getDownloadURL().then(async url => {
            var blob = await fetch(url).then(r => r.blob())
            var myFile = blobToFile(blob, makeid(6)+".png");
            formData.append("file",myFile)
            await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`,{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                },
                body: formData
            }).then(async response => await response.json())
                .then( res => {

                    id=res.id
                    dataStore.updateImageWordPressSelected(id)
                });
        })
    },[])


    // Upload Image Wordpress And Get Id
    const getMediaId=useCallback( async ()=>{
        let id:any;
        const formData=new FormData();
        formData.append("file",file1)
        await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
            },
            body: formData
        }).then(async response => await response.json())
            .then( res => {
                id=res.id
                dataStore.updateImageWordPressSelected(id)
            });
    },[file1])

    const checkUserCountryWithFirmCountry= async ()=> {
        let firmCountries = firm?.country?.split(',').map((c:string)=>c.trim())
        if(firmCountries?.includes(country) || !country || !firm){
            setCheckUserCountryWithFirmCountryActive(true)
        }
        else {
            setCheckUserCountryWithFirmCountryActive(false)
        }
    }

    useEffect(()=>{
        checkUserCountryWithFirmCountry()
    },[country,firm]) // eslint-disable-line react-hooks/exhaustive-deps



    // Change Biography Formt To Be Accept By Wordpress
    const textFormat=(biography:any)=>{
        let text:string=biography;
        let p:string="<p>";
        let pEnd:string="</p>";
        let count=0;
        for(let i=0;i<data.profesionnalBiography.length;i++){
            if(count%2===0) {
                text = text.replace("\n", pEnd + '&nbsp;');
                count++
            }else {
                text = text.replace("\n", p);
                count++
            }

        }
        text=text+pEnd;
        return text
    }

    // Filter TaxSpecialismList To Be include In The List /Wordpress-conf/taxSpecialism.tsx
    const filterTaxSpecialisme=(taxSpecialismList:string[])=>{
        let intersection = taxSpecialismList.filter(x => taxSpecialismeListWordpress.includes(x));
        return intersection
    }

    // Delete avatar from Firebase
    const deleteImageFirebase = useCallback(async (id) => {
        const updates: any = {};
        updates['avatarUrl'] = "";
        firebase.database().ref('users/' + id).update(updates)
    },[file1])// eslint-disable-line react-hooks/exhaustive-deps

    function noOtherChanges(userInfo:UserData) {
        // Comparing fields from data, and additional fields like grade, country, etc.
        return (
            data.email === userInfo.email &&
            data.firstName === userInfo.firstName &&
            data.lastName === userInfo.lastName &&
            data.phone === userInfo.phone &&
            data.mobile === userInfo.mobile &&
            data.avatarUrl === userInfo.avatarUrl &&
            data.personnalBiography === userInfo.personnalBiography &&
            data.profesionnalBiography === userInfo.profesionnalBiography &&
            data.linkedInProfileLink === userInfo.linkedInProfileLink &&
            data.twitterProfileLink === userInfo.twitterProfileLink &&
            (assistant.id ? assistant.id : data.assistantId) === userInfo.assistantId &&
            data.assistantName === userInfo.assistantName &&
            data.assistantEmail === userInfo.assistantEmail &&
            data.assistantTelephone === userInfo.assistantTelephone &&
            data.assistantMobile === userInfo.assistantMobile &&
            (data.wordpress_id ? data.wordpress_id : wordpress_id) === userInfo.wordpress_id &&
            grade === userInfo.grade &&
            country === userInfo.country &&
            JSON.stringify(areaOfIndustryExpertise) === JSON.stringify(userInfo.areaOfIndustryExpertise) &&
            JSON.stringify(taxSpecialism) === JSON.stringify(userInfo.taxSpecialisme) &&
            isVisible === userInfo.isVisible &&
            data.deleted === userInfo.deleted
        );
    }

    // Handle the deletion of a profile
    const handleProfileDeletion = useCallback(async () => {
        // Retrieve the current user data
        const userInfo = await API.getUserInfo(firebase.auth().currentUser?.uid as string);
        // The Global administrator cannot delete their profile
        if (userInfo.right === "Global administrator") {
            notification['warning']({
                message: 'Warning',
                description: t('As a global administrator, you cannot delete your profile.')
            });
        }
        // If user is a Firm administrator, Standard User, or Assistant
        else {
            try {
                // Sample function to make the fetch call with types defined for parameters.
                const callFirebaseFunction = async (endpoint: string,method:string, data: Record<string, any>) => {
                    const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
                        method: method,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify(data)
                    });
                    if (!response.ok) {
                        throw new Error('Error calling Firebase function: ' + endpoint);
                    }
                    return await response.json();
                }
                //setWorking(true);
                setDeletingProfile(true);
                // Create request to delete profile
                const requestId = await API.getRequestId();
                await callFirebaseFunction('createRequestProfileUpdate','POST', {
                    requestId: requestId,
                    userId: !!userInfo.id ? userInfo.id : "",
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    mobile: data.mobile,
                    right: userInfo.right,
                    isVisible: isVisible,
                    firm: firm,
                    deleted: true,
                    type: "Delete",
                    requestedBy: userInfo.firstName + " " + userInfo.lastName,
                    subject: userInfo.firstName + " " + userInfo.lastName,
                    profesionnalBiography: data.profesionnalBiography,
                    personnalBiography: data.personnalBiography,
                    linkedInProfileLink: data.linkedInProfileLink,
                    twitterProfileLink: data.twitterProfileLink,
                    assistantId:!!assistant.id?assistant.id:"",
                    assistantName: data.assistantName,
                    assistantEmail: data.assistantEmail,
                    assistantTelephone: data.assistantTelephone,
                    assistantMobile: data.assistantMobile,
                    taxSpecialisme: taxSpecialism,
                    areaOfIndustryExpertise: areaOfIndustryExpertise,
                    grade: grade,
                    country: country,
                    wordpress_id:!!data.wordpress_id ? data.wordpress_id : "",
                    callType:""
                });

                // Send mail after sending request
                const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendMailRequest`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        requestBy: userInfo.firstName + " " + userInfo.lastName,
                        emailList: adminsMail
                    })
                });
                if(response.status !== 200) {
                    throw new Error("Error creating a request.");
                } else {
                    notification['success']({
                        message: 'Success',
                        description: t('Thank you for your update, it is being processed')
                    });
                }
            }
            catch (error) {
                notification["error"]({
                    message: "Failed to update profile",
                    description: "Failed to update profile",
                });
            }
        }
        setWorking(false);
        setDeletingProfile(false);
    }, [t, adminsMail, data, areaOfIndustryExpertise, assistant, country, firm, grade, isVisible, taxSpecialism, token]);

    // Check If New Email Added Already Exist And Get His Wordpress Id
    const checkUserWordpress = async (email: string) => {
        try {
            const tokenWordpress = localStorage.getItem("wordpressToken");
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUserWordPressById?email=${email.trim()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokenWordpress}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const res = await response.json();
                return res.userId;
            } else if (response.status === 404) {
                return null; // Return null if user not found
            } else {
                throw new Error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error('Error checking WordPress user:', error);
            throw error;
        }
    };
    function generateValidUsername(firstName:string, lastName:string) {
        // Combine and remove non-alphanumeric characters, no replacement with random characters
        // eslint-disable-next-line
        let username = (firstName + lastName).replace(/[^a-zA-Z0-9_\-]/g, '');
        // Ensure the username is not empty
        if (!username) {
            throw new Error('Invalid username generated from first and last names.');
        }
        return username;
    }
    const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            //Get User Info From Current User Id
            const userId = await firebase.auth().currentUser?.uid;
            const userInfo = await API.getUserInfo(firebase.auth().currentUser?.uid as string);
            try {
                const isConsentOnlyChange = (isConsentNotifications !== userInfo.isConsentNotifications) && noOtherChanges(userInfo);
                // Sample function to make the fetch call with types defined for parameters.
                const callFirebaseFunction = async (endpoint: string, method: string, data: Record<string, any>) => {
                    const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
                        method: method,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify(data)
                    });
                    if (!response.ok) {
                        throw new Error('Error calling Firebase function: ' + endpoint);
                    }
                    return await response.json();
                }
                setWorking(true)
                if (isConsentOnlyChange) {
                    // Update only isConsentNotifications
                    const userUpdateIsConsentNotifications = {
                        ...userInfo,
                        isConsentNotifications: isConsentNotifications
                    };
                    const res = await API.updateUser(userUpdateIsConsentNotifications, userId as any);
                    if (res) {
                        notification["success"]({
                            message: "Notification Preference Updated",
                            description: "Your notification preference has been updated successfully.",
                        });
                    }
                }
                else {
                    /*If a user holds the role of a Global Administrator,
                     they must be present in both the Firebase system and WordPress system,
                     irrespective of the state of their 'is visible' attribute*/
                    let shouldUpdateFirebase = false;
                    if (userInfo.right === "Global administrator") {
                        // Check If New Email Added Already Exist And Get His Wordpress Id
                        // eslint-disable-next-line
                        wordpress_id = await checkUserWordpress(userInfo.email);
                        //If File Not Changed And AvatarUrl Exist on Firebase So We Execute UploadUmageWordpress
                        if (!fileChanged && data.avatarUrl) {
                            await uploadImageWordpress();
                        }
                        // If File Changed We Execute getMediaId() to Upload Image To Wordpress And Get Id
                        if (fileChanged) {
                            await getMediaId();
                        }
                        // Delete avatar from Firebase
                        if (pictureDeleted) {
                            await deleteImageFirebase(userInfo.id);
                        }
                        //Get Image Wordpress Id from Store depend on Witch last Two Condition Executed
                        const image = dataStore.imageWordPressSelected;
                        // Build Acf Data
                        const userWordpressUpdateACF = {
                            fields: {
                                //display_email: updatedUserData.email,
                                image: image && !pictureDeleted ? image : 9999999,
                                telephone: data.phone,
                                mobile: data.mobile,
                                biography: !!data.profesionnalBiography ? textFormat(data.profesionnalBiography) : "",
                                connect_url: data.linkedInProfileLink,
                                assistants_name: data.assistantName,
                                assistants_email: data.assistantEmail,
                                position: grade,
                                user_location: countryId,
                                show: isVisible,
                                area_of_expertise: taxSpecialism.length > 0 ? filterTaxSpecialisme(taxSpecialism) : "",
                                company_logo: firm.logo_id
                            }
                        }
                        // Generate a sanitized username
                        const username = generateValidUsername(data.firstName, data.lastName);
                        //Build Native Data
                        const userWordpressUpdateNative = {
                            name: username,
                            first_name: data.firstName,
                            last_name: data.lastName,
                            url: !!firm ? firm.webSite : "",
                        }
                        // If User Has Already Wordpress Id
                        if(wordpress_id || data.wordpress_id ) {
                            // Update Native Data Wordpress For The User Exist Already
                            await callFirebaseFunction('updateUserWordpressNative', 'PUT', {
                                userIdWordpress:!!data.wordpress_id ? data.wordpress_id : wordpress_id,
                                token: localStorage.getItem("wordpressToken"),
                                first_name: userWordpressUpdateNative.first_name,
                                last_name: userWordpressUpdateNative.last_name,
                                name: userWordpressUpdateNative.name,
                                url: userWordpressUpdateNative.url,
                                callType: ""

                            });
                        }
                        // Id User Has Not Wordpress Id
                        if (!data.wordpress_id && !wordpress_id) {
                            // Create Wordpress User By Insert Native Data
                            const res = await callFirebaseFunction('createUserWordPressNativeFunction', 'POST', {
                                token: localStorage.getItem("wordpressToken"),
                                name: userWordpressUpdateNative.name,
                                username: userWordpressUpdateNative.name,
                                first_name: userWordpressUpdateNative.first_name,
                                last_name: userWordpressUpdateNative.last_name,
                                email: data.email,
                                password: "i5Z%sTF6tdu&#*h#ydq$J%PM",
                                url: userWordpressUpdateNative.url,
                                description: "",
                                callType: ""
                            });
                            if (res.data && res.data.userId) {
                                // eslint-disable-next-line
                                wordpress_id = res.data.userId;
                            } else {
                                notification["error"]({
                                    message: "User created but no ID returned",
                                    description: "User created but no ID returned",
                                });
                            }
                        }
                        // For The Two last Condition If He Has Wordpress Or Not We Update ACF Data
                        await callFirebaseFunction('updateUserWordpressAcf', 'PUT', {
                            userIdWordpress: !!data.wordpress_id ? data.wordpress_id : wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            image: userWordpressUpdateACF.fields.image,
                            telephone: userWordpressUpdateACF.fields.telephone,
                            mobile: userWordpressUpdateACF.fields.mobile,
                            biography: userWordpressUpdateACF.fields.biography,
                            connect_url: userWordpressUpdateACF.fields.connect_url,
                            assistants_name: userWordpressUpdateACF.fields.assistants_name,
                            assistants_email: userWordpressUpdateACF.fields.assistants_email,
                            position: userWordpressUpdateACF.fields.position,
                            user_location: userWordpressUpdateACF.fields.user_location,
                            show: userWordpressUpdateACF.fields.show,
                            area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                            company_logo: userWordpressUpdateACF.fields.company_logo,
                            callType: ""

                        });
                        dataStore.updateImageWordPressSelected(0);
                        shouldUpdateFirebase = true;
                    }
                   /* users other than the global administrator whose isVisible value is set to false
                    normal creation in firebase without wordpress*/
                    else if (userInfo.isVisible===false) {
                        shouldUpdateFirebase = true;
                    }
                     /* Create Request To Update Profile of users other than the global administrator
                     who have isVisible set to true*/
                    if (userInfo.right !== "Global administrator" && userInfo.isVisible === true) {
                        try {
                            //Create Request To Update Profile
                            const requestId = await API.getRequestId();
                            await callFirebaseFunction('createRequestProfileUpdate', 'POST', {
                                requestId: requestId,
                                userId: !!userId ? userId : "",
                                email: data.email,
                                firstName: data.firstName,
                                lastName: data.lastName,
                                phone: data.phone,
                                mobile: data.mobile,
                                right: userInfo.right,
                                isVisible: isVisible,
                                firm: firm,
                                deleted: userInfo.deleted,
                                type: "Update",
                                requestedBy: userInfo.firstName + " " + userInfo.lastName,
                                subject: userInfo.firstName + " " + userInfo.lastName,
                                profesionnalBiography: data.profesionnalBiography,
                                personnalBiography: data.personnalBiography,
                                linkedInProfileLink: data.linkedInProfileLink,
                                twitterProfileLink: data.twitterProfileLink,
                                assistantId: !!assistant.id ? assistant.id : "",
                                assistantName: data.assistantName,
                                assistantEmail: data.assistantEmail,
                                assistantTelephone: data.assistantTelephone,
                                assistantMobile: data.assistantMobile,
                                taxSpecialisme: taxSpecialism,
                                areaOfIndustryExpertise: areaOfIndustryExpertise,
                                grade: grade,
                                country: country,
                                wordpress_id: !!data.wordpress_id ? data.wordpress_id : "",
                                callType: ""
                            });
                            // If File Changed Upload Image Using Id Of User And Link It To Request
                            if (fileChanged) {
                                await uploadImage(data.id, requestId);
                                setFileChanged(false);
                            }
                            // Link empty avatar value to the request if avatar is deleted
                            if(pictureDeleted) {
                                const updates: any = {};
                                updates['avatarUrl'] = "";
                                await firebase.database().ref("approval_request/" + requestId).update(updates);
                            }

                            // Link empty taxSpecialism value to the request if taxSpecialism is deleted
                            if(taxSpecialism.length === 0 && userInfo.taxSpecialisme?.length !== 0) {
                                const updatedTaxSpec: any = {};
                                updatedTaxSpec['taxSpecialisme'] = "";
                                await firebase.database().ref("approval_request/" + requestId).update(updatedTaxSpec);
                            }

                            // Link empty areaOfIndustryExpertise value to the request if areaOfIndustryExpertise is deleted
                            if(areaOfIndustryExpertise.length === 0 && userInfo.areaOfIndustryExpertise?.length !== 0) {
                                const updatedIndustry: any = {};
                                updatedIndustry['areaOfIndustryExpertise'] = "";
                                await firebase.database().ref("approval_request/" + requestId).update(updatedIndustry);
                            }

                            //Send Mail After Sending Request
                            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendMailRequest`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                },
                                body: JSON.stringify({
                                    requestBy: userInfo.firstName + " " + userInfo.lastName,
                                    emailList: adminsMail
                                })
                            });
                            if (response.status !== 200) {
                                throw new Error("Error creating a request.");
                            }
                            else {
                                dataStore.updateProfileFormChanged(false);
                                // Update the approvalStatus to Pending after sending the request
                                const updatedInfo: any = {
                                    approvalStatus: ApprovalStatus.Pending
                                }
                                await API.updateUser(updatedInfo, userInfo.id);
                                // Disable the Save button
                                setIsEnabled(false);
                                notification['success']({
                                    message: 'Success',
                                    description: t('Thank you for your update, it is being processed')
                                });
                            }
                        }
                        catch (error) {
                            notification["error"]({
                                message: "Failed to update profile",
                                description: "Failed to update profile",
                            });
                        }
                    }
                    // Mise à jour dans Firebase
                    if (shouldUpdateFirebase) {
                        // Mise à jour dans Firebase
                        // Build Data For Firebase database
                        const userUpdate = {
                            id: !!userId ? userId : "",
                            email: data.email,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            phone: data.phone,
                            mobile: data.mobile,
                            right: userInfo.right,
                            isVisible: isVisible,
                            firm: firm,
                            deleted: userInfo.deleted,
                            personnalBiography: data.personnalBiography,
                            profesionnalBiography: data.profesionnalBiography,
                            linkedInProfileLink: data.linkedInProfileLink,
                            twitterProfileLink: data.twitterProfileLink,
                            assistantId: !!assistant.id ? assistant.id : data.assistantId,
                            assistantName: data.assistantName,
                            assistantEmail: data.assistantEmail,
                            assistantTelephone: data.assistantTelephone,
                            assistantMobile: data.assistantMobile,
                            grade: grade,
                            country: country,
                            areaOfIndustryExpertise: areaOfIndustryExpertise,
                            taxSpecialisme: taxSpecialism,
                            wordpress_id: !!data.wordpress_id ? data.wordpress_id : !!wordpress_id ? wordpress_id : "",
                            isConsentNotifications: isConsentNotifications,
                            approvalStatus: ApprovalStatus.Default
                        };
                        // If File Changed Upload Image Using Id Of User
                        if (fileChanged) {
                            uploadImage(userUpdate.id, "");
                            setFileChanged(false);
                        }
                        // Update User Firebase
                        const res = await API.updateUser(userUpdate, userId as any);
                        if (res) {
                            dataStore.updateProfileFormChanged(false)
                            notification["success"]({
                                message: "Success",
                                description: t("Profile updated"),
                            });
                            dataStore.updateUserInfo({
                                id: !!userId ? userId : "",
                                email: data.email,
                                firstName: data.firstName,
                                lastName: data.lastName,
                                phone: data.phone,
                                mobile: data.mobile,
                                right: userInfo.right,
                                isVisible: isVisible,
                                firm: firm,
                                deleted: userInfo.deleted,
                                personnalBiography: data.personnalBiography,
                                profesionnalBiography: data.profesionnalBiography,
                                linkedInProfileLink: data.linkedInProfileLink,
                                twitterProfileLink: data.twitterProfileLink,
                                assistantId: !!assistant.id ? assistant.id : data.assistantId,
                                assistantName: data.assistantName,
                                assistantEmail: data.assistantEmail,
                                assistantTelephone: data.assistantTelephone,
                                assistantMobile: data.assistantMobile,
                                grade: grade,
                                country: country,
                                areaOfIndustryExpertise: areaOfIndustryExpertise,
                                taxSpecialisme: taxSpecialism,
                                wordpress_id: !!data.wordpress_id ? data.wordpress_id : !!wordpress_id ? wordpress_id : "",
                                avatarUrl: preview,
                                approvalStatus: ApprovalStatus.Default
                            });
                        }
                    }
                }
            } catch (error) {
                notification["error"]({
                    message: "Update failed",
                    description: "Update failed",
                });
            }
            setWorking(false);
            setPictureDeleted(false);
        }, [firm, pictureDeleted, fileChanged, taxSpecialism, wordpress_id, countryId, adminsMail, assistant, areaOfIndustryExpertise, isVisible, grade, country, data, t]// eslint-disable-line react-hooks/exhaustive-deps
    );
    const toggleConsentNotifications = useCallback(() => {
        setIsConsentNotifications(prevValue => !prevValue);
        setData(d => ({ ...d, isConsentNotifications: !d.isConsentNotifications }));
    }, [setData, setIsConsentNotifications]);

    // Update the approvalStatus to Default when closing the alert
    const handleCloseMessageAlert = useCallback(async (userID) => {
        const updatedInfo: any = {
            approvalStatus: ApprovalStatus.Default
        }
        await API.updateUser(updatedInfo, userID);
    }, []);


    useEffect(() => {
        setData(d => ({ ...d, isConsentNotifications }));
    }, [isConsentNotifications]);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async function (user) {
                if (user) {
                    const token = await user.getIdToken();
                    setToken(token);
                } else {
                    setToken('');
                }
            }
        );
    }, []);

    // First Loading Components
    useEffect(() => {
        dataStore.updateProfileFormChanged(false)
        loadDataFirms();
        getCurrentUser();
        loadDataGrade();
        loadDataCountry();
        loadDataAreaOfIndustryExpertise();
        loadDataTaxSpecialism();
        loadAdmins();
    }, []);

    useEffect(() => {
        loadDataAssistant()
    }, [country]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleRemove = () => {
        setPreview(undefined);
        setPictureDeleted(true);
    };

    return (
        <>
            <Skeleton loading={loadingData} active avatar >
                <form onSubmit={handleSubmit}>
                    <div className="UserModal">
                        <Collapse in={!isEnabled || (userRight!=="Global administrator" && currentUser?.approvalStatus === ApprovalStatus.Pending)}>
                            <Alert
                                severity="info"
                            >
                                Your modifications have been submitted for review to the Global Administrator. You can make further updates to your profile once the administrator approves or declines your request.
                            </Alert>
                        </Collapse>
                        <Collapse in={openAlert && (userRight!=="Global administrator" && currentUser?.approvalStatus === ApprovalStatus.Approved)}>
                            <Alert
                                severity="success"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            handleCloseMessageAlert(currentUser?.id).then(() => setOpenAlert(false));
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }
                            >
                                Your modification request has been approved by the Global Administrator. You can now close this alert message.
                            </Alert>
                        </Collapse>
                        <Collapse in={openAlert && (userRight!=="Global administrator" && currentUser?.approvalStatus === ApprovalStatus.Declined)}>
                            <Alert
                                severity="warning"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            handleCloseMessageAlert(currentUser?.id).then(() => setOpenAlert(false));
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }
                            >
                                Your modification request has been declined by the Global Administrator. You can now close this alert message.
                            </Alert>
                        </Collapse>
                        <br/>
                        <Alert severity="warning">
                            <strong>Please Note:</strong>
                            <span> that any changes will first need to be approved by the Global Team within 48h</span>
                        </Alert>
                        <br/>
                        <div className="usernameAv" >
                            <div className="ProfileHeader">
                                <Avatar
                                    size={80}
                                    icon={preview === undefined || preview === '' ? <UserOutlined /> : undefined}
                                    style={{ boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)" }}
                                    src={preview}
                                />
                                <label className="UploadContainer" htmlFor="upload">
                                    <CloudUploadOutlined />
                                </label>
                               <DeleteOutlined className={"UploadContainerLeft"} onClick={handleRemove}/>
                                <input
                                    type="file"
                                    onChange={handleChangeFile}
                                    id="upload"
                                    accept="image/png, image/jpeg"
                                />
                                <span style={{marginLeft:'10px', position:'absolute', right: '0', top: '0'}}>
                                <Tooltip title="Please upload a square photo to ensure it fits well within the circular frame. Accepted file formats are PNG and JPG only.">
                                    <InfoIcon/>
                                </Tooltip>
                                </span>
                            </div>
                        </div>
                        <div className="Body" style={{marginTop : "25px"}}>
                            <h2 className="ContactTitle">Contact</h2>
                            <ContactInfo
                                checkUserName={checkUserName}
                                listCountries={listCountries}
                                handleChangeCountry={handleChangeCountry}
                                data={data}
                                handleDataChange={handleDataChange}
                                usernameVerified={usernameVerified}
                                emailVerified={emailVerified}
                                action="Edit"
                                country={country}
                                checkUserCountryWithFirmCountryActive={checkUserCountryWithFirmCountryActive}
                                isConsentNotifications={isConsentNotifications}
                                toggleConsentNotifications={toggleConsentNotifications}
                                onChange={handleInputChange}
                            />
                            <h2 className="ContactTitle" style={{marginTop : "25px"}}>Executive Assistant Contact Details</h2>
                            <AssistantInfo
                                assistant={assistant}
                                assistants={assistants}
                                handleChangeAssistant={
                                    handleChangeAssistant
                                }
                                data={data}
                                handleDataChange={
                                    handleDataChange
                                }
                                action="Edit"

                            />
                            <h2 className="ContactTitle" style={{marginTop : "25px"}}>People information</h2>
                            <PeopleInfo
                                handleChangefirm={handleChangefirm}
                                handleChangeGrade={handleChangeGrade}
                                handleDataChange={
                                    handleDataChange
                                }
                                firms={firms}
                                firm={firm}
                                grades={grades}
                                grade={grade}
                                handleChangeAreaOfIndustryExpertise={
                                    handleChangeAreaOfIndustryExpertise
                                }
                                handleChangeTaxSpecialism={handleChangeTaxSpecialism}
                                listAreaOfIndustryExpertise={listAreaOfIndustryExpertise}
                                areaOfIndustryExpertise={areaOfIndustryExpertise}
                                listTaxSpecialism={listTaxSpecialism}
                                taxSpecialism={taxSpecialism}
                                handleOpen={handleOpen}
                                data={data}
                                action="Edit"
                                isArVis={false}
                                checkUserCountryWithFirmCountryActive={checkUserCountryWithFirmCountryActive}
                            />
                            <br/>
                            <br/>
                            <div className="ResetPassword" style={{display : "flex" , justifyContent : 'flex-end'}}>
{/*
                                <Button onClick={handleProfileDeletion} icon={<DeleteOutlined/>} hidden={userRight==="Global administrator"} size="large" type="danger" style={{marginRight : '25px'}}>Delete profile</Button>
*/}
                                <Button onClick={handleProfileDeletion} icon={<DeleteOutlined/>} hidden={userRight==="Global administrator"} size="large" type="danger" style={{marginRight : '25px'}} loading={deletingProfile}>Delete profile</Button>

                                <Button onClick={resetPassword} icon={<ReloadOutlined />} size="large" type="dashed">
                                    Reset password
                                    <Spin spinning={isLoading} indicator={loadingIcon}/>
                                </Button>
                                <Button
                                    onClick={handleSubmit}
                                    icon={<SaveOutlined />}
                                    disabled={
                                        !usernameVerified ||
                                        !isEnabled ||
                                        //isButtonDisabled ||
                                        (userRight!=="Global administrator" && currentUser?.approvalStatus === ApprovalStatus.Pending)
                                    }
                                    size="large"
                                    type="primary"
                                    style={{marginLeft : '25px'}}
                                    loading={working}
                                >
                                    Save
                                </Button>
                            </div>
                            <br/>
                        </div>
                        <Modal
                            visible={openNewArea}
                            title="Add new area"
                            onOk={() => {
                                addNewAreas(inputValue);
                            }}
                            onCancel={handleClose}
                            destroyOnClose
                            footer={[
                                <Button key="back" onClick={handleClose}>
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    type="primary"
                                    disabled={!canSubmitArea}
                                    onClick={() => {
                                        addNewAreas(inputValue);
                                    }}
                                >
                                    add
                                </Button>,
                            ]}
                        >
                            <Paper >
                                <form onSubmit={handleSubmitArea}>
                                    {/*Content*/}
                                    {/*Areas of industry Expertise*/}
                                    <div style={{ padding: "20px" }}>
                                        <TextField
                                            required
                                            style={{ width: "100%" }}
                                            id="value-input"
                                            onChange={handleClick}
                                            label="Areas of industry Expertise"
                                        />
                                    </div>
                                    <br />
                                </form>
                            </Paper>
                        </Modal>
                    </div>
                </form>
            </Skeleton>
        </>);
}

export default inject("authStore", "dataStore", "messageStore")(observer(Profile));
