import React, {useEffect, useState} from 'react';
import "firebase/auth"
import firebase from "firebase/app";
import logo from "../../../assets/logo.svg";
import {Button, Grid, Input, InputLabel, Paper} from "@material-ui/core";
import {ROUTES} from "../../../parameters/routes";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {Skeleton} from "antd";
import TooltipPassword from '../../core/Widgets/TooltipPassword';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 60,
            flexDirection: 'column',
            alignItems: 'center',
        },
        headerTitle: {
            textAlign: 'center',
            padding: '2rem 0',
            margin: '0',
            fontSize: '2rem',
        },
        logo: {
            width: 250,
            marginTop: 13,
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
        },
        paper: {
            paddingTop: 16,
            paddingBottom: 16,
            paddingRight: 25,
            paddingLeft: 25,
            marginTop: theme.spacing(3),
            maxWidth: '500px',
            minWidth: '300px',
            maxHeight: '700px',
            width: '80%',
            height: '60%',
            margin: '100px auto',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            boxShadow: '0px 0px 25px -4px rgba(150, 150, 150, 0.24)',
            border: '1px solid rgba(150, 150, 150, 0.18)',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        field: {
            marginTop: theme.spacing(2)
        },
        actions: {
            paddingTop: 5,
            paddingBottom: 16,
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
        },
        buttonCancel: {
            background: '#424766',
            height: '35px',
            width: '200px',
            color: "white",
        }
    }),
);

const PasswordResetForm: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [rightLoading , setRightLoading] = useState(true);
    const actionCode = getParameterByName('oobCode');
    const [email, setEmail] = useState<string | null>(null);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [passwordVal, setPasswordValidation] = useState({
        displayVal: "none",
        uppercase: false,
        lowercase: false,
        specialChar: false,
        numeral: false,
        minChar: false,
        maxChar: true,
        valid: false
    });

    useEffect(() => {
        const verifyCode = async () => {
            if (!actionCode) {
                setError('Invalid action code');
                return;
            }
            try {
                const info = await firebase.auth().verifyPasswordResetCode(actionCode);
                setEmail(info);
            } catch (error) {
                setError('Invalid or expired action code');
            } finally {
                setRightLoading(false);
            }
        };

        verifyCode();
    }, [actionCode]);

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        const isValidLength = password.length >= 8 && password.length <= 20;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

        setNewPassword(password);

        if (password.length === 0) {
            setIsValidPassword(true);
        } else {
            setIsValidPassword(isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar);
        }

        setPasswordValidation({
            displayVal: "block",
            uppercase: hasUppercase,
            lowercase: hasLowercase,
            specialChar: hasSpecialChar,
            numeral: hasNumber,
            minChar: isValidLength,
            maxChar: password.length <= 20 && password.length !== 0,
            valid: isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar
        });
    };

    const handleResetPassword = async () => {
        if (!actionCode) {
            setError('Invalid action code');
            return;
        }

        try {
            const auth = firebase.auth();
            await auth.confirmPasswordReset(actionCode, newPassword);

            // Call cloud function to update passwordUpdated
            if (email) {
                const result = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updatePasswordFlagFunction`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });

                if (result.ok)  {
                    history.push(ROUTES.login);
                    setSuccess(true);
                } else {
                    setError('Failed to update password flag.');
                }
            } else {
                setError('Email is not available.');
            }
        } catch (error) {
            // @ts-ignore
            setError('Error resetting password: ' + error.message);
        }
    };

    useEffect( () => {
        const checkExpiration = async () => {
            const auth = firebase.auth();
            try {
                if (actionCode != null) {
                    const actionCodeInfo = await auth.checkActionCode(actionCode);

                    if (actionCodeInfo) { // Check if actionCodeInfo is not null or undefined
                        // @ts-ignore
                        const expirationTime = actionCodeInfo.data.expirationTime;
                        const currentTime = Date.now();
                        if (expirationTime < currentTime) {
                            setSuccess(true);
                        }

                        setRightLoading(false);
                    } else {
                        // Handle the case where actionCodeInfo is null or undefined
                        setSuccess(true);
                        setError('Invalid action code or no information returned.');
                    }
                }
            } catch (error) {
                // @ts-ignore
                setError('Error checking expiration:', error);
                // @ts-ignore
                if (error.code === 'auth/invalid-action-code') {
                    //The password reset link is invalid or has expired
                    setSuccess(true);
                    setRightLoading(false);
                } else {
                    //An error occurred while checking the password reset link
                    setSuccess(true);
                    setRightLoading(false);
                }
            }
        };

        checkExpiration().then();
    }, [actionCode]);

    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} alt="Logo"/>
            <Paper className={classes.paper}>
                <h2 className={classes.headerTitle}>{'Reset Password'}</h2>
                <Skeleton  loading={rightLoading} active>
                    {!success ? (
                        <>
                            <div className={"messageContainer"}>
                                <InputLabel style={{lineHeight:"0"}} htmlFor="password">New password</InputLabel>
                                <Input
                                    id="password"
                                    required
                                    type="password"
                                    inputProps={{ style: { color: "rgb(104, 29, 73 )" } }}
                                    value={newPassword}
                                    onChange={handlePasswordChange}
                                    style={{
                                        marginTop: "10px",
                                        borderColor: isValidPassword ? "" : "red",
                                    }}
                                />
                                {!isValidPassword && (
                                    <div id="message" style={{ bottom: "-85px" }}>
                                        <TooltipPassword displayVal={passwordVal.displayVal} lowercase={passwordVal.lowercase} uppercase={passwordVal.uppercase} maxChar={passwordVal.maxChar} minChar={passwordVal.minChar} numeral={passwordVal.numeral} specialChar={passwordVal.specialChar} />
                                    </div>
                                )}
                                <div className={classes.actions}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                className="buttonCancel"
                                                onClick={() => history.push(ROUTES.login)}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                                onClick={handleResetPassword}
                                                disabled={newPassword === '' || !isValidPassword}
                                                className="buttonUpdate" >
                                                    Update Password
                                            </Button>
                                            {error && <p>{error}</p>}
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p>Your request to reset your password has expired or the link has already been used.</p>
                    )}
                </Skeleton>
            </Paper>
        </div>
    );
};

export default PasswordResetForm;

// Function to get URL parameters by name
function getParameterByName(name: string, url?: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\\[\\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\\+/g, ' '));
}
