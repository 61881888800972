import {submitWorkerType} from "../../Types/Login/LoginType";
import {ROUTES} from "../../parameters/routes";
import {notification} from "antd";
import messageStore from "../../stores/messageStore";
import authStore from "../../stores/authStore";


const parseJwt = (token: any) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};


export const submitWorker = async ({
                                       e,
                                       canSubmit,
                                       data,
                                       login,
                                       setData,
                                       t,
                                       history,
                                       setLoginPending
                                   }: submitWorkerType) => {
    e.preventDefault();
    if (!canSubmit) {
        return;
    }

    const firebaseFunctionUrl = process.env.REACT_APP_CLOUD_FUNCTIONS + '/wordpressAuth';

    if (data.email && data.password) {
        try {
            setLoginPending(true);
            const response = await login(data.email, data.password);

            if (response.user) {
                history.push(ROUTES.news);

                const fetchToken = async () => {
                    const responseFetch = await fetch(firebaseFunctionUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({})
                    });

                    if (!responseFetch.ok) {
                        throw new Error('Error calling httpsWordpressAuth function: ' + responseFetch.statusText);
                    }

                    const responseData = await responseFetch.json();
                    const token = responseData.token;
                    localStorage.setItem('wordpressToken', token);
                    localStorage.setItem('wordpress/expirationToken', parseJwt(token).exp);
                    return token;
                };

                const manageTokenExpiration = async () => {
                    const token = localStorage.getItem('wordpressToken');
                    const expirationTime = new Date(parseJwt(token).exp * 1000);
                    const timeUntilExpiration = expirationTime.getTime() - Date.now();

                    if (timeUntilExpiration <= 0) {
                        try {
                            const newToken = await fetchToken();
                            // Recalculate expiration time for new token
                            const newExpirationTime = new Date(parseJwt(newToken).exp * 1000);
                            const newTimeUntilExpiration = newExpirationTime.getTime() - Date.now();

                            setTimeout(manageTokenExpiration, newTimeUntilExpiration - 1000);
                        } catch (error) {
                            authStore.logout();
                            messageStore.snackbar({
                                message: 'Session expired. Please log in again.',
                                type: 'error',
                            });
                        }
                    } else {
                        setTimeout(manageTokenExpiration, timeUntilExpiration - 1000);
                    }
                };

                await fetchToken();
                await manageTokenExpiration();

                setLoginPending(false);
            }
        } catch (error: any) {
                notification["error"]({
                    message: "Authentication failed",
                    description: `${t(error.message)}`,
                });
                setLoginPending(false);
                setData({email: "", password: ""});
            }
    }
};


