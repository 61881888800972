import React, {FormEvent, useCallback, useMemo, useState} from 'react';
import {Button} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import messageStore from '../../../stores/messageStore';
import {useTranslation} from 'react-i18next';
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import logo from "../../../assets/logo.svg";
import Grid from "@material-ui/core/Grid";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {ROUTES} from "../../../parameters/routes";
import authStore from "../../../stores/authStore";
//import {notification} from "antd";




const ForgotPassword = () => {

    const {t} = useTranslation();
    const history = useHistory();
    const [data, setData] = useState({email: ''});
    const canSubmit = useMemo(() => !!data.email, [data]);


    const handleDataChange = useCallback(
        ({currentTarget}: React.ChangeEvent<HTMLInputElement>) => {
            setData((d) => ({...d, [currentTarget.id]: currentTarget.value}));
        },
        []
    );

    const handleSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!canSubmit) {
                return;
            }
            var actionCodeSettings = {
                // After password reset, the user will be give the ability to go back
                // to this page.
                url: process.env.REACT_APP_EMAIL_API as any,
                handleCodeInApp: false
            };
            if (data.email) {
                try {
                    await firebase.auth().sendPasswordResetEmail(data.email,actionCodeSettings)
                        .then(function () {
                            setTimeout(()=>authStore.logout(),3000);
                            messageStore.snackbar({
                                message: t("If an account exists, an e-mail with a link to reset your password has been sent"),
                                type: "success",
                            });
                            history.push(ROUTES.login);
                        })
                        .catch((err) => {
                            messageStore.snackbar({
                                message: `${t(err.message)}`,
                                type: 'error',
                            });
                            setData({email: ''});
                        });
                } catch (error) {
                    messageStore.snackbar({
                        message: `${t('An error occurred')}`,
                        type: 'error',
                    });
                    setData({email: ''});
                }
            }
        },
        [canSubmit, data, history, t]
    );


    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            container: {
                display: 'flex',
                justifyContent: 'center',
                marginTop: 60,
                flexDirection: 'column',
                alignItems: 'center',
            },
            headerTitle: {
                textAlign: 'center',
                padding: '2rem 0',
                margin: '0',
                fontSize: '2rem',
            },
            logo: {
                width: 250,
                marginTop: 13,
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
            },
            paper:{
                paddingTop: 16,
                paddingBottom: 16,
                paddingRight: 25,
                paddingLeft: 25,
                marginTop: theme.spacing(3),
                maxWidth: '500px',
                minWidth: '300px',
                maxHeight: '700px',
                width: '80%',
                height: '60%',
                margin: '100px auto',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                boxShadow: '0px 0px 25px -4px rgba(150, 150, 150, 0.24)',
                border: '1px solid rgba(150, 150, 150, 0.18)',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
            field: {
                marginTop: theme.spacing(3)
            },
            actions:{
                paddingTop: 16,
                paddingBottom: 16,
                marginTop: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
            },
            button: {
                marginRight: theme.spacing(3),
                height: '35px',
                width: '200px',
            },
        }),
    );
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} alt="Logo"/>
            <Paper className={classes.paper}>
                <h2 className={classes.headerTitle}>{'Forgotten Password'}</h2>
                <form onSubmit={handleSubmit}>
                    <FormControl required={true} className={'w-100'}>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input
                            value={data.email}
                            onChange={handleDataChange}
                            id="email"
                            required
                            inputProps={{style: {color: "rgb(104, 29, 73 )"}}}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Icon>email</Icon>
                                </InputAdornment>}
                        />
                    </FormControl>
                    <div className={classes.actions}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() => history.push(ROUTES.login)}
                                >
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    disabled={!canSubmit}
                                    className={classes.button}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>


                </form>
            </Paper>
        </div>
    );
};

export default inject('messageStore')(observer(ForgotPassword));
