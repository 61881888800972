import { notification } from 'antd';

export const sendEmailCredentials = async (
    endpoint: string,
    token: string,
    data: { email: string; password: string; firstName: string }
) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                user: data.email,
                pass: data.password,
                receiver: data.firstName,
            }),
        });

        if (response.ok) {
            notification.success({
                message: 'Success',
                description: 'Email was sent successfully',
            });
        } else {
            const errorResponse = await response.text();
            new Error(`Server response error: ${errorResponse}`);
        }
    } catch (error: any) {
        console.error('Error sending email credentials:', error);
        notification.error({
            message: 'Error',
            description: `Failed to send emails. ${error.message}`,
        });
    }
};


export const sendEmailRequestFolder = async (
    endpoint: string,
    token: string,
    data: { email: string; full_name: string; folder_name: string }
) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                email: data.email,
                full_name: data.full_name,
                folder_name: data.folder_name,
            }),
        });

        if (response.ok) {
            notification.success({
                message: 'Success',
                description: 'Email was sent successfully',
            });
        } else {
            const errorResponse = await response.text();
            new Error(`Server response error: ${errorResponse}`);
        }
    } catch (error: any) {
        console.error('Error sending email credentials:', error);
        notification.error({
            message: 'Error',
            description: `Failed to send emails. ${error.message}`,
        });
    }
};