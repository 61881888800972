import React, {useState, useEffect, useCallback} from 'react';
import './Management.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import {useTranslation} from "react-i18next";
import {Card, notification, Input, Button, Row, Col, List, Typography, Pagination, Divider, Modal, Alert} from "antd";
import {PlusOutlined, CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import firebase from "firebase/app";
import {areaOfIndustryExpertiseRef, countryRef, gradeRef, taxSpecialismRef} from "../../../services/firebase/firebase";

// @ts-ignore
const CountryListComponent = ({countries, editCountryName}) => {
    const [editingCountry, setEditingCountry] = useState(null);
    const [newName, setNewName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filterCountry, setFilterCountry] = useState('');
    const [visible, setVisible] = useState(false);
    const [countryToDelete, setCountryToDelete] = useState('');
    const [EditCountryLoading, setEditCountryLoading] = useState(false);

    // Filter and paginate data
    const filteredCountries = countries.filter((country: string) =>
        country.toLowerCase().includes(filterCountry.toLowerCase())
    );

    // Paginate data
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedCountries = filteredCountries.slice(startIndex, endIndex);

    // Split array into two columns
    const splitArrayIntoTwo = (arr: string[]) => {
        const mid = Math.ceil(arr.length / 2);
        return [arr.slice(0, mid), arr.slice(mid)];
    };

    const [column1, column2] = splitArrayIntoTwo(paginatedCountries);


    const handleEditClick = (country: string) => {
        // @ts-ignore
        setEditingCountry(country);
        setNewName(country); // Assuming `country` is the name of the country
    };

    const handleApprove = async (country: never, newName: string) => {
        // Check if the new grade already exists in the current grades list
        if (countries.includes(newName) && country !== newName) {
            notification['warning']({
                message: 'Warning',
                description: 'This country already exists. Please enter a different country.',
            });
            return;
        }
        setEditCountryLoading(true);
        try {
            await editCountryName(country, newName);
        } catch (e) {
            console.error("Error while approving the country name change:", e);
            notification['error']({
                message: 'Error',
                description: 'Failed to update the country name. Please try again.',
            });
        } finally {
            setEditCountryLoading(false);
            setEditingCountry(null); // Close the input after editing
        }
    };

    const handleCancel = () => {
        setEditingCountry(null); // Close the input without saving
    };

    const handlePageChange = (page: React.SetStateAction<number>, pageSize: any) => {
        setCurrentPage(page);
        setPageSize(pageSize || 10);
    };

    const handleCancelModelDeleteCountry = () => {
        setVisible(false);
        setCountryToDelete('');
    };

    const showModalDeleteCountry = (countryName: string) => {
        setCountryToDelete(countryName);
        setVisible(true);
    };

    interface Post {
        id: number;
        title: {
            rendered: string;
        };
    }

    const handleConfirmDeleteCountry = async () => {
        setVisible(false);
        if (countryToDelete !== "") {
            try {
                // Check if the country exists in WordPress
                const allPostsOurLocationsResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/posts?categories=9&per_page=100`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                    },
                });

                let postId;
                if (allPostsOurLocationsResponse.ok) {
                    const allPostsData = await allPostsOurLocationsResponse.json();
                    const existingPost = allPostsData.find((post: Post) => post.title.rendered === countryToDelete);
                    if (existingPost) {
                        // If the country exists in WordPress, use the existing ID
                        postId = existingPost.id;
                    } else {
                        notification['error']({
                            message: 'Error',
                            description: `Country "${countryToDelete}" does not exist.`
                        });
                        return;
                    }
                } else {
                    notification['error']({message: 'Error', description: 'Failed to fetch posts from WordPress.'});
                    return;
                }

                if (postId) {
                    // Delete the post from WordPress
                    const deleteResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/posts/${postId}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                        }
                    });

                    if (deleteResponse.status === 403) {
                        notification['error']({
                            message: 'Error',
                            description: 'You do not have permission to delete posts.'
                        });
                        return;
                    }

                    if (deleteResponse.status !== 200) {
                        const deleteData = await deleteResponse.json();
                        notification['error']({message: 'Error', description: deleteData.message});
                        return;
                    }

                    // Use the ID from the WordPress post to delete Country Firebase
                    const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteCountryFunction`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("firebaseToken")} `,
                        },
                        body: JSON.stringify({
                            id: postId,
                        })
                    });

                    const responseData = await response.json();
                    if (response.status !== 200) {
                        notification['error']({message: 'Error', description: responseData.error});
                        return;
                    }

                    // Remove country reference from the firm in Firebase
                    const firmsRef = firebase.database().ref('firm');
                    const snapshot = await firmsRef.once('value');

                    if (snapshot.exists()) {
                        const firmsData = snapshot.val();
                        let firmIdToUpdate = null;

                        // Find the firm with the matching country name
                        for (const [id, firm] of Object.entries(firmsData)) {
                            // @ts-ignore
                            if (firm.country === countryToDelete) {
                                firmIdToUpdate = id;
                                break;
                            }
                        }

                        if (firmIdToUpdate) {
                            // Update the firm by removing the country reference
                            await firebase.database().ref('firm/' + firmIdToUpdate).update({country: null});
                            notification['success']({
                                message: 'Success',
                                description: 'Country reference successfully removed from the firm'
                            });
                        } else {
                            notification['error']({
                                message: 'Error',
                                description: `Firm with country "${countryToDelete}" does not exist.`
                            });
                        }
                    } else {
                        notification['error']({message: 'Error', description: 'No firms found.'});
                    }

                    // Delete users with the specified country reference
                    const usersRef = firebase.database().ref('users');
                    const usersSnapshot = await usersRef.once('value');
                    const users = usersSnapshot.val();

                    if (users) {
                        const deletePromises = [];
                        for (const uid in users) {
                            if (users[uid].country === countryToDelete) {
                                // Delete user from Firebase
                                deletePromises.push(firebase.database().ref(`users/${uid}`).remove());
                            }
                        }

                        // Execute all delete operations
                        await Promise.all(deletePromises);

                        notification['success']({
                            message: 'Success',
                            description: `All users with country "${countryToDelete}" have been successfully deleted`
                        });
                    } else {
                        notification['error']({message: 'Error', description: 'No users found.'});
                    }

                    // Fetch countries again if needed
                    //await fetchCountries();
                }
            } catch (error) {
                notification['error']({message: 'Error', description: 'Failed to delete the country'});
            }
        }
    };

    const renderActionsCountry = (item: string) => {
        return editingCountry === item ? (
            <Row gutter={5} align="middle" justify="center">
                <Col>
                    <Button
                        type="default"
                        icon={<CheckOutlined/>}
                        onClick={() => handleApprove(item, newName)}
                        loading={EditCountryLoading}
                    />
                </Col>
                <Col>
                    <Button
                        type="default"
                        icon={<CloseOutlined/>}
                        onClick={handleCancel}
                    />
                </Col>
            </Row>
        ) : (
            <Row gutter={5} align="middle" justify="center">
                <Col>
                    <Button
                        type="default"
                        icon={<EditOutlined/>}
                        onClick={() => handleEditClick(item)}
                    />
                </Col>
                <Col>
                    <Button
                        type="default"
                        icon={<DeleteOutlined/>}
                        onClick={() => showModalDeleteCountry(item)}
                    />
                    <Modal
                        title="Confirm Deletion"
                        visible={visible}
                        onOk={handleConfirmDeleteCountry}
                        onCancel={handleCancelModelDeleteCountry}
                        okText="Confirm"
                        cancelText="Cancel"
                        maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0.3)', opacity: '0.5'}}
                    >
                        <p>Are you sure you want to delete the country and all associated data?</p>
                        <Alert style={{ marginTop: 20 }}
                               message="Warning"
                               description="Deleting this country will also remove all associated users, both in the Hub and on the website. Are you sure you want to proceed?"
                               type="error"
                               showIcon
                        />
                    </Modal>
                </Col>
            </Row>
        );
    };

    const renderTitleCountry = (item: string) => {
        return editingCountry === item ? (
            <Input
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onPressEnter={() => handleApprove(item, newName)}
                autoFocus
            />
        ) : (
            <span>{item}</span>
        );
    };

    return (
        <>
            <Input
                placeholder="Filter countries"
                value={filterCountry}
                onChange={(e) => setFilterCountry(e.target.value)}
                style={{marginBottom: 16}}
            />
            {filteredCountries.length === 0 || filteredCountries.length === 1 ? (
                <List
                    itemLayout="horizontal"
                    dataSource={column1}
                    renderItem={(item: string) => (
                        <List.Item actions={[renderActionsCountry(item)]}>
                            <List.Item.Meta
                                title={renderTitleCountry(item)}
                            />
                        </List.Item>
                    )}
                />
            ) : (
                <>
                    <Row gutter={16}>
                        <Col span={11}>
                            <List
                                itemLayout="horizontal"
                                dataSource={column1}
                                renderItem={(item: string) => (
                                    <List.Item actions={[renderActionsCountry(item)]}>
                                        <List.Item.Meta
                                            title={renderTitleCountry(item)}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col span={2}>
                            <Divider type="vertical"/>
                        </Col>
                        <Col span={11}>
                            <List
                                itemLayout="horizontal"
                                dataSource={column2}
                                renderItem={(item: string) => (
                                    <List.Item actions={[renderActionsCountry(item)]}>
                                        <List.Item.Meta
                                            title={renderTitleCountry(item)}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </>
            )}
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredCountries.length}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageChange}
                pageSizeOptions={['10', '20', '50', '100']}
            />
        </>
    );
};

// @ts-ignore
const GradeListComponent = ({grades, editGradeName, deleteGrade}) => {
    const [editingGrade, setEditingGrade] = useState(null);
    const [newGrade, setNewGrade] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filterGrade, setFilterGrade] = useState('');
    const [visible, setVisible] = useState(false);
    const [gradeToDelete, setGradeToDelete] = useState('');
    const [loadingGradeToDelete, setLoadingGradeToDelete] = useState('');
    const [EditGradeLoading, setEditGradeLoading] = useState(false);

    // Filter and paginate data
    const filteredGrades = grades.filter((grade: string) =>
        grade.toLowerCase().includes(filterGrade.toLowerCase())
    );

    // Paginate data
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedGrades = filteredGrades.slice(startIndex, endIndex);

    // Split the array into two columns
    const splitArrayIntoTwo = (arr: string[]) => {
        const mid = Math.ceil(arr.length / 2);
        return [arr.slice(0, mid), arr.slice(mid)];
    };

    const [column1, column2] = splitArrayIntoTwo(paginatedGrades);

    const handlePageChange = (page: React.SetStateAction<number>, pageSize: any) => {
        setCurrentPage(page);
        setPageSize(pageSize || 10);
    };

    const handleEditClick = (grade: any) => {
        setEditingGrade(grade);
        setNewGrade(grade);
    };

    const handleApproveGrade = async (grade: string, newGrade: string) => {
        // Check if the new grade already exists in the current grades list
        if (grades.includes(newGrade) && grade !== newGrade) {
            notification['warning']({
                message: 'Warning',
                description: 'This grade already exists. Please enter a different grade.',
            });
            return;
        }
        setEditGradeLoading(true);
        try {
            // Call the function to update the grade (API/Firebase)
            await editGradeName(grade, newGrade);
            setEditingGrade(null); // Close the input after success
        } catch (e) {
            // Error handling and notification if something goes wrong
            notification['error']({
                message: 'Error',
                description: 'Failed to update the grade. Please try again.',
            });
        } finally {
            setEditGradeLoading(false);
        }
    };

    // Close the input without saving
    const handleCancel = () => {
        setEditingGrade(null);
    };

    // Define the renderActionsGrade function here
    const renderActionsGrade = (item: string) => {
        return editingGrade === item ? (
            <Row gutter={5} align="middle" justify="center">
                <Col>
                    <Button
                        type="default"
                        icon={<CheckOutlined/>}
                        onClick={() => handleApproveGrade(item, newGrade)}
                        loading={EditGradeLoading}
                    />
                </Col>
                <Col>
                    <Button
                        type="default"
                        icon={<CloseOutlined/>}
                        onClick={handleCancel}
                    />
                </Col>
            </Row>
        ) : (
            <Row gutter={5} align="middle" justify="center">
                <Col>
                    <Button
                        type="default"
                        icon={<EditOutlined/>}
                        onClick={() => handleEditClick(item)}
                    />
                </Col>
                <Col>
                    <Button
                        type="default"
                        icon={<DeleteOutlined/>}
                        onClick={() => handleDeleteClick(item)}
                        loading={loadingGradeToDelete === item} // Show loader only for the grade being deleted
                    />
                </Col>
            </Row>
        );
    };

    const handleDeleteClick = (grade: string) => {
        setGradeToDelete(grade);
        setVisible(true);
    };

    const handleConfirmDelete = async () => {
        setLoadingGradeToDelete(gradeToDelete); // Set the loader for the specific grade being deleted
        try {
            setVisible(false);
            await deleteGrade(gradeToDelete);
            notification['success']({
                message: 'Success',
                description: 'The grade was successfully deleted, and users associated with this grade no longer have this value.',
            });
        } catch (error) {
            notification['error']({
                message: 'Error',
                description: 'Failed to delete the grade. Please try again.',
            });
        } finally {
            setLoadingGradeToDelete(''); // Clear the loader after the delete process
        }
    };

    const handleCancelDelete = () => {
        setVisible(false);
    };

    return (
        <>
            {/* Input for filtering the grades */}
            <Input
                placeholder="Filter grades"
                value={filterGrade}
                onChange={(e) => setFilterGrade(e.target.value)}
                style={{marginBottom: 16}}
            />
            <Row gutter={16}>
                <Col span={11}>
                    <List
                        itemLayout="horizontal"
                        dataSource={column1}
                        renderItem={(item) => (
                            <List.Item actions={[renderActionsGrade(item)]}>
                                <List.Item.Meta
                                    title={editingGrade === item ? (
                                        <Input
                                            value={newGrade}
                                            onChange={(e) => setNewGrade(e.target.value)}
                                            onPressEnter={() => handleApproveGrade(item, newGrade)}  // Handle edit approval
                                        />
                                    ) : (
                                        <span>{item}</span>
                                    )}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={2}>
                    <Divider type="vertical"/>
                </Col>
                <Col span={11}>
                    <List
                        itemLayout="horizontal"
                        dataSource={column2}
                        renderItem={(item) => (
                            <List.Item actions={[renderActionsGrade(item)]}>
                                <List.Item.Meta
                                    title={editingGrade === item ? (
                                        <Input
                                            value={newGrade}
                                            onChange={(e) => setNewGrade(e.target.value)}
                                            onPressEnter={() => handleApproveGrade(item, newGrade)}
                                        />
                                    ) : (
                                        <span>{item}</span>
                                    )}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredGrades.length}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageChange}
                pageSizeOptions={['10', '20', '50', '100']}
            />
            <Modal
                title="Confirm Deletion"
                visible={visible}
                onOk={handleConfirmDelete}
                onCancel={handleCancelDelete}
                okText="Confirm"
                cancelText="Cancel"
                confirmLoading={loadingGradeToDelete === gradeToDelete} // Apply loader only for the current grade being deleted
            >
                <p>Are you sure you want to delete the grade? Users who have this value will no longer be associated
                    with it.</p>
            </Modal>
        </>
    );
};

// @ts-ignore
const AreaOfIndustryExpertiseListComponent = ({expertiseAreas, editExpertiseAreaName, deleteExpertiseArea}) => {
    const [editingExpertiseArea, setEditingExpertiseArea] = useState(null);
    const [newExpertiseArea, setNewExpertiseArea] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filterExpertiseArea, setFilterExpertiseArea] = useState('');
    const [visible, setVisible] = useState(false);
    const [expertiseAreaToDelete, setExpertiseAreaToDelete] = useState('');
    const [loadingExpertiseAreaToDelete, setLoadingExpertiseAreaToDelete] = useState('');
    const [EditExpertiseAreaLoading, setEditExpertiseAreaLoading] = useState(false);

    // Filter and paginate data
    const filteredExpertiseAreas = expertiseAreas.filter((area: string) =>
        area.toLowerCase().includes(filterExpertiseArea.toLowerCase())
    );

    // Paginate data
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedExpertiseAreas = filteredExpertiseAreas.slice(startIndex, endIndex);

    // Split the array into two columns
    const splitArrayIntoTwo = (arr: string[]) => {
        const mid = Math.ceil(arr.length / 2);
        return [arr.slice(0, mid), arr.slice(mid)];
    };

    const [column1, column2] = splitArrayIntoTwo(paginatedExpertiseAreas);

    const handlePageChange = (page: React.SetStateAction<number>, pageSize: any) => {
        setCurrentPage(page);
        setPageSize(pageSize || 10);
    };

    const handleEditClick = (expertiseArea: any) => {
        setEditingExpertiseArea(expertiseArea);
        setNewExpertiseArea(expertiseArea);
    };

    const handleApproveExpertiseArea = async (expertiseArea: string, newExpertiseArea: string) => {
        if (expertiseAreas.includes(newExpertiseArea) && expertiseArea !== newExpertiseArea) {
            notification['warning']({
                message: 'Warning',
                description: 'This area of expertise already exists. Please enter a different name.',
            });
            return;
        }
        setEditExpertiseAreaLoading(true);
        try {
            await editExpertiseAreaName(expertiseArea, newExpertiseArea);
            setEditingExpertiseArea(null);
        } catch (e) {
            notification['error']({
                message: 'Error',
                description: 'Failed to update the area of expertise. Please try again.',
            });
        } finally {
            setEditExpertiseAreaLoading(false);
        }
    };

    const handleCancel = () => {
        setEditingExpertiseArea(null);
    };

    const handleDeleteClick = (expertiseArea: string) => {
        setExpertiseAreaToDelete(expertiseArea);
        setVisible(true);
    };

    const handleConfirmDelete = async () => {
        setLoadingExpertiseAreaToDelete(expertiseAreaToDelete);
        try {
            setVisible(false);
            await deleteExpertiseArea(expertiseAreaToDelete);
        } catch (error) {
            notification['error']({
                message: 'Error',
                description: 'Failed to delete the area of expertise. Please try again.',
            });
        } finally {
            setLoadingExpertiseAreaToDelete('');
        }
    };

    const handleCancelDelete = () => {
        setVisible(false);
    };

    const renderActionsExpertiseArea = (item: string) => {
        return editingExpertiseArea === item ? (
            <Row gutter={5} align="middle" justify="center">
                <Col>
                    <Button
                        type="default"
                        icon={<CheckOutlined/>}
                        onClick={() => handleApproveExpertiseArea(item, newExpertiseArea)}
                        loading={EditExpertiseAreaLoading}
                    />
                </Col>
                <Col>
                    <Button
                        type="default"
                        icon={<CloseOutlined/>}
                        onClick={handleCancel}
                    />
                </Col>
            </Row>
        ) : (
            <Row gutter={5} align="middle" justify="center">
                <Col>
                    <Button
                        type="default"
                        icon={<EditOutlined/>}
                        onClick={() => handleEditClick(item)}
                    />
                </Col>
                <Col>
                    <Button
                        type="default"
                        icon={<DeleteOutlined/>}
                        onClick={() => handleDeleteClick(item)}
                        loading={loadingExpertiseAreaToDelete === item}
                    />
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Input
                placeholder="Filter areas of expertise"
                value={filterExpertiseArea}
                onChange={(e) => setFilterExpertiseArea(e.target.value)}
                style={{marginBottom: 16}}
            />
            <Row gutter={16}>
                <Col span={11}>
                    <List
                        itemLayout="horizontal"
                        dataSource={column1}
                        renderItem={(item) => (
                            <List.Item actions={[renderActionsExpertiseArea(item)]}>
                                <List.Item.Meta
                                    title={editingExpertiseArea === item ? (
                                        <Input
                                            value={newExpertiseArea}
                                            onChange={(e) => setNewExpertiseArea(e.target.value)}
                                            onPressEnter={() => handleApproveExpertiseArea(item, newExpertiseArea)}
                                        />
                                    ) : (
                                        <span>{item}</span>
                                    )}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={2}>
                    <Divider type="vertical"/>
                </Col>
                <Col span={11}>
                    <List
                        itemLayout="horizontal"
                        dataSource={column2}
                        renderItem={(item) => (
                            <List.Item actions={[renderActionsExpertiseArea(item)]}>
                                <List.Item.Meta
                                    title={editingExpertiseArea === item ? (
                                        <Input
                                            value={newExpertiseArea}
                                            onChange={(e) => setNewExpertiseArea(e.target.value)}
                                            onPressEnter={() => handleApproveExpertiseArea(item, newExpertiseArea)}
                                        />
                                    ) : (
                                        <span>{item}</span>
                                    )}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredExpertiseAreas.length}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageChange}
                pageSizeOptions={['10', '20', '50', '100']}
            />
            <Modal
                title="Confirm Deletion"
                visible={visible}
                onOk={handleConfirmDelete}
                onCancel={handleCancelDelete}
                okText="Confirm"
                cancelText="Cancel"
                confirmLoading={loadingExpertiseAreaToDelete === expertiseAreaToDelete}
            >
                <p>Are you sure you want to delete the area of expertise? Users who have this value will no longer be associated
                    with it.</p>
            </Modal>
        </>
    );
};

// @ts-ignore
const TaxSpecialismListComponent = ({ taxSpecialisms, editTaxSpecialismName, deleteTaxSpecialism }) => {
    const [editingTaxSpecialism, setEditingTaxSpecialism] = useState(null);
    const [newTaxSpecialism, setNewTaxSpecialism] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filterTaxSpecialism, setFilterTaxSpecialism] = useState('');
    const [visible, setVisible] = useState(false);
    const [taxSpecialismToDelete, setTaxSpecialismToDelete] = useState('');
    const [loadingTaxSpecialismToDelete, setLoadingTaxSpecialismToDelete] = useState('');
    const [editTaxSpecialismLoading, setEditTaxSpecialismLoading] = useState(false);

    // Filter and paginate data
    const filteredTaxSpecialisms = taxSpecialisms.filter((specialism: string) =>
        specialism.toLowerCase().includes(filterTaxSpecialism.toLowerCase())
    );

    // Data pagination
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedTaxSpecialisms = filteredTaxSpecialisms.slice(startIndex, endIndex);

    // Divide the table into two columns
    const splitArrayIntoTwo = (arr: string[]) => {
        const mid = Math.ceil(arr.length / 2);
        return [arr.slice(0, mid), arr.slice(mid)];
    };

    const [column1, column2] = splitArrayIntoTwo(paginatedTaxSpecialisms);

    const handlePageChange = (page: React.SetStateAction<number>, pageSize: any) => {
        setCurrentPage(page);
        setPageSize(pageSize || 10);
    };

    const handleEditClick = (taxSpecialism: any) => {
        setEditingTaxSpecialism(taxSpecialism);
        setNewTaxSpecialism(taxSpecialism);
    };

    const handleApproveTaxSpecialism = async (taxSpecialism: string, newTaxSpecialism: string) => {
        if (taxSpecialisms.includes(newTaxSpecialism) && taxSpecialism !== newTaxSpecialism) {
            notification['warning']({
                message: 'Warning',
                description: 'This tax specialism already exists. Please enter a different name.',
            });
            return;
        }
        setEditTaxSpecialismLoading(true);
        try {
            await editTaxSpecialismName(taxSpecialism, newTaxSpecialism);
            setEditingTaxSpecialism(null);
        } catch (e) {
            notification['error']({
                message: 'Error',
                description: 'Failed to update the tax specialism. Please try again.',
            });
        } finally {
            setEditTaxSpecialismLoading(false);
        }
    };

    const handleCancel = () => {
        setEditingTaxSpecialism(null);
    };

    const handleDeleteClick = (taxSpecialism: string) => {
        setTaxSpecialismToDelete(taxSpecialism);
        setVisible(true);
    };

    const handleConfirmDelete = async () => {
        setLoadingTaxSpecialismToDelete(taxSpecialismToDelete);
        try {
            setVisible(false);
            await deleteTaxSpecialism(taxSpecialismToDelete);
        } catch (error) {
            notification['error']({
                message: 'Error',
                description: 'Failed to delete the tax specialism. Please try again.',
            });
        } finally {
            setLoadingTaxSpecialismToDelete('');
        }
    };

    const handleCancelDelete = () => {
        setVisible(false);
    };

    const renderActionsTaxSpecialism = (item: string) => {
        return editingTaxSpecialism === item ? (
            <Row gutter={5} align="middle" justify="center">
                <Col>
                    <Button
                        type="default"
                        icon={<CheckOutlined />}
                        onClick={() => handleApproveTaxSpecialism(item, newTaxSpecialism)}
                        loading={editTaxSpecialismLoading}
                    />
                </Col>
                <Col>
                    <Button
                        type="default"
                        icon={<CloseOutlined />}
                        onClick={handleCancel}
                    />
                </Col>
            </Row>
        ) : (
            <Row gutter={5} align="middle" justify="center">
                <Col>
                    <Button
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => handleEditClick(item)}
                    />
                </Col>
                <Col>
                    <Button
                        type="default"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDeleteClick(item)}
                        loading={loadingTaxSpecialismToDelete === item}
                    />
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Input
                placeholder="Filter tax specialisms"
                value={filterTaxSpecialism}
                onChange={(e) => setFilterTaxSpecialism(e.target.value)}
                style={{ marginBottom: 16 }}
            />
            <Row gutter={16}>
                <Col span={11}>
                    <List
                        itemLayout="horizontal"
                        dataSource={column1}
                        renderItem={(item) => (
                            <List.Item actions={[renderActionsTaxSpecialism(item)]}>
                                <List.Item.Meta
                                    title={editingTaxSpecialism === item ? (
                                        <Input
                                            value={newTaxSpecialism}
                                            onChange={(e) => setNewTaxSpecialism(e.target.value)}
                                            onPressEnter={() => handleApproveTaxSpecialism(item, newTaxSpecialism)}
                                        />
                                    ) : (
                                        <span>{item}</span>
                                    )}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={2}>
                    <Divider type="vertical" />
                </Col>
                <Col span={11}>
                    <List
                        itemLayout="horizontal"
                        dataSource={column2}
                        renderItem={(item) => (
                            <List.Item actions={[renderActionsTaxSpecialism(item)]}>
                                <List.Item.Meta
                                    title={editingTaxSpecialism === item ? (
                                        <Input
                                            value={newTaxSpecialism}
                                            onChange={(e) => setNewTaxSpecialism(e.target.value)}
                                            onPressEnter={() => handleApproveTaxSpecialism(item, newTaxSpecialism)}
                                        />
                                    ) : (
                                        <span>{item}</span>
                                    )}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredTaxSpecialisms.length}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageChange}
                pageSizeOptions={['10', '20', '50', '100']}
            />
            <Modal
                title="Confirm Deletion"
                visible={visible}
                onOk={handleConfirmDelete}
                onCancel={handleCancelDelete}
                okText="Confirm"
                cancelText="Cancel"
                confirmLoading={loadingTaxSpecialismToDelete === taxSpecialismToDelete}
            >
                <p>Are you sure you want to delete the tax specialism? Users who have this value will no longer be associated
                    with it.</p>
            </Modal>
        </>
    );
};

const Management = () => {
    const [inputValueAddCountry, setInputValueAddCountry] = useState<string>('');
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [token] = useState(''); // State hook for Firebase authentication token
    const [countries, setCountries] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [addCountryLoading, setAddCountryLoading] = useState(false);
    const [grades, setGrades] = useState<string[]>([]);
    const [AddGradeLoading, setAddGradeLoading] = useState(false);
    const [inputValueAddGrade, setInputValueAddGrade] = useState<string>('');
    const [isVisibleGrade, setIsVisibleGrade] = useState(false);
    const [addExpertiseAreaLoading, setAddExpertiseAreaLoading] = useState(false);
    const [addTaxSpecialismLoading, setAddTaxSpecialismLoading] = useState(false);
    const [inputValueAddExpertiseArea, setInputValueAddExpertiseArea] = useState<string>('');
    const [inputValueAddTaxSpecialism, setInputValueAddTaxSpecialism] = useState<string>('');
    const [expertiseAreas, setExpertiseAreas] = useState<string[]>([]);
    const [taxSpecialisms, setTaxSpecialisms] = useState<string[]>([]);
    const [isVisibleExpertise, setIsVisibleExpertise] = useState(false);
    const [isVisibleTaxSpecialism, setIsVisibleTaxSpecialism] = useState(false);

    interface Post {
        id: number;
        title: {
            rendered: string;
        };
    }

    const addNewCountry = async (inputValueCountry: string) => {
        if (inputValueCountry !== "") {
            setAddCountryLoading(true);
            try {
                // Check if the country already exists in WordPress
                const allPostsOurLocationsResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/posts?categories=9&per_page=100`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                    },
                });

                let postId;
                if (allPostsOurLocationsResponse.ok) {
                    const allPostsData = await allPostsOurLocationsResponse.json();
                    const existingPost = allPostsData.find((post: Post) => post.title.rendered === inputValueCountry);
                    if (existingPost) {
                        // If the country exists in WordPress, use the existing ID
                        postId = existingPost.id;
                    }
                }

                // Fetch the existing countries from the database
                const countrySnapshot = await countryRef.once('value');
                const countryData = countrySnapshot.val();

                // Check if the country already exists in the database
                if (countryData && Object.values(countryData).includes(inputValueCountry)) {
                    notification['warning']({
                        message: 'Warning',
                        description: 'This country already exists. Please enter a new country.',
                    });
                    setAddCountryLoading(false);  // Stop loading indicator
                    return;  // Exit the function early if the country exists
                }

                if (!postId) {
                    // Create a new post with the given title and "Our Locations" category
                    const postResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/posts`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                        },
                        body: JSON.stringify({
                            title: inputValueCountry,
                            categories: [9],
                            status: 'draft'
                        })
                    });

                    if (postResponse.status === 403) {
                        handleError('You do not have permission to create posts.');
                    }

                    const postData = await postResponse.json();

                    if (postResponse.status !== 201) {
                        handleError(postData.message);
                    }

                    postId = postData.id;

                    // Update post status to publish if creating as draft was successful
                    const publishResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/posts/${postId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                        },
                        body: JSON.stringify({
                            status: 'publish'
                        })
                    });

                    if (publishResponse.status !== 200) {
                        const publishData = await publishResponse.json();
                        handleError(publishData.message);
                    }
                }

                // Use the ID from the created or existing WordPress post to update Firebase
                const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/createCountry`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token} `,
                    },
                    body: JSON.stringify({
                        id: postId,
                        name: inputValueCountry,
                        callType: ""
                    })
                });

                const responseData = await response.json();
                if (response.status !== 200) {
                    handleError(responseData.error);
                }

                await fetchCountries(); // Re-fetch the countries after deleting

                notification['success']({message: 'Success', description: t('Country successfully added')});
            } catch (error) {
                notification['error']({message: 'Error', description: t('Failed to create a new country')});
            } finally {
                setAddCountryLoading(false);
            }
        }
    }

    const addNewGrade = async (inputValueGrade: string) => {
        if (inputValueGrade.trim() !== "") {
            try {
                setAddGradeLoading(true);  // Start loading indicator

                // Fetch the existing grades from the database
                const gradeSnapshot = await gradeRef.once('value');
                const gradeData = gradeSnapshot.val();

                // Check if the grade already exists
                if (gradeData && Object.values(gradeData).includes(inputValueGrade)) {
                    notification['warning']({
                        message: 'Warning',
                        description: 'This grade already exists. Please enter a new grade.',
                    });
                    setAddGradeLoading(false);  // Stop loading indicator
                    return;  // Exit the function early if the grade exists
                }

                // Find the next available index for the new grade
                let nextIndex = 0;
                if (gradeData) {
                    const gradeIndices = Object.keys(gradeData)
                        .map(key => parseInt(key))  // Convert keys to numbers
                        .filter(key => !isNaN(key));  // Filter only numerical keys

                    nextIndex = Math.max(...gradeIndices) + 1;  // Determine the next available index
                }

                // Add the new grade with the next available index
                await gradeRef.child(nextIndex.toString()).set(inputValueGrade);

                // Refresh the list of grades
                await fetchGrades();

                // Success notification
                notification['success']({
                    message: 'Success',
                    description: 'Grade successfully added',
                });

            } catch (error) {
                // Error notification if something goes wrong
                notification['error']({
                    message: 'Error',
                    description: 'Failed to add a new grade. Please try again.',
                });
            } finally {
                setAddGradeLoading(false);  // Stop loading indicator
                setInputValueAddGrade('');  // Clear the input field for grade
            }
        } else {
            // Warning notification if the input is empty
            notification['warning']({
                message: 'Warning',
                description: 'Grade name cannot be empty',
            });
        }
    };

    const addNewExpertiseArea = async (inputValue: string) => {
        if (inputValue.trim() !== "") {
            try {
                setAddExpertiseAreaLoading(true);  // Start loading indicator

                // Fetch the existing expertise areas from the database
                const expertiseSnapshot = await areaOfIndustryExpertiseRef.once('value');
                const expertiseData = expertiseSnapshot.val();

                // Check if the area already exists
                if (expertiseData && Object.values(expertiseData).includes(inputValue)) {
                    notification['warning']({
                        message: 'Warning',
                        description: 'This area of expertise already exists. Please enter a new value.',
                    });
                    setAddExpertiseAreaLoading(false);  // Stop loading indicator
                    return;  // Exit the function early if the value exists
                }

                // Find the next available index for the new area of expertise
                let nextIndex = 0;
                if (expertiseData) {
                    const expertiseIndices = Object.keys(expertiseData)
                        .map(key => parseInt(key))  // Convert keys to numbers
                        .filter(key => !isNaN(key));  // Filter only numerical keys

                    nextIndex = Math.max(...expertiseIndices) + 1;  // Determine the next available index
                }

                // Add the new area of expertise with the next available index
                await areaOfIndustryExpertiseRef.child(nextIndex.toString()).set(inputValue);

                // Refresh the list of areas of expertise
                await fetchExpertiseAreas();

                // Success notification
                notification['success']({
                    message: 'Success',
                    description: 'Area of expertise successfully added',
                });

            } catch (error) {
                // Error notification if something goes wrong
                notification['error']({
                    message: 'Error',
                    description: 'Failed to add a new area of expertise. Please try again.',
                });
            } finally {
                setAddExpertiseAreaLoading(false);  // Stop loading indicator
                setInputValueAddExpertiseArea('');  // Clear the input field for area of expertise
            }
        } else {
            // Warning notification if the input is empty
            notification['warning']({
                message: 'Warning',
                description: 'Area of expertise cannot be empty',
            });
        }
    };

    const addNewTaxSpecialism = async (inputValue: string) => {
        if (inputValue.trim() !== "") {
            try {
                setAddTaxSpecialismLoading(true);  // Start loading indicator

                // Fetch the existing tax specialisms from the database
                const specialismSnapshot = await taxSpecialismRef.once('value');
                const specialismData = specialismSnapshot.val();

                // Check if the specialism already exists
                if (specialismData && Object.values(specialismData).includes(inputValue)) {
                    notification['warning']({
                        message: 'Warning',
                        description: 'This tax specialism already exists. Please enter a new value.',
                    });
                    setAddTaxSpecialismLoading(false);  // Stop loading indicator
                    return;  // Exit the function early if the value exists
                }

                // Find the next available index for the new tax specialism
                let nextIndex = 0;
                if (specialismData) {
                    const specialismIndices = Object.keys(specialismData)
                        .map(key => parseInt(key))  // Convert keys to numbers
                        .filter(key => !isNaN(key));  // Filter only numerical keys

                    nextIndex = Math.max(...specialismIndices) + 1;  // Determine the next available index
                }

                // Add the new tax specialism with the next available index
                await taxSpecialismRef.child(nextIndex.toString()).set(inputValue);

                // Refresh the list of tax specialisms
                await fetchTaxSpecialism();

                // Success notification
                notification['success']({
                    message: 'Success',
                    description: 'Tax specialism successfully added',
                });

            } catch (error) {
                // Error notification if something goes wrong
                notification['error']({
                    message: 'Error',
                    description: 'Failed to add a new tax specialism. Please try again.',
                });
            } finally {
                setAddTaxSpecialismLoading(false);  // Stop loading indicator
                setInputValueAddTaxSpecialism('');  // Clear the input field for tax specialism
            }
        } else {
            // Warning notification if the input is empty
            notification['warning']({
                message: 'Warning',
                description: 'Tax specialism cannot be empty',
            });
        }
    };

    const makeSlug = (str: string): string => {
        return str
            .toLowerCase() // Convert to lowercase
            .trim() // Remove leading/trailing whitespace
            .replace(/&/g, 'and') // Replace "&" with "and"
            .replace(/[^\w\s-]/g, '') // Remove non-word characters (except spaces and dashes)
            .replace(/\s+/g, '-') // Replace spaces with dashe
            .replace(/--+/g, '-'); // Replace multiple dashes with a single dash
    };

    const editCountryName = async (currentCountryName: string, newCountryName: string) => {
        if (newCountryName !== "") {
            try {
                // Check if the country already exists in WordPress
                const allPostsOurLocationsResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/posts?categories=9&per_page=100`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                    },
                });

                let postId;
                if (allPostsOurLocationsResponse.ok) {
                    const allPostsData = await allPostsOurLocationsResponse.json();
                    console.log('Fetched posts:', allPostsData);
                    console.log('current Country Name:', makeSlug(currentCountryName));

                    const existingPost = allPostsData.find((post: { slug: string; }) =>
                        post.slug === makeSlug(currentCountryName)
                    );

                    if (existingPost) {
                        postId = existingPost.id;
                    } else {
                        handleError(`Country "${currentCountryName}" does not exist in WordPress.`);
                        return;
                    }
                }

                if (postId) {
                    // Update the existing post with the new title
                    const postResponse = await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/posts/${postId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                        },
                        body: JSON.stringify({
                            title: newCountryName,
                            slug: newCountryName,
                        })
                    });

                    if (postResponse.status === 403) {
                        handleError('You do not have permission to edit posts.');
                    }

                    const postData = await postResponse.json();

                    if (postResponse.status !== 200) {
                        handleError(postData.message);
                    }


                    // Use the ID from the updated WordPress post to update Firebase
                    const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updateCountry`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token} `,
                        },
                        body: JSON.stringify({
                            id: postId,
                            newName: newCountryName,
                        })
                    });

                    const responseData = await response.json();
                    if (response.status !== 200) {
                        handleError(responseData.error);
                    }

                    // Fetch all firms from Firebase
                    const firmsRef = firebase.database().ref('firm');
                    const snapshot = await firmsRef.once('value');

                    if (snapshot.exists()) {
                        const firmsData = snapshot.val();
                        let firmId = null;
                        let currentFirmData = null;

                        // Find the firm with the matching country name
                        for (const [id, firm] of Object.entries(firmsData)) {
                            // @ts-ignore
                            if (firm.country === currentCountryName) {
                                firmId = id;
                                currentFirmData = firm;
                                break;
                            }
                        }

                        if (firmId && currentFirmData) {
                            // Update only the country name
                            const updatedFirmData = {
                                ...currentFirmData,
                                country: newCountryName
                            };

                            // Save the updated firm data back to Firebase
                            await firebase.database().ref('firm/' + firmId).set(updatedFirmData);

                            notification['success']({
                                message: 'Success',
                                description: 'Country name successfully updated'
                            });
                        } else {
                            handleError(`Firm with country "${currentCountryName}" does not exist.`);
                        }
                    } else {
                        handleError('No firms found.');
                    }

                    // Fetch all users from Firebase
                    const usersRef = firebase.database().ref('users');
                    const usersSnapshot = await usersRef.once('value');
                    const users = usersSnapshot.val();

                    if (users) {
                        const updates = {};
                        for (const uid in users) {
                            if (users[uid].country === currentCountryName) {
                                // @ts-ignore
                                updates[`users/${uid}/country`] = newCountryName;
                            }
                        }

                        // Apply updates to all users with the old country name
                        await firebase.database().ref().update(updates);

                        notification['success']({
                            message: 'Success',
                            description: 'Country name successfully updated for all users'
                        });
                    } else {
                        handleError('No users found.');
                    }

                    await fetchCountries(); // Re-fetch the countries after deleting
                }
            } catch (error) {
                notification['error']({message: 'Error', description: 'Failed to update the country name'});
            }
        }
    };

    const editGradeName = async (oldGrade: string, newGrade: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updateGrades`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    oldGrade,
                    newGrade
                })
            });
            const data = await response.json();
            if (response.ok) {
                notification['success']({message: 'Success', description: 'Grade successfully updated'});
                await fetchGrades();
            } else {
                notification['error']({message: 'Error', description: `Error: ${data.message}`});
            }
        } catch (error) {
            notification['error']({message: 'Error', description: 'Failed to update the grade'});
        }
    };

    const editExpertiseAreaName = async (oldExpertise: string, newExpertise: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updateExpertiseArea`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    oldExpertise,
                    newExpertise
                })
            });
            const data = await response.json();
            if (response.ok) {
                notification['success']({
                    message: 'Success',
                    description: 'Expertise area successfully updated',
                });
                await fetchExpertiseAreas(); // Refresh the expertise areas
            } else {
                notification['error']({
                    message: 'Error',
                    description: `Error: ${data.message}`,
                });
            }
        } catch (error) {
            notification['error']({
                message: 'Error',
                description: 'Failed to update the expertise area',
            });
        }
    };

    const editTaxSpecialismName = async (oldSpecialism: string, newSpecialism: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/updateTaxSpecialism`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    oldTaxSpecialism: oldSpecialism,
                    newTaxSpecialism: newSpecialism
                })
            });
            const data = await response.json();
            if (response.ok) {
                notification['success']({
                    message: 'Success',
                    description: 'Tax specialism successfully updated',
                });
                await fetchTaxSpecialism(); // Refresh the tax specialisms
            } else {
                notification['error']({
                    message: 'Error',
                    description: `Error: ${data.message}`,
                });
            }
        } catch (error) {
            notification['error']({
                message: 'Error',
                description: 'Failed to update the tax specialism',
            });
        }
    };

    const deleteGrade = async (grade: string) => {
        const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteGrades`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({gradeToDelete: grade}),
        });

        if (!response.ok) {
            throw new Error('Failed to delete grade');
        }
    };

    const deleteExpertiseArea = async (expertiseArea: string) => {
        const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteExpertiseArea`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({expertiseAreaToDelete: expertiseArea}),
        });

        if (!response.ok) {
            throw new Error('Failed to delete area of expertise');
        }

        notification['success']({
            message: 'Success',
            description: 'Area of expertise successfully deleted',
        });

        // Refresh the list of expertise areas after deletion
        await fetchExpertiseAreas();
    };

    const deleteTaxSpecialism = async (specialism: string) => {
        const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteTaxSpecialism`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ taxSpecialismToDelete: specialism }),
        });

        if (!response.ok) {
            throw new Error('Failed to delete tax specialism');
        }

        notification['success']({
            message: 'Success',
            description: 'Tax specialism successfully deleted',
        });

        // Refresh the list of tax specialisms after deletion
        await fetchTaxSpecialism();
    };

    const fetchCountries = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getAllCountries`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();

                console.log("data:",data);

                const Countries = data.countries.map((country: any) => (country.name));

                setCountries(Countries);

            } else {
                console.error('Error fetching countries:', response.statusText);
            }

        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    }, [token]);

    const fetchGrades = useCallback(async () => {
        gradeRef.on('value', (snap) => {
            const result = snap.val() as Record<string, string> | null;

            // Check if result is an object and convert it to an array
            const gradeArray: string[] = result ? Object.values(result) : [];

            setGrades(gradeArray);
        });
    }, []);

    const fetchExpertiseAreas = useCallback(async () => {
        areaOfIndustryExpertiseRef.on('value', (snap) => {
            const result = snap.val() as Record<string, string> | null;

            // Check if result is an object and convert it to an array
            const expertiseArray: string[] = result ? Object.values(result) : [];

            setExpertiseAreas(expertiseArray);
        });
    }, []);

    const fetchTaxSpecialism = useCallback(async () => {
        taxSpecialismRef.on('value', (snap) => {
            const result = snap.val() as Record<string, string> | null;

            // Check if result is an object and convert it to an array
            const taxSpecialismArray: string[] = result ? Object.values(result) : [];

            setTaxSpecialisms(taxSpecialismArray);
        });
    }, []);

    function handleError(message: string) {
        console.error(message);
    }

    const handleInputAddCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValueAddCountry(event.target.value);
    }

    const handleInputAddGradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValueAddGrade(event.target.value);
    };

    const handleInputAddExpertiseAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValueAddExpertiseArea(event.target.value);
    };

    const handleInputAddTaxSpecialismChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValueAddTaxSpecialism(event.target.value);
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleGradeVisibility = () => {
        setIsVisibleGrade(!isVisibleGrade);
    };

    const toggleExpertiseVisibility = () => {
        setIsVisibleExpertise(!isVisibleExpertise);
    };

    const toggleTaxSpecialismVisibility = () => {
        setIsVisibleTaxSpecialism(!isVisibleTaxSpecialism);
    };

    useEffect(() => {
        // Simulate an asynchronous operation
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
        fetchCountries().then();
        fetchGrades().then();
        fetchExpertiseAreas().then();
        fetchTaxSpecialism().then();
        // Clean up the timeout
        return () => clearTimeout(timeout);
    }, [token, fetchCountries, fetchGrades, fetchExpertiseAreas,fetchTaxSpecialism]);


    return (
        <div>
            {loading ? (
                <section className="dots-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </section>
            ) : (
                <>
                    <div id="wrapper" className="overflow-hidden mb-5">
                        <div className="d-flex flex-column" id="content-wrapper">
                            <div id="content">
                                <div className="container-fluid">
                                    {/* Country Management */}
                                    <div className="row mb-4">
                                        <div className="col-lg-12 col-xl-12">
                                            <Card>
                                                <Row gutter={[16, 16]}>
                                                    <Col span={6}>
                                                        <Typography.Title level={3}>Management
                                                            Country</Typography.Title>
                                                    </Col>
                                                    <Col span={18}>
                                                        <Row gutter={16} align="middle" justify="end">
                                                            <Col>
                                                                <div
                                                                    className={`toggle-input ${isVisible ? 'show' : 'hide'}`}>
                                                                    <Input
                                                                        placeholder="New Country"
                                                                        value={inputValueAddCountry}
                                                                        onChange={handleInputAddCountryChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div
                                                                    className={`toggle-input ${isVisible ? 'show' : 'hide'}`}>
                                                                    <Button type="dashed"
                                                                            onClick={() => addNewCountry(inputValueAddCountry)}
                                                                            icon={<PlusOutlined/>}
                                                                            loading={addCountryLoading}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <Button type="primary" onClick={toggleVisibility}>
                                                                    {isVisible ? 'Hide' : 'Add New Country'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <CountryListComponent
                                                    countries={countries}
                                                    editCountryName={editCountryName}
                                                />
                                            </Card>
                                        </div>
                                    </div>
                                    {/* Grade Management */}
                                    <div className="row mb-4">
                                        <div className="col-lg-12 col-xl-12">
                                            <Card>
                                                <Row gutter={[16, 16]}>
                                                    <Col span={12}>
                                                        <Typography.Title level={3}>Management Grade</Typography.Title>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row gutter={16} align="middle" justify="end">
                                                            <Col>
                                                                <div
                                                                    className={`toggle-input ${isVisibleGrade ? 'show' : 'hide'}`}>
                                                                    <Input
                                                                        placeholder="New Grade"
                                                                        value={inputValueAddGrade}
                                                                        onChange={handleInputAddGradeChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div
                                                                    className={`toggle-input ${isVisibleGrade ? 'show' : 'hide'}`}>
                                                                    <Button
                                                                        type="dashed"
                                                                        onClick={() => addNewGrade(inputValueAddGrade)}
                                                                        icon={<PlusOutlined/>}
                                                                        loading={AddGradeLoading}
                                                                    >
                                                                        Add Grade
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <Button type="primary" onClick={toggleGradeVisibility}>
                                                                    {isVisibleGrade ? 'Hide' : 'Add New Grade'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <GradeListComponent grades={grades} editGradeName={editGradeName}
                                                                    deleteGrade={deleteGrade}/>
                                            </Card>
                                        </div>
                                    </div>
                                    {/* Area of Industry Expertise Management */}
                                    <div className="row mb-4">
                                        <div className="col-lg-12 col-xl-12">
                                            <Card>
                                                <Row gutter={[16, 16]}>
                                                    <Col span={12}>
                                                        <Typography.Title level={3}>Manage Area of Industry
                                                            Expertise</Typography.Title>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row gutter={16} align="middle" justify="end">
                                                            <Col>
                                                                <div
                                                                    className={`toggle-input ${isVisibleExpertise ? 'show' : 'hide'}`}>
                                                                    <Input
                                                                        placeholder="New Area of Expertise"
                                                                        value={inputValueAddExpertiseArea}
                                                                        onChange={handleInputAddExpertiseAreaChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div
                                                                    className={`toggle-input ${isVisibleExpertise ? 'show' : 'hide'}`}>
                                                                    <Button
                                                                        type="dashed"
                                                                        onClick={() => addNewExpertiseArea(inputValueAddExpertiseArea)}
                                                                        icon={<PlusOutlined/>}
                                                                        loading={addExpertiseAreaLoading}
                                                                    >
                                                                        Add Expertise
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <Button type="primary"
                                                                        onClick={toggleExpertiseVisibility}>
                                                                    {isVisibleExpertise ? 'Hide' : 'Add New Expertise'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <AreaOfIndustryExpertiseListComponent
                                                    expertiseAreas={expertiseAreas}
                                                    editExpertiseAreaName={editExpertiseAreaName}
                                                    deleteExpertiseArea={deleteExpertiseArea}
                                                />
                                            </Card>
                                        </div>
                                    </div>
                                    {/* Tax Specialism Management */}
                                    <div className="row mb-4">
                                        <div className="col-lg-12 col-xl-12">
                                            <Card>
                                                <Row gutter={[16, 16]}>
                                                    <Col span={12}>
                                                        <Typography.Title level={3}>Manage Tax Specialism</Typography.Title>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row gutter={16} align="middle" justify="end">
                                                            <Col>
                                                                <div className={`toggle-input ${isVisibleTaxSpecialism ? 'show' : 'hide'}`}>
                                                                    <Input
                                                                        placeholder="New Tax Specialism"
                                                                        value={inputValueAddTaxSpecialism}
                                                                        onChange={handleInputAddTaxSpecialismChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className={`toggle-input ${isVisibleTaxSpecialism ? 'show' : 'hide'}`}>
                                                                    <Button
                                                                        type="dashed"
                                                                        onClick={() => addNewTaxSpecialism(inputValueAddTaxSpecialism)}
                                                                        icon={<PlusOutlined />}
                                                                        loading={addTaxSpecialismLoading}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <Button type="primary" onClick={toggleTaxSpecialismVisibility}>
                                                                    {isVisibleTaxSpecialism ? 'Hide' : 'Add New Tax Specialism'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <TaxSpecialismListComponent
                                                    taxSpecialisms={taxSpecialisms}
                                                    editTaxSpecialismName={editTaxSpecialismName}
                                                    deleteTaxSpecialism={deleteTaxSpecialism}
                                                />
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Management;