export const callFirebaseFunction = async (
    endpoint: string,
    method: string,
    data: Record<string, any>,
    token: string
) => {
    const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // If 204 No Content, handle accordingly
    if (response.status === 204) {
        return { userId: null, message: 'User created but no data returned' };
    }

    return await response.json();
};