import { ReactComponent as Document } from 'assets/documents/svg/document.svg'
import { ReactComponent as FolderSvg } from 'assets/documents/svg/folder.svg';
import { IconLink } from "@tabler/icons";
import {
    FilePptOutlined,
    FileExcelOutlined,
    FileWordOutlined,
    FilePdfOutlined,
    FileImageOutlined,
    FileOutlined,
    FileTextOutlined,
    PictureOutlined,
    VideoCameraOutlined
} from '@ant-design/icons';
import React from 'react';

export const extensionsIcons = {
    'folder': <FolderSvg style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'pdf': <FilePdfOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'docx': <FileWordOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'doc': <FileWordOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'xlsx': <FileExcelOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'xls': <FileExcelOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'pptx': <FilePptOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'ppt': <FilePptOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'png': <FileImageOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'svg': <FileOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'jpg': <PictureOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'json': <FileTextOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'csv': <FileTextOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'mp4': <VideoCameraOutlined style={{ textAlign: 'left', margin: 'inherit' }}/>,
    'txt': <Document/>,
    'link': <IconLink/>,
    'default': <Document/>
}