import React, { useCallback, useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ROUTES } from "../parameters/routes";
import { inject, observer } from "mobx-react";
import { AuthStore } from "../stores/authStore";

interface AuthorizationProps extends RouteComponentProps {
  authStore: AuthStore;
}

export enum Api {
  dev = "https://www.master-7rqtwti-dk5624z6tjjw6.fr-3.platformsh.site",
  test = "https://www.test-t6dnbai-dk5624z6tjjw6.fr-3.platformsh.site",
  prod = "https://www.taxand.com",
}

const validateWordPressToken = async (): Promise<boolean> => {
  const token = localStorage.getItem('wordpressToken');

  if (!token) {
    console.error('WordPress token is missing.');
    return false;
  }

  try {
    const response = await fetch(`${Api.prod}/wp-json/jwt-auth/v1/token/validate`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      console.log('WordPress token is valid.');
      return true;
    } else {
      console.error('WordPress token validation failed:', response.status, response.statusText);
      return false;
    }
  } catch (error) {
    console.error('Error validating WordPress token:', error);
    return false;
  }
};

const Authorization: React.FC<AuthorizationProps> = ({ authStore, children, history }) => {
  const [guard, setGuard] = useState(false);

  // Extracting isAuthorized to a constant
  const isAuthorized = authStore.isAuthorized;

  const checkAdmin = useCallback(async () => {
    if (!isAuthorized) {
      history.push(ROUTES.login);
    } else {
      const isWordPressTokenValid = await validateWordPressToken();

      if (!isWordPressTokenValid) {
        console.warn('Redirecting to login due to invalid WordPress token.');
        history.push(ROUTES.login);
      } else {
        setGuard(true);
      }
    }
  }, [isAuthorized, history]);

  useEffect(() => {
    checkAdmin().then();
  }, [checkAdmin]);

  return <>{guard ? children : null}</>;
};

export default withRouter(inject('authStore')(observer(Authorization)));
