import {AuthStore} from '../stores/authStore';
import {
    File,
    FirmData,
    Folder,
    GroupChat,
    GroupChatExtended,
    NewUser,
    UserData,
    UserRequestUpdate
} from '../models/general';
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {CalenderEvent} from "../models/calenderEvent";
import {Category} from "../models/category";


export interface API {
    login(email: string, password: string): Promise<firebase.auth.UserCredential>;
    verifyUserAsAdmin(userId: string): Promise<boolean | Error>;
    getUserInfo(userId: string): Promise<UserData>;
    getRequestInfo(requestId: string): Promise<UserRequestUpdate>;
    getApprovalRequestInfo(RequestId: string): Promise<UserData>;
    getFirmUserInfo(userId: string): Promise<UserData>;
    setNewUser(newUser: NewUser, userId: string): Promise<UserData>;
    updateUser(updatedUser: UserData, userId: string): Promise<boolean | Error>;
    updateUserFirm(updatedUserFirm: FirmData, userId: string): Promise<boolean | Error>;
    updateApprovalFirm(updatedApprovalFirm: UserRequestUpdate, userId: string): Promise<boolean | Error>;
    getGroupChatId(): Promise<any>;
    getFirmId(): Promise<any>;
    getRequestId(): Promise<any>;
    getFileId(): Promise<any>;
    getGroupChatInfo(groupChatId: string): Promise<GroupChat>;
    updateGroupChatInfo(data: GroupChatExtended, groupChatId: string): Promise<boolean | Error>;
    deleteGroupChat(userId: string): Promise<boolean | Error>;
    updateVisiblity(userId: string,isVisible:boolean) :Promise<boolean | Error>;
    getFirmInfo(firmId: string): Promise<FirmData>;
    updateFirm(updatedFirm: FirmData, firmId: string): Promise<boolean | Error>;
    updateFile(updatedFile: File, fileId: string): Promise<boolean | Error>;
    updateFolder(updatedFolder: Folder, folderId: string): Promise<boolean | Error>;
    addAreaOfExpertise(areaOfExpertise:string):Promise<any>;
    getAreaOfExpertiseInfo(areaOfExpertiseId:string):Promise<any>;
    getCountryInfo(countryId:string):Promise<any>;
    getAllUserInfo(): Promise<any>;
    getDeletedUserInfo(): Promise<any>;
    getFileById(fileId: string): Promise<File>;
    getFolderById(folderId: string): Promise<Folder>;
    getEventId(): Promise<any>;
    getEventInfo(eventId: string): Promise<CalenderEvent>;
    getAllEvents(): Promise<any>;
    updateEvent(updatedEvent: CalenderEvent, eventId: string) : Promise<boolean | Error>;
    getCategoryId(): Promise<any>;
    getAllCategories(): Promise<any>;
    getCategoryInfo(categoryId: string): Promise<Category>;
    getCategoryByName(categoryName: string): Promise<Category>;
    getUserByEmail(email: string): Promise<UserData>;
    addGrade(grade:string):Promise<any>;
}


function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function createAPI(authStore: AuthStore): API {
    return {
        async login(email, password) {
            const sanitizedEmail = email.trim();

            if (!isValidEmail(sanitizedEmail)) {
                throw new Error("The email address is badly formatted.");
            }

            const res = firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(async (response) => {
                    authStore.setToken(response.user?.uid as any);
                      return response
                })
                .catch(function (error) {
                    throw new Error(error);
                });
            return res;
        },

        async verifyUserAsAdmin(userId: string) {

            authStore.setToken(userId);

            return true
        },

        // get all users that are not deleted

        async getAllUserInfo() {
            const res = await firebase.database().ref('users/').orderByChild('deleted').equalTo(false).once('value').then(function (snapshot: any) {
                const users: UserData[] = [];
                snapshot.forEach(function (childSnapshot: any) {
                    const user: UserData = childSnapshot.val();
                    user.id = childSnapshot.key;
                    users.push(user);
                });
                return users;
                // return snapshot.val();
            });
            return res;
        },

        // get all users that are deleted

        async getDeletedUserInfo() {
            const res = await firebase.database().ref('users/').orderByChild('deleted').equalTo(true).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },

        async getUserInfo(userId: string) {
            const res = firebase.database().ref('users/' + userId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },

        async getApprovalRequestInfo(RequestId: string) {
            const res = firebase.database().ref('approval_request/' + RequestId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },

        async getFirmUserInfo(userId: string) {
            const res = firebase.database().ref('users/' + userId+'/firm').once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },


        async setNewUser(newUser: NewUser, userId: string) {
            const res = firebase.database().ref('users/' + userId)
            .set(newUser)
            .then(function (user: UserData) {
                return user
            })
            .catch(function (error) {
                throw new Error(error);
            });
            return res;
        },

        async updateUser(updatedUser: UserData , userId: string) {
            const res = firebase.database().ref('users/' + userId)
            .update(updatedUser)
            .then(function () {
                return true
            })
            .catch(function (error) {
                throw new Error(error);
            });      
            return res;
        },

        async updateUserFirm(updatedUserFirm: FirmData , userId: string) {

            const res = firebase.database().ref('users/' + userId+'/firm')
                .update(updatedUserFirm)
                .then(function () {
                    return true
                })
                .catch(function (error) {
                    throw new Error(error);
                });
            return res;
        },

        async updateApprovalFirm(updatedApprovalFirm: UserRequestUpdate , approvalId: string) {

            const res = firebase.database().ref('approval_request/' + approvalId+'/firm')
                .update(updatedApprovalFirm)
                .then(function () {
                    return true
                })
                .catch(function (error) {
                    throw new Error(error);
                });
            return res;
        },

        async updateFirm(updatedFirm: FirmData, firmId: string) {
            const res = firebase.database().ref('firm/' + firmId)
                .set(updatedFirm)
                .then(function () {
                    return true
                })
                .catch(function (error) {
                    throw new Error(error);
                });
            return res;
        },
        async updateFile(file: File, fileId: string) {
            const res = firebase.database().ref('document/' + fileId)
                .set(file)
                .then(function () {
                    return true
                })
                .catch(function (error) {
                    throw new Error(error);
                });
            return res;
        },
        async updateFolder(folder: Folder, folderId: string) {
            const res = firebase.database().ref('folder/' + folderId)
                .set(folder)
                .then(function () {
                    return true
                })
                .catch(function (error) {
                    throw new Error(error);
                });
            return res;
        },

        async getGroupChatId() {
            const res = firebase.database().ref('groupChats').push().then(function (res) {
                return res.key
            })
            .catch(function (error) {
                throw new Error(error);
            });
            return res;
        },

        async getGroupChatInfo(groupChatId: string) {
            const res = firebase.database().ref('groupChats/' + groupChatId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },

        async getFirmId() {
            const res = firebase.database().ref('firm' ).push().then(function (res) {
                return res.key;
            });
            return res;
        },

        async addAreaOfExpertise(areaOfIndustryExpertise:string) {
            const res = firebase.database().ref('area_of_industry_expertise' ).push().set(areaOfIndustryExpertise).then(function (res) {
                return res;
            });
            return res;
        },

        async getRequestId() {
            const res = firebase.database().ref('approval_request' ).push().then(function (res) {
                return res.key;
            });
            return res;
        },

        async getFirmInfo(firmId: string) {
            const res = firebase.database().ref('firm/' + firmId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },

        async getAreaOfExpertiseInfo(areaOfIndustryExpertiseId: string) {
            const res = firebase.database().ref('area_of_industry_expertise/' + areaOfIndustryExpertiseId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },
        async getCountryInfo(countryId: string) {
            const res = firebase.database().ref('country/' + countryId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },

        async getRequestInfo(requestId: string) {
            const res = firebase.database().ref('approval_request/' + requestId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },


        async updateVisiblity(userId:string,isVisible:boolean){
            const updates: any = {};
            updates['isVisible'] = isVisible;
            const res =  firebase.database().ref('users/' + userId).update(updates).then(function (){
                return true;
            }).catch(function (error) {
                    throw new Error(error);
                    });
            return res;
        },


        async updateGroupChatInfo(data: GroupChatExtended, id: string) {
            const res = firebase.database().ref('groupChats/' + id)
            .set(data)
            .then(function () {
                return true
            })
            .catch(function (error) {
                throw new Error(error);
            });      
            return res;
        },

        async deleteGroupChat(groupChatId: string) {
            const res = firebase.database().ref('groupChats/' + groupChatId)
            .remove()
            .then(function () {
                return true
            })
            .catch(function (error) {
                throw new Error(error);
            });;
            return res;
        },
        async getFileById(fileId: string) {
            const res = firebase.database().ref('document/' + fileId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },
        async getFileId() {
            const res = firebase.database().ref('document' ).push().then(function (res) {
                return res.key;
            });
            return res;
        },
        async getFolderById(folderId: string) {
            const res = firebase.database().ref('folder/' + folderId).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },
        async getEventId() {
            const res = firebase.database().ref('eventCalender' ).push().then(function (res) {
                return res.key;
            });
            return res;
        },
        async getEventInfo(eventId: string) {
            const res = firebase.database().ref('eventCalender/' + eventId).once('value').then(function (snapshot: any) {
               return snapshot.val();
            });
            return res;
        },
        async getAllEvents() {
            return await firebase.database().ref('eventCalender/').once('value').then(function (snapshot: any) {
              const events: CalenderEvent[] = [];
              snapshot.forEach(function (childSnapshot: any) {
                  const event: CalenderEvent = childSnapshot.val();
                  event.id = childSnapshot.key;
                  events.push(event);
              });
              return events;
          });
        },
        async updateEvent(updatedEvent: CalenderEvent, eventId: string): Promise<boolean | Error> {
            const res = firebase.database().ref('eventCalender/' + eventId).update(updatedEvent)
                .then(function () {
                    return true
                }).catch(function (error) {
                    throw new Error(error);
                });
            return res;
        },
        async getCategoryId() {
            const res = firebase.database().ref('category').push().then(function (res) {
               return res.key;
            });
            return res;
        },
        async getAllCategories() {
            const res = await firebase.database().ref('category/').once('value').then(function (snapshot: any) {
                const categories: Category[] = [];
                snapshot.forEach(function (childSnapshot: any) {
                    const category: Category = childSnapshot.val();
                    category.id = childSnapshot.key;
                    categories.push(category);
                });
                return categories;
            });
            return res;
        },
        async getCategoryInfo(categoryId: string) {
          const res = firebase.database().ref('category/' + categoryId).once('value').then(function (snapshot: any) {
              return snapshot.val();
          });
          return res;
        },
        async getCategoryByName(categoryName: string) {
            const res = firebase.database().ref('category/').orderByChild('name').equalTo(categoryName).once('value').then(function (snapshot: any) {
                return snapshot.val();
            });
            return res;
        },

        async getUserByEmail(email: string) {
            const sanitizedEmail = email.trim();

            if (!isValidEmail(sanitizedEmail)) {
                throw new Error("The email address is badly formatted.");
            }


            try {
                // Query Firebase to find a user with the given email
                const snapshot = await firebase.database().ref('users').orderByChild('email').equalTo(email).once('value');

                // If the snapshot exists and has children, return the first child's value
                if (snapshot.exists() && snapshot.hasChildren()) {
                    const userId = Object.keys(snapshot.val())[0];
                    return snapshot.val()[userId];
                } else {
                    // User with the given email not found
                    return null;
                }
            } catch (error) {
                console.error("Error getting user by email:", error);
                throw error;
            }
        },

        async addGrade(grade:string) {
            const res = firebase.database().ref('grade' ).push().set(grade).then(function (res) {
                return res;
            });
            return res;
        },

    }

}
